import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap"

import React from "react"
import RegisterJWT from "./RegisterJWT"
import { connect } from "react-redux"
import { getSystemLanguage } from "../../languages"
import logo from "../../../../assets/img/logo/logo.png"

class Register extends React.Component {
  render() {
    return (
      <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication rounded-0 mb-0"  style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
            <Row className="m-0">
              <Col lg="12" md="12" className="p-0">
                <Card className="mb-0 p-2 login-custom-width" style={{backgroundColor: 'transparent', border: '1px solid #ff9e4363', borderRadius: '7px !important'}}>
                  <CardHeader className="pb-1 pt-50">
                    <img style={{width: 240, height: 65, display: 'block', margin: 'auto'}} src={logo} alt="logo" />
                  </CardHeader>
                  <CardBody className="pt-1 pb-50">
                    <h4 className="mb-0" style={{color: 'white', textAlign: 'center'}}>{getSystemLanguage() === 'RU' ? 'Регистрация' : 'Create Account'}</h4>
                    <RegisterJWT />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      langsss: state.app.lang
  }
}

export default connect(mapStateToProps)(Register)
