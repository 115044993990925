import React, { Fragment } from "react"
import Select from "react-select"
import { Label, Row, Input, Form, Button } from 'reactstrap'
import { history } from "../../history"
import { getSystemLanguage } from "./languages"

const statusOptions = [
    { value: "active", label: getSystemLanguage() === 'RU' ? 'Активна' : 'Active' },
    { value: "pause", label: getSystemLanguage() === 'RU' ? 'Пауза' : 'Pause' },
    { value: "dedicated", label: getSystemLanguage() === 'RU' ? 'Отложенный старт' : 'Delay' },
  ]

export default function CompanySetupStatus({ type, ...props }) {
    const statusValue = statusOptions.find(({ value }) => value === props.company?.status) || statusOptions[0]

    return (
        <Fragment>
            <div className='content-header'>
                <h5 className='mb-0'>{props.title}</h5>
            </div>
            <Form onSubmit={e => e.preventDefault()}>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-status">{getSystemLanguage() === 'RU' ? 'Статус' : 'Status'}</Label>
                        <Select
                            value={statusValue}
                            size="sm"
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать' : 'Select'}
                            onChange={(value) => props.setCompany({ ...props.company, status: value.value })}
                            options={statusOptions}
                            className="React"
                            classNamePrefix="select"
                        />
                    </div>
                    {props.company?.status === 'dedicated' ? <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-white">{getSystemLanguage() === 'RU' ? 'Фильтровать после' : 'Filter After'}</Label>
                        <Input
                            type="number"
                            value={props.company?.dedicatedClicks}
                            onChange={e => props.setCompany({ ...props.company, dedicatedClicks: e.target.value })}
                        />
                    </div> : null}
                </Row>
                <hr />
                <div className='d-flex justify-content-between'>
                    <Button.Ripple style={{ width: 120 }} color='danger' className='btn-next' outline onClick={() => history.push('/campaigns')}>
                        <span className='align-middle d-sm-inline-block'>{getSystemLanguage() === 'RU' ? 'Отмена' : 'Cancel'}</span>
                    </Button.Ripple>
                    <Button.Ripple style={{ width: 130 }} color='primary' className='btn-next' onClick={() => props.isNewCompany ? props.addCompany(props.company) : props.updateCompany(props.company)}>
                        <span className='align-middle d-sm-inline-block'>{props.isNewCompany ? getSystemLanguage() === 'RU' ? 'Создать' : 'Save' : getSystemLanguage() === 'RU' ? 'Изменить' : 'Update'}</span>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

