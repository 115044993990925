import React from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import languages, { getSystemLanguage } from "../../views/pages/languages";

class BasicSweetCallback extends React.Component {
  state = {
    defaultAlert: false,
    row: {}
  }

  componentDidMount = () => {
    this.props.refAlert.current = this
  }

  show = (row) => {
    this.setState({ defaultAlert: true, row })
  }

  handleAlert = (state, value) => {
    this.setState({ [state]: value })
  }

  render() {
    return (
        <SweetAlert title={languages[getSystemLanguage()].alert.title} 
          warning
          show={this.state.defaultAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText={languages[getSystemLanguage()].alert.confirm}
          cancelBtnText={languages[getSystemLanguage()].alert.cancel}
          onConfirm={() => {
            this.props.onConfirm(this.state.row)
            this.handleAlert("defaultAlert", false)
          }}
          onCancel={() => {
            this.handleAlert("defaultAlert", false)
          }}
        >
        {languages[getSystemLanguage()].alert.msg}
        </SweetAlert>)
  }
}

export default BasicSweetCallback