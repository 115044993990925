import { createStore } from "redux"
import createRootReducer from "./reducer"

const rootReducer = createRootReducer()

const store = createStore(
  rootReducer,
  {}
)

export { store }
