import React from "react"
import {
  NavItem,
  NavLink,
} from "reactstrap"
import * as Icon from "react-feather"
import { connect } from "react-redux"
import { promosSelector } from "../../../redux/selectors"
import { AllHtmlEntities } from 'html-entities'
import { getSystemLanguage } from "../../../views/pages/languages"

const entities = new AllHtmlEntities();

class NavbarBookmarks extends React.PureComponent {
  render() {
    const spanHtmlAll = this.props.promoHtml && this.props.promoHtml.find(({ active, target }) => active && target === 'All Users')
    const spanHtmlActive = this.props.promoHtml && this.props.promoHtml.find(({ active, target }) => active && target === 'Active Users')
    const spanHtml = this.props.isActive ? spanHtmlActive : spanHtmlAll
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={this.props.sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        {spanHtml ? <span dangerouslySetInnerHTML={{__html: entities.decode(getSystemLanguage() === 'RU' ? spanHtml.text : spanHtml.textEn)}} /> : null }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      promoHtml: promosSelector(state),
      userFull: state.app.user
  }
}

export default connect(mapStateToProps)(NavbarBookmarks)