import React from "react"
import { connect } from "react-redux"
import AffiliatePage from "./AffiliatePage"
import AffiliateRegister from "./AffiliateRegister"

function Affiliate(props) {
    return (
        <div className={`data-list simple`}>
            {props.user.affiliate ? <AffiliatePage /> : <AffiliateRegister /> }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user
    }
}

export default connect(mapStateToProps)(Affiliate)