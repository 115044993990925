import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { MONO, PRIVAT, SBERBANK, TINKOV, VISA } from "./constants";

import { CardIcon } from "./Icons";
import React from "react";
import { getSystemLanguage } from "../languages";

const PaymentMethod = (props) => (
  <ListGroupItem
    style={{ cursor: "pointer" }}
    active={props.active === props.kind}
    onClick={() => props.setCardSystem(props.kind)}
  >
    <p className="float-left mb-0 mr-1">{props.children}</p>
    <span>{props.label}</span>
  </ListGroupItem>
);

const SelectCardBank = (props) => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.closeHandler}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={props.closeHandler}>
        <span style={{ textTransform: "uppercase" }}>{props.plan?.name}</span> -
        1 {props.active}
        <span
          style={{
            display: "block",
            float: "right",
            position: "absolute",
            right: 46,
            top: 12,
          }}
        >
          ${props.summaryPrice}
        </span>
      </ModalHeader>
      <ModalBody className="modal-dialog-centered">
        <div style={{ width: "100%" }}>
          <Row style={{ width: "100%" }}>
            <Col lg="8" md="8" sm="8">
              <FormGroup>
                <Label for="basicInput">
                  {getSystemLanguage() === "RU" ? "Промокод" : "Promo Code"}
                </Label>
                <Input
                  type="text"
                  value={props.promo}
                  onChange={props.promoHandler}
                  placeholder={
                    getSystemLanguage() === "RU"
                      ? "Введите Промокод"
                      : "Enter Promo Code"
                  }
                />
              </FormGroup>
            </Col>
            {props.discount ? (
              <Col
                lg="4"
                md="4"
                sm="4"
                style={{ paddingTop: 28, textAlign: "end" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {getSystemLanguage() === "RU" ? "Скидка" : "Discount"} -{" "}
                  {props.discount}%
                </span>
                <br />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    display: "block",
                    paddingTop: 3,
                  }}
                >
                  -{props.summaryDiscount}$
                </span>
              </Col>
            ) : null}
          </Row>
          {props.discount ? (
            <Row>
              <Col style={{ textAlign: "end", paddingRight: 40 }}>
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  {getSystemLanguage() === "RU" ? "Всего" : "Total"}{" "}
                  <span style={{ color: "#20ac4b" }}>
                    {props.summaryPriceWithDiscount}$
                  </span>
                </span>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col style={{ marginTop: 13 }}>
              {getSystemLanguage() === "RU" ? (
                <Row>
                  <Col style={{ paddingTop: 0, paddingLeft: 24 }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ fontWeight: "bold" }}>
                        Для оплаты картой -{" "}
                      </span>
                      <a target="_blank" href="https://t.me/cloakit">
                        напишите нам
                      </a>
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col style={{ paddingTop: 0, paddingLeft: 24 }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ fontWeight: "bold" }}>
                        To pay by card -{" "}
                      </span>
                      <a target="_blank" href="https://t.me/cloakit">
                        contact us
                      </a>
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            props.closeHandler();
            props.openSelectModal();
          }}
        >
          {getSystemLanguage() === "RU" ? "Назад" : "Back"}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.closeHandler();
          }}
        >
          {getSystemLanguage() === "RU" ? "Подтвердить" : "Confirm"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectCardBank;
