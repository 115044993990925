import { Button, FormGroup, Input, Label } from "reactstrap"
import { Check, X } from "react-feather"
import React, { useEffect, useState } from "react"

import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexy"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import { getSystemLanguage } from "./languages"
import { initialBlackList } from "./utils"

const DataListSidebar = ({ show, data, updateData, addData, cancelSidebar }) => {
  const [localData, setLocalData] = useState(data || initialBlackList)

  useEffect(() => {
    setLocalData(data || initialBlackList)
  }, [data])

  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialBlackList)
  }

  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? getSystemLanguage() === 'RU' ? 'ОБНОВИТЬ' : 'UPDATE' : getSystemLanguage() === 'RU' ? 'СОЗДАТЬ' : 'ADD NEW'}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-name">{getSystemLanguage() === 'RU' ? 'Имя' : 'Name'}</Label>
          <Input
            type="text"
            value={localData.name}
            placeholder=""
            onChange={e => setLocalData({ ...localData, name: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-black-ips">IP</Label>
          <Input
            type="textarea"
            rows="3"
            value={localData.blackIp}
            onChange={(e) => {
              setLocalData({ ...localData, blackIp: e.target.value })
            }}
          />
          <div className="d-inline-block mr-1">
            <Checkbox
              color="primary"
              icon={<Check className="vx-icon" size={10} />}
              checked={localData.ipWithoutGeoFilter}
              onChange={() => setLocalData({ ...localData, ipWithoutGeoFilter: !localData.ipWithoutGeoFilter })}
              label={getSystemLanguage() === 'RU' ? 'Фильтровать Ip без Geo' : 'Filter Ip Without Geo'}
              size="sm"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="data-ISP">ISP</Label>
          <Input
            type="textarea"
            rows="3"
            value={localData.isp}
            onChange={(e) => {
              setLocalData({ ...localData, isp: e.target.value })
            }}
          />
          <div className="d-inline-block mr-1">
            <Checkbox
              color="primary"
              icon={<Check className="vx-icon" size={10} />}
              checked={localData.ispEmptyFilter}
              onChange={() => setLocalData({ ...localData, ispEmptyFilter: !localData.ispEmptyFilter })}
              label={getSystemLanguage() === 'RU' ? 'Фильтровать пустой Isp' : 'Filter Empty Isp'}
              size="sm"
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="data-user-agent">User Agent</Label>
          <Input
            type="textarea"
            rows="3"
            value={localData.userAgent}
            onChange={(e) => {
              setLocalData({ ...localData, userAgent: e.target.value })
            }}
          />
        </FormGroup>
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit(localData)}>
          {data !== null ? getSystemLanguage() === 'RU' ? 'Сохранить' : 'Update' : getSystemLanguage() === 'RU' ? 'Создать' : 'Save'}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          {getSystemLanguage() === 'RU' ? 'Отмена' : 'Cancel'}
          </Button>
      </div>
    </div>
  )
}
export default DataListSidebar
