import React from 'react'
import fetchApi from './utility/context/fetchApi';
import { history } from './history'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (history?.location?.pathname === '/campaigns' && (!Boolean(localStorage.getItem('user')) || localStorage.getItem('user') === 'null')) {
      localStorage.clear()
      history.push(`/login${window.location.search}`)
    } else  if (history?.location?.pathname === '/campaigns' && localStorage.getItem('user')) {
      window.location.reload()
    } else if (history?.location?.pathname !== '/login') {
      fetchApi({
        url: `/auth/error-notify`,
        method: 'POST',
        body: { error, errorInfo, location: history?.location?.pathname, user: localStorage.getItem('user') }
      })
      history.push(`/login${window.location.search}`)
    }
    this.setState({hasError: false})
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. Write to Support Team on <a target="_blank" rel="noopener noreferrer" href="https://t.me/cloudfilter">Telegram</a> <svg style={{ paddingLeft: 0, stroke: 'white', fill: 'white' }} height="12pt" viewBox="0 -39 512.00011 512" width="12pt" xmlns="http://www.w3.org/2000/svg">
        <path d="m504.09375 11.859375c-6.253906-7.648437-15.621094-11.859375-26.378906-11.859375-5.847656 0-12.042969 1.230469-18.410156 3.664062l-433.398438 165.441407c-23 8.777343-26.097656 21.949219-25.8984375 29.019531s4.0390625 20.046875 27.4999995 27.511719c.140626.042969.28125.085937.421876.125l89.898437 25.726562 48.617187 139.023438c6.628907 18.953125 21.507813 30.726562 38.835938 30.726562 10.925781 0 21.671875-4.578125 31.078125-13.234375l55.605469-51.199218 80.652344 64.941406c.007812.007812.019531.011718.027343.019531l.765625.617187c.070313.054688.144532.113282.214844.167969 8.964844 6.953125 18.75 10.625 28.308594 10.628907h.003906c18.675781 0 33.546875-13.824219 37.878906-35.214844l71.011719-350.640625c2.851563-14.074219.460937-26.667969-6.734375-35.464844zm-356.191406 234.742187 173.441406-88.605468-107.996094 114.753906c-1.769531 1.878906-3.023437 4.179688-3.640625 6.683594l-20.824219 84.351562zm68.132812 139.332032c-.71875.660156-1.441406 1.25-2.164062 1.792968l19.320312-78.25 35.144532 28.300782zm265.390625-344.566406-71.011719 350.644531c-.683593 3.355469-2.867187 11.164062-8.480468 11.164062-2.773438 0-6.257813-1.511719-9.824219-4.257812l-91.390625-73.585938c-.011719-.011719-.027344-.023437-.042969-.03125l-54.378906-43.789062 156.175781-165.949219c5-5.3125 5.453125-13.449219 1.074219-19.285156-4.382813-5.835938-12.324219-7.671875-18.820313-4.351563l-256.867187 131.226563-91.121094-26.070313 433.265625-165.390625c3.660156-1.398437 6.214844-1.691406 7.710938-1.691406.917968 0 2.550781.109375 3.15625.855469.796875.972656 1.8125 4.289062.554687 10.511719zm0 0" />
      </svg></h1>
    }

    return this.props.children;
  }
}