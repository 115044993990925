import React, { Fragment, useState } from "react"
import { connect } from "react-redux"
import { Label, Row, Input, Form, Button } from 'reactstrap'
import { setPromocode, setUser } from "../../redux/actions"
import { promocodeSelector, tokenSelector } from "../../redux/selectors"
import fetchApi from "../../utility/context/fetchApi"
import notify from "../../utility/context/notify"

function AffiliateRegister(props) {
    const [state, setState] = useState({ promocode: '', wallet: '', telegram: props.user.telegram })
    const updateUserData = (user) =>
        fetchApi({
            url: `/users/${user._id}`,
            method: 'PATCH',
            token: props.token,
            body: user
        }).then((user) => {
            localStorage.setItem('user', JSON.stringify({ ...props.userFull, user: { ...props.user, ...user } }))
            props.setUser(user)
        })
    const addPromocode = (data) => {
            fetchApi({
                url: '/promocode',
                method: 'POST',
                token: props.token,
                body: data
            }).then((doc) => {
                props.setPromocode([doc, ...props.promocodes])
            })
        }
    return (
        <Fragment>
            <div className='content-header'>
                <h5 style={{
                    fontSize: '21px',
                    marginLeft: '3px',
                    marginBottom: '18px',
                    color: '#616161'
                }}>Join to Affiliate</h5>
            </div>
            <Form onSubmit={e => e.preventDefault()}>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-name">Promocode Name</Label>
                        <Input
                            type="text"
                            required
                            value={state?.promocode}
                            onChange={e => setState({ ...state, promocode: e.target.value })}
                        />
                    </div>
                </Row>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-name">USDT TRC20 for Payments</Label>
                        <Input
                            type="text"
                            required
                            value={state?.wallet}
                            onChange={e => setState({ ...state, wallet: e.target.value })}
                        />
                    </div>
                </Row>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-name">Telegram</Label>
                        <Input
                            type="text"
                            value={state?.telegram}
                            onChange={e => setState({ ...state, telegram: e.target.value })}
                        />
                    </div>
                </Row>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div />
                    <Button.Ripple style={{ width: 130 }} color='primary' className='btn-next' onClick={() => {
                        if (props.promocodes.some(({name}) => name.toLocaleUpperCase() === state.promocode.toLocaleUpperCase())) {
                            notify('Promocode is alredy taken. Try another one.', 'error')
                        } else {
                            Promise.all([
                                updateUserData({ ...props.user, affiliate: state.promocode, wallet: state.wallet, telegram: state.telegram }),
                                addPromocode({ name: state.promocode, discount: 40 })
                            ])
                        }
                    }}>
                        <span className='align-middle d-sm-inline-block'>Submit</span>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        userFull: state.app.user,
        promocodes: promocodeSelector(state) || [],
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setPromocode, setUser })(AffiliateRegister)