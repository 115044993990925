import {
  RESET_VALUE,
  SET_AFFILIATE_MODE,
  SET_BLACK_IPS,
  SET_BLACK_LIST,
  SET_COINS,
  SET_COMPANIES,
  SET_COMPANY_LIMIT,
  SET_FILTER_COMPANIES,
  SET_FILTER_DATE_COMPANIES,
  SET_INIT_VALUE,
  SET_ISP,
  SET_PAYMENTS,
  SET_PROMOCODE,
  SET_PROMOS,
  SET_STATISTIC,
  SET_STATISTIC_LIMIT,
  SET_TARIFS,
  SET_USER,
  SET_USERS,
  SET_USER_AGENT,
  SET_VALUE
} from './constants'

import { assocPath } from 'ramda'
import { combineReducers } from 'redux'
import themeConfig from '../configs/themeConfig'

try {
  localStorage.user && JSON.parse(localStorage.user)
} catch (error) {
  console.log('error: ', error);
  localStorage.clear()
}

const initialState = {
  statisticLimit: 10,
  companyLimit: 10,
  notification: '',
  lang: '',
  theme: themeConfig,
  filterDateCompanies: 3,
  filterCompanies: '',
  user: localStorage.user && JSON.parse(localStorage.user),
  tarifs: null,
  promos: null,
  isp: null,
  userAgent: null,
  blackList: null,
  promocode: null,
  users: null,
  payments: null,
  coins: null,
  blackIps: null,
  companies: null,
  statistic: null,
  affiliateMode: false
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VALUE:
      return assocPath(action.payload.path, action.payload.value, state)
    case SET_FILTER_DATE_COMPANIES:
      return { ...state, filterDateCompanies: action.payload }
    case SET_STATISTIC_LIMIT:
      return { ...state, statisticLimit: action.payload }
    case SET_COMPANY_LIMIT:
      return { ...state, companyLimit: action.payload }
    case SET_FILTER_COMPANIES:
      return { ...state, filterCompanies: action.payload }
    case SET_BLACK_IPS:
      return assocPath(['blackIps'], action.payload, state)
    case SET_TARIFS:
      return assocPath(['tarifs'], action.payload, state)
    case SET_PROMOS:
      return assocPath(['promos'], action.payload, state)
    case SET_ISP:
      return assocPath(['isp'], action.payload, state)
    case SET_BLACK_LIST:
      return assocPath(['blackList'], action.payload, state)
    case SET_USER_AGENT:
      return assocPath(['userAgent'], action.payload, state)
    case SET_PROMOCODE:
      return assocPath(['promocode'], action.payload, state)
    case SET_USERS:
      return assocPath(['users'], action.payload, state)
    case SET_PAYMENTS:
      return assocPath(['payments'], action.payload, state)
    case SET_COINS:
      return assocPath(['coins'], action.payload, state)
    case SET_COMPANIES:
      return assocPath(['companies'], action.payload, state)
    case SET_STATISTIC:
      return assocPath(['statistic'], action.payload, state)
    case RESET_VALUE:
      return { ...initialState, user: null }
    case SET_USER:
      return { ...state, user: { ...state.user, user: action.payload } }
    case SET_AFFILIATE_MODE:
      return { ...state, affiliateMode: action.payload }
    case SET_INIT_VALUE:
      return { ...initialState, user: {...state.user, user: action.payload.user}, blackList: action.payload.blackList, 
        tarifs: action.payload.tarifs, promocode: action.payload.promocodes, coins: action.payload.coins, promos: action.payload.promos }
    default:
      return state
  }
}

export default function createRootReducer() {
  return combineReducers({ app: appReducer })
}