import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup, Button } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import { initialCoin } from "./utils"

function _arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const CoinsDataListSidebar = ({ show, data, updateData, addData, cancelSidebar }) => {
  const [localData, setLocalData] = useState(data || initialCoin)

  useEffect(() => {
    setLocalData(data || initialCoin)
  }, [data])

  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialCoin)
  }
  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? "UPDATE DATA" : "ADD NEW DATA"}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-name">Name</Label>
          <Input
            type="text"
            value={localData.nameFull}
            placeholder=""
            onChange={e => setLocalData({ ...localData, nameFull: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-name">Name Short</Label>
          <Input
            type="text"
            value={localData.nameShort}
            placeholder=""
            onChange={e => setLocalData({ ...localData, nameShort: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-name">Address</Label>
          <Input
            type="text"
            value={localData.address}
            placeholder=""
            onChange={e => setLocalData({ ...localData, address: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-limit">Sort Order</Label>
          <Input
            type="number"
            value={localData.sortOrder}
            placeholder=""
            onChange={e => setLocalData({ ...localData, sortOrder: e.target.value })}
            id="data-limit"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-price">Index</Label>
          <Input
            type="number"
            value={localData.index}
            placeholder=""
            onChange={e => setLocalData({ ...localData, index: e.target.value })}
            id="data-price"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-qrImg">QR Image</Label>
          <Input
            type="file"
            accept=".png"
            placeholder=""
            onChange={
              function (e) {
                if (e.target.files && e.target.files[0]) {
                  const reader = new FileReader()
                  reader.readAsArrayBuffer(e.target.files[0])
                  reader.onload = function () {
                    setLocalData({ ...localData, qrImg: _arrayBufferToBase64(reader.result) })
                  }
                }
              }
            }
            id="data-qrImg"
          />
        </FormGroup>
        {localData.qrImg ? <FormGroup>
          <p style={{ textAlign: 'center' }}><span style={{ textAlign: 'center' }}><img
            src={'data:image/png;base64,' + localData.qrImg}
            className="mx-auto"
            width="100px"
          /></span></p>
          <Button
            className="mx-auto"
            color="danger"
            outline
            onClick={() => setLocalData({ ...localData, qrImg: '' })}>
            Remove QR code
          </Button>
        </FormGroup> : null}
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit(localData)}>
          {data !== null ? "Update" : "Submit"}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          Cancel
          </Button>
      </div>
    </div>
  )
}
export default CoinsDataListSidebar
