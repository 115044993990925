import React from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { tokenSelector } from "../../redux/selectors"
import { setValueBinded } from "../../redux/actions"
import fetchApi from "../../utility/context/fetchApi"
import notify from "../../utility/context/notify"
import { getSystemLanguage } from "./languages"

function UserPage(props) {
    const [old, setOld] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirm, setConfirm] = useState('')
    const [telegram, setTelegram] = useState(props.user.telegram)
    const [whiteListIp, setWhiteListIp] = useState(props.user.whiteListIp || '')

    const submit = () => {
        if (newPass) {
            if (newPass !== confirm) {
                notify(getSystemLanguage() === 'RU' ? 'Пароль должен совпадать.' : 'Password must be the same.', 'error')
                return
            }
            if (newPass === '') {
                notify(getSystemLanguage() === 'RU' ? 'Введите новый пароль.' : 'New password is empty.', 'error')
                return
            }
            if (old === '') {
                notify(getSystemLanguage() === 'RU' ? 'Введите старый пароль' : 'Old password is empty.', 'error')
                return
            }
        }
        fetchApi({
            url: `/users/${props.user._id}`,
            method: 'PATCH',
            token: props.token,
            body: { telegram, whiteListIp, ...(newPass ? { password: newPass } : {}) }
        }).then((user) => {
            if (user) {
                props.setUser(user)
                notify(getSystemLanguage() === 'RU' ? 'Обновлено успешно' : 'Updated Successfully', 'success')
            }
        })
    }

    return (<div>
        <Form>
            <Row>
                <Col sm="8">
                    <FormGroup>
                        <Label for="EmailVertical">Email</Label>
                        <Input
                            disabled
                            value={props.user.email}
                            type="email"
                            name="Email"
                            id="EmailVertical"
                            placeholder="Email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="TelegramVertical">Telegram</Label>
                        <Input
                            value={telegram}
                            onChange={(e) => setTelegram(e.target.value)}
                            type="text"
                            name="Telegram"
                            id="TelegramVertical"
                        />
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Label for="">{getSystemLanguage() === 'RU' ? 'Старый пароль' : 'Old Password'}</Label>
                        <Input
                            type="password"
                            name="password"
                            value={old}
                            onChange={(e) => setOld(e.target.value)}
                            id="passwordVertical"
                            placeholder="Password"
                        />
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Label for="">{getSystemLanguage() === 'RU' ? 'Новый пароль' : 'New Password'}</Label>
                        <Input
                            type="password"
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                            name="password"
                            id="passwordVertical"
                            placeholder="Password"
                        />
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Label for="">{getSystemLanguage() === 'RU' ? 'Подтвердить пароль' : 'Confirm Password'}</Label>
                        <Input
                            type="password"
                            name="password"
                            value={confirm}
                            onChange={(e) => setConfirm(e.target.value)}
                            id="passwordVertical"
                            placeholder="Password"
                        />
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Label for="">Whitelist IP</Label>
                        <Input
                            type="textarea"
                            rows="3"
                            value={whiteListIp}
                            onChange={(e) => {
                                if (e.target.value.split('\n').length <= 100) {
                                    setWhiteListIp(e.target.value)
                                } else {
                                    notify('You have reached limit. Maximum 100', 'error')
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Button.Ripple
                            color="primary"
                            className="mr-1 mb-1"
                            onClick={submit}
                        >
                            {getSystemLanguage() === 'RU' ? 'Сохранить' : 'Save'}
                  </Button.Ripple>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    </div>)
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setUser: setValueBinded(['user', 'user']) })(UserPage)