import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather"
import React, { useEffect, useState } from "react"
import { comparators, getSliceData, getSortIndex, getTotalPages, typeDate, typeString } from "./utils"

import DataTable from "react-data-table-component"
import ReactPaginate from "react-paginate"
import { typeNumber } from "./utils"

const hideEmail = function(email) {
    return email.replace(/(.{2})(.*)(?=@)/,
      function(gp1, gp2, gp3) { 
        for(let i = 0; i < gp3.length; i++) { 
          gp2+= "*"; 
        } return gp2; 
      });
  };

const spanStyle = { maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis' }

export default function AffiliatePageStat(props) {
    const [data, setData] = useState([])
    const [, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit] = useState(5)
    const [totalPages, setTotalPages] = useState(1)
    const [, setSortIndex] = useState([1, 2])
    const [filter] = useState('')
    const [sort, setSort] = useState({ selector: 'createdAt', type: 'asc', typeData: typeDate })

    useEffect(() => {
        if (props.dataAll) {
            const allDataSorted = props.dataAll.slice().sort(comparators[sort.typeData](sort.selector))
            if (sort.type === 'asc') allDataSorted.reverse()
            if (filter) {
                const filteredData = allDataSorted
                    .filter((row) =>
                        (row.email && row.email.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.promocode && row.promocode.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.name && row.name.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.method && row.method.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.type && row.type.toLowerCase().includes(filter.toLowerCase()))
                    )
                setData(filteredData)
                setTotal(filteredData.length)
                setTotalPages(1)
                setPage(0)
                setSortIndex([1, filteredData.length])
            } else {
                setData(getSliceData(allDataSorted, page, limit))
                setTotal(allDataSorted.length)
                setTotalPages(getTotalPages(allDataSorted, limit))
                setSortIndex(getSortIndex(page, limit, allDataSorted.length))
            }
        }
    }, [page, props.dataAll, limit, filter, sort])

    const handleSort = ({ selector, type: typeData }, type) => {
        setSort({ selector, type, typeData })
    }

    const columns = [
        {
            name: 'Date',
            type: typeDate,
            selector: "createdAt",
            flexGrow: 1,
            cell: row => {
                const date = (row.createdAt && new Date(row.createdAt)) || new Date()
                return <span>{date.toLocaleTimeString()}<br />{new Intl.DateTimeFormat('en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(date)}</span>
            }
        },
        {
            name: 'Customer',
            type: typeString,
            selector: "email",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{hideEmail(row.email)}</span>
        },
        {
            name: 'Revenue',
            type: typeNumber,
            selector: "total",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{row.total * 0.2}$</span>
        }
    ]
    if (!props.dataAll) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 200 }}>
        </div>
    }

    return <div className='data-list simple' style={{ marginTop: 30 }}>
        <span style={{ fontSize: '1.5rem', marginLeft: '3px', fontWeight: 500 }}>Stats</span>
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            responsive
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
        />
    </div>
}