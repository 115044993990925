import { Navbar, UncontrolledAlert } from "reactstrap"

import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import React from "react"
import classnames from "classnames"
import { connect } from "react-redux"
import { getSystemLanguage } from "../../../views/pages/languages"
import { history } from "../../../history"

const ThemeNavbar = props => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]
  if (!props.user) return null
  const endDate = new Date(props.user?.subscriptionEndAt)
  const isActive = new Date() < endDate
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  isActive={isActive}
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={props.user.email}
              />
            </div>
          </div>
        </div>
      </Navbar>
      {isActive ? null : <div style={{ padding: '10px 30px' }}><UncontrolledAlert color="danger">
        {getSystemLanguage() === 'RU' ? 'Привет, Ваша Cloakit.pro подписка закончилась.' : 'Hi, your Cloakit.pro subscription has been expired already.' }
        {getSystemLanguage() === 'RU' ? 'Чтобы продлить подписку перейдите на страницу' : 'To renew your subscription go to'}  <a href='' onClick={() => history.push('/payments')}>{ getSystemLanguage() === 'RU' ? 'оплаты' : 'payments' }</a> { getSystemLanguage() === 'RU' ? 'и выберите тарифный план' : 'and buy plan' }.
                </UncontrolledAlert></div>}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.app.user?.user
  }
}

export default connect(mapStateToProps)(ThemeNavbar)
