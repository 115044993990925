export const MONTH = 'month'
export const YEAR = 'year'

export const BTC = 'BTC'
export const ETH = 'ETH'
export const USDT = 'USDT'
export const USDT20 = 'USDT TRC 20'
export const USD = 'USD'
export const RUB = 'RUB'
export const CARD = 'CARD'
export const LTC = 'LTC'

export const PRIVAT = 'Приват 24 UAH'
export const MONO = 'Монобанк UAH'
export const VISA = 'VISA/MasterCard UAH'
export const TINKOV = 'Тинькофф RUB'
export const SBERBANK = 'Сбербанк RUB'