import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
} from "reactstrap"
import { history } from "../../../history"
import SweetAlert from "react-bootstrap-sweetalert"
import validator from "validator"
import notify from "../../../utility/context/notify"
import fetchApi from "../../../utility/context/fetchApi"
import logo from "../../../assets/img/logo/logo.png"
import { getSystemLanguage } from "../languages"
import { connect } from "react-redux"

class ForgotPassword extends React.Component {
  state = {
    email: "",
    alert: false
  }
  handleRegister = async e => {
    e.preventDefault()
    if (!validator.isEmail(this.state.email)) {
      return notify(getSystemLanguage() === 'RU' ? 'Email должен быть корректным.' : 'Email must be correct.', 'error')
    }
    await fetchApi({
      url: '/auth/forgot-password', method: 'POST', body: {
        email: this.state.email,
      }
    })
    this.setState({alert: true})
  }
  render() {
    return (
      <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication rounded-0 mb-0"  style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
            <Row className="m-0">
              <Col lg="12" md="12" className="p-0">
                <Card className="mb-0 px-2 py-1 login-custom-width" style={{backgroundColor: 'transparent', border: '1px solid #ff9e4363', borderRadius: '7px !important'}}>
                  <CardHeader className="pb-1">
                    <img style={{width: 240, height: 65, display: 'block', margin: 'auto'}} src={logo} alt="logo" />
                  </CardHeader>
                  <CardBody className="pt-1 pb-0">
                    <h4 style={{color: 'white', textAlign: 'center', marginBottom: '16px'}}>{getSystemLanguage() === 'RU' ? 'Восстановление Пароля' : 'Recover your password'}</h4>
                    <Form onSubmit={this.handleRegister}>
                      <FormGroup className="form-label-group">
                        <Input type="text" placeholder="Email" required value={this.state.email}
                          onChange={e => this.setState({ email: e.target.value })} />
                      </FormGroup>
                      <div className="float-md-left d-block mb-1">
                        <Button.Ripple
                          color="primary"
                          outline
                          className="px-75 btn-block"
                          onClick={() => history.push(`/login${window.location.search}`)}
                        >
                          {getSystemLanguage() === 'RU' ? 'Вернуться на главную' : 'Back to Login'}
                        </Button.Ripple>
                      </div>
                      <div className="float-md-right d-block mb-1">
                        <Button.Ripple
                          color="primary"
                          type="submit"
                          className="px-75 btn-block"
                        >
                          {getSystemLanguage() === 'RU' ? 'Сбросить Пароль' : 'Reset Password'}
                        </Button.Ripple>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
          <SweetAlert title={getSystemLanguage() === 'RU' ? 'Новый пароль отправлен на вашу почту!' : 'New password was send to your email!'}
            closeOnClickOutside
            show={this.state.alert}
            onConfirm={() => {
              history.push(`/login${window.location.search}`)
              this.setState({ alert: false })
            }}
            onCancel={() => {
              history.push(`/login${window.location.search}`)
              this.setState({ alert: false })
            }}
          >
          </SweetAlert>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      langsss: state.app.lang
  }
}

export default connect(mapStateToProps)(ForgotPassword)