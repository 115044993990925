import React, { useEffect, useState } from "react"
import Select from 'react-select'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';

import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import { tarifsSelector, tokenSelector } from "../../redux/selectors"
import { setBlackIps } from "../../redux/actions"
import {
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Form,
    Label,
    Input,
    FormGroup,
    Button
} from 'reactstrap'

import '../../assets/scss/plugins/extensions/editor.scss'
import '../../assets/scss/plugins/extensions/form-quill-editor.scss'
import '../../assets/scss/plugins/extensions/_react-select.scss'
import '../../assets/scss/plugins/extensions/page-blog.scss'
import { AllHtmlEntities } from 'html-entities'
import { history } from "../../history"

const entities = new AllHtmlEntities()

function EditPosts(props) {
    let postId = props?.match?.params?.postId
    if (postId === 'new') {
        postId = undefined
    }
    const initialContent = `
    <p></p>
    `
    let contentBlock = htmlToDraft(initialContent)
    let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    let editorState = EditorState.createWithContent(contentState)

    const [data, setData] = useState({})
    const [title, setTitle] = useState('Post Title')
    const [description, setDescription] = useState('Short Description')
    const [status, setStatus] = useState('Draft')
    const [tarifIds, setTarifIds] = useState([])
    const [content, setContent] = useState(editorState)
    const [featuredImg, setFeaturedImg] = useState('')

    const deviceOptions = props.tarifs?.map(({ _id, name }) => ({ value: _id, label: name })) || []

    useEffect(() => {
        if (postId) {
            fetchApi({
                url: `/post/${postId}`,
                method: 'GET',
                token: props.token,
            }).then((post) => {
                setData(post)
                setTitle(post.blogTitle)
                setFeaturedImg(post.featuredImage)
                setStatus(post.status)
                setDescription(post.description)
                setTarifIds(post.tarifIds)
                contentBlock = htmlToDraft(entities.decode(post.excerpt))
                contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                editorState = EditorState.createWithContent(contentState)
                setContent(editorState)
            })
        }
    }, [postId, props.token])

    const addData = (data) => {
        fetchApi({
            url: '/post',
            method: 'POST',
            token: props.token,
            body: data
        }).then(() => {
            history.push('/admin/edit-posts')
        })
    }

    const updateData = ({ _id, ...data }) => {
        fetchApi({
            url: `/post/${_id}`,
            method: 'PATCH',
            token: props.token,
            body: data
        }).then(() => {
            history.push('/admin/edit-posts')
        })
    }

    return (
        <div className='blog-edit-wrapper'>
            {data !== null ? (
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='mt-2' onSubmit={e => e.preventDefault()}>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup className='mb-2'>
                                                <Label for='blog-edit-title'>Title</Label>
                                                <Input id='blog-edit-title' value={title} onChange={e => setTitle(e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup className='mb-2'>
                                                <Label for='blog-edit-status'>Status</Label>
                                                <Input
                                                    type='select'
                                                    id='blog-edit-status'
                                                    value={status}
                                                    onChange={e => setStatus(e.target.value)}
                                                >
                                                    <option value='Published'>Published</option>
                                                    <option value='Draft'>Draft</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md='12'>
                                            <FormGroup className='mb-2'>
                                                <Label for='blog-edit-status'>Tarifs Visible</Label>
                                                <Select
                                                    value={deviceOptions
                                                        .filter(({ value }) => tarifIds?.includes(value))}
                                                    onChange={(value) => setTarifIds(value?.map(({ value }) => value) || [])}
                                                    isMulti
                                                    size="sm"
                                                    options={deviceOptions}
                                                    className="React"
                                                    classNamePrefix="select"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='12'>
                                            <FormGroup className='mb-2'>
                                                <Label for='blog-edit-status'>Short Description</Label>
                                                <Input
                                                    type="textarea"
                                                    size="sm"
                                                    rows="3"
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='12'>
                                            <FormGroup className='mb-2'>
                                                <Label>Content</Label>
                                                <Editor editorState={content} onEditorStateChange={data => setContent(data)} />
                                            </FormGroup>
                                        </Col>
                                        <Col className='mb-2' sm='12'>
                                            <div className='border rounded p-2'>
                                                <h4 className='mb-1'>Main Image</h4>
                                                <Media className='flex-column flex-md-row'>
                                                    <img
                                                        className='rounded mr-2 mb-1 mb-md-0'
                                                        src={featuredImg}
                                                        alt='featured img'
                                                        width='170'
                                                        height='110'
                                                    />
                                                    <Media body>
                                                        <div>
                                                            <FormGroup className='mb-0'>
                                                                <Input
                                                                    name='customFile'
                                                                    value={featuredImg}
                                                                    onChange={function (e) {
                                                                        setFeaturedImg(e.target.value)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </Media>
                                                </Media>
                                            </div>
                                        </Col>
                                        <Col className='mt-50'>
                                            <Button.Ripple color='primary' className='mr-1' onClick={() => !postId ? addData({
                                                blogTitle: title, tarifIds, excerpt: entities.encode(draftToHtml(convertToRaw(content.getCurrentContent()))),
                                                featuredImage: featuredImg,
                                                status,
                                                description
                                            }) : updateData({
                                                _id: postId,
                                                blogTitle: title, tarifIds, excerpt: entities.encode(draftToHtml(convertToRaw(content.getCurrentContent()))),
                                                featuredImage: featuredImg,
                                                status,
                                                description
                                            })}>
                                                {postId ? 'Update Post' : 'Save Post'}
                                            </Button.Ripple>
                                            <Button.Ripple color='secondary' outline onClick={() => history.push('/admin/edit-posts')}>
                                                Cancel
                                            </Button.Ripple>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : null}
        </div>
    )

}

const mapStateToProps = state => {
    return {
        tarifs: tarifsSelector(state),
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setBlackIps })(EditPosts)
