import {
  RESET_VALUE,
  SET_AFFILIATE_MODE,
  SET_BLACK_IPS,
  SET_BLACK_LIST,
  SET_COINS,
  SET_COMPANIES,
  SET_COMPANY_LIMIT,
  SET_FILTER_COMPANIES,
  SET_FILTER_DATE_COMPANIES,
  SET_INIT_VALUE,
  SET_ISP,
  SET_PAYMENTS,
  SET_PROMOCODE,
  SET_PROMOS,
  SET_STATISTIC,
  SET_STATISTIC_LIMIT,
  SET_TARIFS,
  SET_USER,
  SET_USERS,
  SET_USER_AGENT,
  SET_VALUE
} from './constants'

export const setValue = (path, value) => ({ type: SET_VALUE, payload: { path, value } })

export const setUser = (value) => ({ type: SET_USER, payload: value })

export const setAffiliateMode = (value) => ({ type: SET_AFFILIATE_MODE, payload: value })

export const setStatisticLimit = (value) => ({ type: SET_STATISTIC_LIMIT, payload: value })

export const setCompanyLimit = (value) => ({ type: SET_COMPANY_LIMIT, payload: value })

export const setBlackIps = (value) => ({ type: SET_BLACK_IPS, payload: value })

export const setTarifs = (value) => ({ type: SET_TARIFS, payload: value })

export const setPromos = (value) => ({ type: SET_PROMOS, payload: value })

export const setFilterCompanies = (value) => ({ type: SET_FILTER_COMPANIES, payload: value })

export const setPayments = (value) => ({ type: SET_PAYMENTS, payload: value })

export const setCoins = (value) => ({ type: SET_COINS, payload: value })

export const setIsp = (value) => ({ type: SET_ISP, payload: value })

export const setBlackList = (value) => ({ type: SET_BLACK_LIST, payload: value })

export const setInitValue = (value) => ({ type: SET_INIT_VALUE, payload: value })

export const setFilterDateCompanies = (value) => ({ type: SET_FILTER_DATE_COMPANIES, payload: value })

export const setUserAgent = (value) => ({ type: SET_USER_AGENT, payload: value })

export const setPromocode = (value) => ({ type: SET_PROMOCODE, payload: value })

export const setUsers = (value) => ({ type: SET_USERS, payload: value })

export const setCompanies = (value) => ({ type: SET_COMPANIES, payload: value })

export const setStatistic = (value) => ({ type: SET_STATISTIC, payload: value })

export const setValueBinded = (path) => (value) => ({ type: SET_VALUE, payload: { path, value } })

export const resetState = () => ({ type: RESET_VALUE })

export const notify = (message) => setValue(['notification'], message)

export const notificationClose = () => setValue(['notification'], '')

export const setValueFromEventText = (path) => (event) => setValue(path, event.target.value)

export const setValueFromEventSwitch = (path) => (event) => setValue(path, event.target.checked)

export const setValueFromEventTab = (path) => (event, tab) => setValue(path, tab)

