import React, { useState } from "react"
import { Label, Input, FormGroup, Button } from "reactstrap"
import { X } from "react-feather"
import "flatpickr/dist/themes/light.css";
import "../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import PerfectScrollbar from "react-perfect-scrollbar"
import Flatpickr from "react-flatpickr";
import classnames from "classnames"
import Select from "react-select"

const initialState = {}

const UserDataListSidebar = ({ show, data, updateData, addData, cancelSidebar, tarifs }) => {
  const [localData, setLocalData] = useState(data || initialState)
  const [date, setDate] = useState(data.subscriptionEndAt || new Date())
  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialState)
  }
  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? "UPDATE DATA" : "ADD NEW DATA"}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-name">Email</Label>
          <Input
            type="text"
            value={localData.email}
            placeholder=""
            onChange={e => setLocalData({ ...localData, email: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-limit">Plan</Label>
          <Select
            className="React"
            classNamePrefix="select"
            value={{value: localData.tarif, label: tarifs.find(({_id}) => _id === localData.tarif)?.name}}
            onChange={value => setLocalData({ ...localData, tarif: value.value })}
            name="Plan"
            options={tarifs.sort((a, b)=>a.limit - b.limit).map(({_id: value, name: label}) => ({value, label}))}
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-telegram">Telegram</Label>
          <Input
            type="text"
            value={localData.telegram}
            placeholder=""
            onChange={e => setLocalData({ ...localData, telegram: e.target.value })}
            id="data-telegram"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-promocode">Promocode</Label>
          <Input
            type="text"
            value={localData.promocode}
            placeholder=""
            onChange={e => setLocalData({ ...localData, promocode: e.target.value })}
            id="data-promocode"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-affiliate">Affiliate</Label>
          <Input
            type="text"
            value={localData.affiliate}
            placeholder=""
            onChange={e => setLocalData({ ...localData, affiliate: e.target.value })}
            id="data-affiliate"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-wallet">USDT TRC20 for Payments</Label>
          <Input
            type="text"
            value={localData.wallet}
            placeholder=""
            onChange={e => setLocalData({ ...localData, wallet: e.target.value })}
            id="data-wallet"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-price">Subsription Ent At</Label>
          <Flatpickr
              className="form-control"
              value={date}
              onChange={date => setDate(date[0].toISOString())}
            />
        </FormGroup>
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit({...localData, subscriptionEndAt: date})}>
          {data !== null ? "Update" : "Submit"}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          Cancel
          </Button>
      </div>
    </div>
  )
}
export default UserDataListSidebar
