import React from 'react'
import { connect } from 'react-redux'
import { setValueBinded } from '../../../../redux/actions'
import { tokenSelector } from '../../../../redux/selectors'
import fetchApi from '../../../../utility/context/fetchApi'
import { getSystemLanguage } from '../../../../views/pages/languages'
import './LanguageStyles.scss'

const Lang = (props) => {
    return <span className='lang-custom-class' onClick={() => {
        if (props.withoutServer) {
            localStorage.setItem('lang', props.lang)
            props.setLang(props.lang)
            return 
        }
        fetchApi({
            url: `/users/${props.user._id}`,
            method: 'PATCH',
            token: props.token,
            body: { lang: props.lang }
        }).then((user) => {
            if (user) {
                localStorage.setItem('lang', user?.lang || getSystemLanguage())
                props.setUser(user)
            }
        })
    }}>{props.lang}</span>
}

function LanguageSelector(props) {
    return <span className='lang-custom-wrapper-class'><Lang lang='EN' {...props} /> | <Lang lang='RU' {...props} /></span>
}

const mapStateToProps = state => {
    return {
        langsss: state.app.lang,
        user: state.app.user?.user,
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setUser: setValueBinded(['user', 'user']), setLang: setValueBinded(['lang']) })(LanguageSelector)