export const SET_VALUE = 'SET_VALUE'
export const SET_BLACK_IPS = 'SET_BLACK_IPS'
export const SET_TARIFS = 'SET_TARIFS'
export const SET_PROMOS = 'SET_PROMOS'
export const SET_ISP = 'SET_ISP'
export const SET_BLACK_LIST = 'SET_BLACK_LIST'
export const SET_USER_AGENT = 'SET_USER_AGENT'
export const SET_PROMOCODE = 'SET_PROMOCODE'
export const SET_USERS = 'SET_USERS'
export const SET_USER = 'SET_USER'
export const SET_FILTER_COMPANIES = 'SET_FILTER_COMPANIES'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_PAYMENTS = 'SET_PAYMENTS'
export const SET_COINS = 'SET_COINS'
export const SET_STATISTIC = 'SET_STATISTIC'
export const SET_AFFILIATE_MODE = 'SET_AFFILIATE_MODE'
export const RESET_VALUE = 'RESET_VALUE'
export const SET_INIT_VALUE = 'SET_INIT_VALUE'
export const SET_FILTER_DATE_COMPANIES = 'SET_FILTER_DATE_COMPANIES'
export const SET_STATISTIC_LIMIT = 'SET_STATISTIC_LIMIT'
export const SET_COMPANY_LIMIT = 'SET_COMPANY_LIMIT'
