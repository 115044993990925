import React from 'react'
import { Badge, Nav, NavItem, NavLink } from "reactstrap"
import className from 'classnames'
import { MONTH, YEAR } from './constants'
import { getSystemLanguage } from '../languages'

const NavItemC = (props) => <NavItem style={{ position: 'relative' }}>
    <NavLink
        style={{ backgroundColor: props.current === props.target ? '#20ac4b' : 'inherit' }}
        className={className({ active: props.current === props.target })}
        onClick={() => props.onClick(props.target)}
    >
        {props.title}
    </NavLink>
    {props.children}
</NavItem>

const Navs = (props) =>
    <Nav pills className="justify-content-center">
        <NavItemC target={MONTH} title={getSystemLanguage() === 'RU' ? 'Месяц' : 'Month'} current={props.current} onClick={props.onClick}>
        </NavItemC>
        <NavItemC target={YEAR} title={getSystemLanguage() === 'RU' ? 'Год' : 'Year'} current={props.current} onClick={props.onClick} >
            <Badge style={{ position: 'absolute', top: -6, right: -33, backgroundColor: '#20ac4b' }}>-50%</Badge>
        </NavItemC>
    </Nav>

export default Navs