import React, { Suspense, useState } from "react"
import { Route, Router, Switch } from "react-router-dom"
import languages, { getSystemLanguage } from "./views/pages/languages"

import Coins from "./views/pages/Coins"
import CompanySetup from "./views/pages/CompanySetup"
import { ContextLayout } from "./utility/context/Layout"
import Login from "./views/pages/authentication/login/Login"
import Particles from 'react-particles-js'
import PaymentsAdmin from "./views/pages/PaymentsAdmin"
import Spinner from "./components/@vuexy/spinner/Loading-spinner"
import affiliate from "./views/pages/Affiliate"
import affiliatePage from "./views/pages/AffiliatePage"
import authorized from "./views/pages/misc/Verified"
import blackIps from "./views/pages/BlackIps"
import blackList from "./views/pages/BlackList"
import compaigns from "./views/pages/Companies"
import config from './particles.json'
import editPosts from "./views/pages/EditPosts"
import error404 from "./views/pages/misc/error/404"
import forgotPassword from "./views/pages/authentication/ForgotPassword"
import { history } from "./history"
import isp from "./views/pages/Isp"
import knowledgeBase from "./views/pages/KnowledgeBase"
import notify from "./utility/context/notify"
import payments from "./views/pages/Payments"
import postList from "./views/pages/PostList"
import promocode from "./views/pages/Promocode"
import promos from "./views/pages/Promo"
import readPost from "./views/pages/ReadPost"
import register from "./views/pages/authentication/register/Register"
import statistics from "./views/pages/Statistics"
import support from "./views/pages/Support"
import tarifs from "./views/pages/Tarifs"
import userAgent from "./views/pages/UserAgent"
import userPage from "./views/pages/UserPage"
// Route-based code splitting
import users from "./views/pages/Users"

// Set Layout and Component Using App Route
const RouteConfig = ({ user, requireUser = true, requireActiveUser = false, component: Component, fullLayout, particles, ...rest }) => {
  const [loaded] = useState(true)
  if (!user && requireUser === true) {
    history.push(`/login${window.location.search}`)
  }
  
  if (requireActiveUser) {
    const endDate = new Date(user.subscriptionEndAt)
    const isActive = new Date() < endDate
    if (!user.tarif || !isActive) {
        notify(languages[getSystemLanguage()].error.activatePlan, 'error')
        history.push('/payments')
    }
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (props.match.url === '/') {
          history.push('/campaigns')
        }
        return (
          <ContextLayout.Consumer>
            {context => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === "horizontal"
                    ? context.horizontalLayout
                    : context.VerticalLayout
              return (
                <LayoutTag {...props}>
                  <Suspense fallback={<Spinner />}>
                    {particles ? <Particles params={config} style={{ position: 'absolute', top: 0, backgroundColor: 'black' }} /> : null}
                    {!loaded ? <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Spinner color="primary" style={{ margin: '0 auto', alignSelf: 'center' }} size='lg' />
                    </div> : <Component {...props} />}
                  </Suspense>
                </LayoutTag>
              )
            }}
          </ContextLayout.Consumer>
        )
      }}
    />
  )
}

const AppRoute = RouteConfig

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router history={history}>
        <Switch>
          <AppRoute initApp={this.props.initApp} user={this.props.user} exact path="/" component={compaigns} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/users" component={users} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/user-page" component={userPage} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/tarifs" component={tarifs} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/promos" component={promos} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/coins" component={Coins} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/payments" component={PaymentsAdmin} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/black-ips" component={blackIps} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/edit-posts/:postId" component={editPosts} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/edit-posts" component={postList} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/campaigns/setup/:companyId" component={CompanySetup} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/campaigns/:userId" component={compaigns} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/campaigns" component={compaigns} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/statistics/:companyId/:companyName" component={statistics} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/isp" component={isp} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/black-list" component={blackList} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/user-agent" component={userAgent} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/admin/promocode" component={promocode} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/knowledge-base/:postId" component={readPost} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/knowledge-base" component={knowledgeBase} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/payments" component={payments} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/support" component={support} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/affiliate/:userEmail" component={affiliatePage} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} path="/affiliate" component={affiliate} />
          <AppRoute initApp={this.props.initApp} user={this.props.user} requireUser={false} particles path="/login" component={Login} fullLayout />
          <AppRoute initApp={this.props.initApp} user={this.props.user} requireUser={false} particles path="/register" component={register} fullLayout />
          <AppRoute
            initApp={this.props.initApp}
            user={this.props.user}
            particles
            requireUser={false}
            path="/forgot-password"
            component={forgotPassword}
            fullLayout
          />
          <AppRoute
            initApp={this.props.initApp}
            user={this.props.user}
            requireUser={false}
            path="/verified/:verifiedId"
            component={authorized}
            fullLayout
          />
          <AppRoute initApp={this.props.initApp} user={this.props.user} requireUser={false} component={error404} fullLayout />
        </Switch>
      </Router>
    )
  }
}

export default AppRouter
