import * as Icon from "react-feather"

import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  ModalBody,
  UncontrolledDropdown
} from "reactstrap"

import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"
import PerfectScrollbar from "react-perfect-scrollbar"
import React from "react"
import className from 'classnames'
import { connect } from "react-redux"
import { getSystemLanguage } from "../../../views/pages/languages"
import { history } from "../../../history"
import { setValueBinded } from "../../../redux/actions"
import { tarifsSelector } from "../../../redux/selectors"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/user-page")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">{getSystemLanguage() === 'RU' ? 'Профиль' : 'Edit Profile'}</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
          props.setUser(null)
          localStorage.removeItem('user')
          history.push(`/login${window.location.search}`)
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">{getSystemLanguage() === 'RU' ? 'Выйти' : 'Log Out'}</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    modalOpen: false,
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [

    ],
    suggestions: []
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    const today = new Date()
    const endDate = new Date(this.props.user?.subscriptionEndAt)
    const notification = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - 3
    ) < today
    const tarif = (this.props.tarifs && this.props.tarifs.find(({ _id }) => _id === this.props.user?.tarif)) || ''
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <Modal
          isOpen={this.state.modalOpen}
          toggle={() => this.setState({ modalOpen: false })}
          className="modal-dialog-centered"
        >

          <ModalBody className="modal-dialog-centered">
            <div style={{ width: '100%' }}>
              <span style={{ fontWeight: 'bold', fontSize: 18 }}>{getSystemLanguage() === 'RU' ? 'Ваш тариф' : 'Your subscription'}: <span style={{ textTransform: 'uppercase' }}>{tarif?.name}</span></span><br />
              <span style={{ fontWeight: 'bold', fontSize: 17 }}>{getSystemLanguage() === 'RU' ? 'Активен до' : 'Active until'} <span>{new Date(this.props.user?.subscriptionEndAt).toLocaleDateString()}</span></span><br />
              <span style={{ display: 'inline-block', marginTop: 22 }}>{getSystemLanguage() === 'RU' ? 'Для перехода на тарифный план выше, напишите нам в' : 'To upgrade your plan message'} <a target="_blank" rel="noopener noreferrer" href="https://t.me/cloakit">{getSystemLanguage() === 'RU' ? 'поддержку' : 'support'}</a>.</span><br />
              <span style={{ color: 'red' }}>{getSystemLanguage() === 'RU' ? 'Дополнительная скидка 50% при оплате за год.' : 'Extra discount 50% for annual subscription.'}</span><br />
            </div>
          </ModalBody>
        </Modal>
        {tarif?.name ? <Button onClick={() => this.setState({ modalOpen: true })} style={{ textTransform: 'uppercase', color: 'white', height: 30, padding: '0.5rem 1rem' }} className={className("mr-1 mb-1 nav-bar-tarif-btn", {
          start: tarif?.name === 'Start',
          basic: tarif?.name === 'Basic',
          premium: tarif?.name === 'Premium'
        })}>{tarif?.name}</Button> : null}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            {notification ? <Badge pill color="primary" className="badge-up">
              {" "}
              1{" "}
            </Badge> : null}
          </DropdownToggle>
          {notification ? <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{getSystemLanguage() === 'RU' ? '1 Сообщение' : '1 New'}</h3>
                <span className="notification-title">{getSystemLanguage() === 'RU' ? 'Уведомления' : 'App Notifications'}</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.File className="font-medium-5 warning" size={21} />
                  </Media>
                  <Media body>
                    <Media heading className="warning media-heading" tag="h6">
                      {getSystemLanguage() === 'RU' ? 'Продлите Вашу подписку' : 'Renew Your Subscription'}
                    </Media>
                    <p className="notification-text">
                      {getSystemLanguage() === 'RU' ? 'Привет, Ваша подписка на Cloakit.pro закончится' : 'Hi, your Cloakit.pro subscription will expire on'} {new Date(this.props.user?.subscriptionEndAt).toLocaleDateString(getSystemLanguage())}!
                    {getSystemLanguage() === 'RU' ? 'Чтобы продлить подписку перейдите на страницу оплаты.' : 'To renew your subscription go to payments.'}
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      {getSystemLanguage() === 'RU' ? 'Последний месяц' : 'Last month'}
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar>
          </DropdownMenu> : null}
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user">
              <Avatar className="mr-1" content={this.props.userName[0].toUpperCase()} />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    tarifs: tarifsSelector(state),
    user: state.app.user?.user,
  }
}

export default connect(mapStateToProps, { setUser: setValueBinded(['user', 'user']) })(NavbarUser)
