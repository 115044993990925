import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { CARD, MONO, PRIVAT, RUB, SBERBANK, TINKOV, USD, VISA } from "./constants"

import { Copy } from "react-feather"
import { CopyToClipboard } from "react-copy-to-clipboard"
import React from "react"
import { getSystemLanguage } from "../languages"
import notify from "../../../utility/context/notify"

const TopLabel = (props) => {
    if (props.paySystem === CARD) {
        return props.cardSystem
    }
    return `${props.coin?.nameFull} (${props.paySystem})`
}

const getPaySystem = (pay, card) => {
    if (pay === CARD && [PRIVAT, MONO, VISA].includes(card)) {
        return 'UAH'
    }
    return pay
}

const cardToLink = {
    [PRIVAT]: 'https://mine.exchange/exchange_P24UAH_to_USDTTRC',
    [MONO]: 'https://mine.exchange/exchange_MONOBUAH_to_USDTTRC',
    [VISA]: 'https://mine.exchange/exchange_CARDUAH_to_USDTTRC',
}

const cardToBank = {
    [TINKOV]: 'https://www.tinkoff.ru/payments/provider-ymoney',
    [SBERBANK]: 'https://www.sberbank.ru/ru/promo/yoomoney',
}

const PaymentDescription = (props) => {
    if (props.paySystem === CARD && [PRIVAT, MONO, VISA].includes(props.cardSystem)) {
        const payAddress = props.coins.find((coin) => coin.nameShort === "USDT TRC 20")?.address
        return <div style={{ width: '100%', paddingLeft: 30, marginTop: 10, lineHeight: 2 }}>
            <span>1. Перейдите в <a href={cardToLink[props.cardSystem]} target="_blank" rel="noopener noreferrer">обменник</a>.</span><br />
            <span>2. Вводите вашу карту.</span><br />
            <span>3. Вводите сумму тарифа <span>{props.targetPrice}</span>$.</span><br />
            <span>4. Вводите кошелек TRC20: <CopyToClipboard text={payAddress}><span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')}>{payAddress}</span></CopyToClipboard></span><br />
            <span>5. Совершаете оплату.</span><br />
        </div>
    }
    if (props.paySystem === CARD && [TINKOV, SBERBANK].includes(props.cardSystem)) {
        return <div style={{ width: '100%', paddingLeft: 30, marginTop: 10, lineHeight: 2 }}>
            <span>1. Перейдите на <a href={cardToBank[props.cardSystem]} target="_blank" rel="noopener noreferrer">сайт</a> и совершите перевода на юмани.</span><br />
            <span>2. Введите кошелек юмани: <CopyToClipboard text='4100116578232157'><span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')}>4100116578232157</span></CopyToClipboard></span><br />
            <span>3. Вводите сумму тарифа <span>{props.targetPrice}</span> руб.</span><br />
            <span>4. Совершаете оплату.</span><br />
        </div>
    }
    return <React.Fragment>
        {props?.coin?.qrImg ? <p style={{ textAlign: 'center' }}><span style={{ textAlign: 'center' }}><img
            src={'data:image/png;base64,' + props?.coin?.qrImg}
            className="mx-auto"
            width="100px"
        /></span></p> : null}
        <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
            <span>{getSystemLanguage() === 'RU' ? 'Для завершения оплаты' : 'To complete your payment'},</span><br />
            <span>{getSystemLanguage() === 'RU' ? 'отправьте' : 'send'} {props.paySystem} {props.paySystem === 'USDT' ? '(ERC 20)' : ''} {getSystemLanguage() === 'RU' ? 'по адресу' : 'to the address below'}.</span><br />
        </div>
        <FormGroup style={{ marginBottom: 0, marginTop: 10 }}>
            <Label for="data-user-server-minified">{props.paySystem === 'USD' ? 'Capitalist' : props.paySystem} {getSystemLanguage() === 'RU' ? 'адрес' : 'address'}</Label>
            <Row style={{ width: '100%' }}>
                <Input
                    style={{ marginLeft: 14, height: 39, width: 'calc(100% - 51px)' }}
                    value={props.coin?.address}
                />
                <CopyToClipboard text={props.coin?.address}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
    </React.Fragment>
}

const PaymentPage = (props) => {
    return <Modal
        isOpen={props.open}
        toggle={props.closeHandler}
        className="modal-dialog-centered"
    >
        <ModalHeader toggle={props.closeHandler}>
            {getSystemLanguage() === 'RU' ? 'Оплатить' : 'Pay with'} <span style={{ display: 'block', float: 'right', position: 'absolute', right: 46, top: 12 }}><TopLabel {...props} /></span>
        </ModalHeader>
        <ModalBody className="modal-dialog-centered">
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', textAlign: 'center', color: '#20ac4b', fontSize: 20, fontWeight: 'bold' }}>
                    <span>{props.summaryPriceWithDiscount}$</span>
                </div>
                {props.paySystem === CARD && [PRIVAT, MONO, VISA].includes(props.cardSystem) ? null : <div style={{ width: '100%', textAlign: 'center', fontSize: 17, marginTop: 10 }}>
                    <span>{props.targetPrice} {getPaySystem(props.paySystem, props.cardSystem)}</span><CopyToClipboard text={props.targetPrice}>
                        <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: -2 }} size={20} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} />
                    </CopyToClipboard>
                </div>}
                <PaymentDescription {...props} />
                <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
                    {getSystemLanguage() === 'RU' && (props.paySystem === USD || props.paySystem === RUB) ? <><span style={{ backgroundColor: '#fff0dc' }}>В комментарии к платежу укажите свою почту.</span><br /></> : null}
                    <span style={{ color: '#20ac4b' }}>{getSystemLanguage() === 'RU' ? 'После оплаты нажмите «Подтвердить»' : 'Аfter you have make transaction click «Сonfirm»'}</span><br />
                    <span>{getSystemLanguage() === 'RU' ? 'Ваш Аккаунт будет активирован после подтверждения оплаты' : 'Your Account will be activate after payment confirmation'}.</span><br />
                    <span>{getSystemLanguage() === 'RU' ? 'Обычно это занимает 30 минут' : 'Usually it takes 30 minutes'}.</span>
                </div>
            </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between' }}>
            <Button onClick={() => {
                props.closeHandler()
                props.openSelectModal()
            }}>{getSystemLanguage() === 'RU' ? 'Назад' : 'Back'}</Button>
            <Button disabled={!props.paySystem} color="primary" onClick={props.sendHandler}>{getSystemLanguage() === 'RU' ? 'Подтвердить' : 'Confirm'}</Button>
        </ModalFooter>
    </Modal>
}

export default PaymentPage