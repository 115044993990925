import { ChevronDown, ChevronLeft, ChevronRight, Download, RotateCw, Trash } from "react-feather"
import { CustomHeaderStatistic, comparators, getSliceData, getSortIndex, getTotalPages, saveFile, typeDate, typeNumber, typeString } from "./utils"
import React, { useEffect, useState } from "react"

import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback"
import DataTable from "react-data-table-component"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import { Spinner } from "reactstrap"
import { connect } from "react-redux"
import fetchApi from "../../utility/context/fetchApi"
import notify from "../../utility/context/notify"
import { tokenSelector } from "../../redux/selectors"

const spanStyle = { maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', textTransform: 'capitalize' }

const conditionalRowStyles = [
    {
        when: row => !row.activated,
        style: {
            backgroundColor: '#ffe998',
            color: 'white',
        },
    }
];


const ActionsComponent = ({ row, activateUser, deleteRow }) => {
    return (
        <div className="data-list-action minus-6">
            <RotateCw
                className="cursor-pointer control-icon"
                size={20}
                title='Activate subsciption'
                onClick={() => {
                    activateUser(row)
                }}
            />
            <Trash
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    deleteRow(row)
                }}
            />
        </div>
    )
}

function Payments(props) {
    const [data, setData] = useState([])
    const [dataAll, setDataAll] = useState(null)
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [sortIndex, setSortIndex] = useState([1, 2])
    const [filter, setFilter] = useState('')
    const [sort, setSort] = useState({ selector: 'createdAt', type: 'asc', typeData: typeDate })
    const [dateRange, setDateRange] = useState(new Date())

    useEffect(() => {
        if (dataAll) {
            const allDataSorted = dataAll.slice().sort(comparators[sort.typeData](sort.selector))
            if (sort.type === 'asc') allDataSorted.reverse()
            if (filter) {
                const filteredData = allDataSorted
                    .filter((row) =>
                        (row.email && row.email.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.promocode && row.promocode.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.name && row.name.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.method && row.method.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.type && row.type.toLowerCase().includes(filter.toLowerCase()))
                    )
                setData(filteredData)
                setTotal(filteredData.length)
                setTotalPages(1)
                setPage(0)
                setSortIndex([1, filteredData.length])
            } else {
                setData(getSliceData(allDataSorted, page, limit))
                setTotal(allDataSorted.length)
                setTotalPages(getTotalPages(allDataSorted, limit))
                setSortIndex(getSortIndex(page, limit, allDataSorted.length))
            }
        }
    }, [page, dataAll, limit, filter, sort])

    const activateUser = (row) => {
        fetchApi({
            url: `/payment/${row._id}`,
            method: 'PATCH',
            token: props.token,
            body: { ...row, activated: true }
        }).then(({ payment }) => {
            setDataAll(dataAll.map((node) => {
                if (node._id === payment._id) return payment
                return node
            }))
            notify('User activated.')
        })
    }
    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        if (dataAll) {
            setDataAll(null)
        }
        fetchApi({
            url: `/payment`,
            method: 'GET',
            token: props.token,
        }).then((companies) => {
            setDataAll(companies)
        })
    }

    const removeData = ({ _id }) => {
        fetchApi({
            url: `/payment/${_id}`,
            method: 'DELETE',
            token: props.token,
        }).then(() => {
            setDataAll(dataAll.filter((node) =>
                node._id !== _id
            ))
        })
    }

    const download = () => {
        const filteredData = dataAll.filter(({ createdAt, activated }) => activated && new Date(createdAt) > new Date(dateRange[0]).setHours(0) && new Date(createdAt) < new Date(dateRange[1]).setHours(24))
        const replacer = (key, value) => {
            return value === null ? '' : value
        }
        const replacerTopLevel = (key, value) => {
            if (key === 'createdAt') {
                return new Intl.DateTimeFormat('en-US', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(value))
            }
            return value
        }
        const header = ['createdAt', 'email', 'total', 'promocode', 'name', 'type', 'method']
        let csv = filteredData.map(row => header.map(fieldName => JSON.stringify(replacerTopLevel(fieldName, row[fieldName]), replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')
        saveFile(csv, 'Payments.csv')
    }

    const handleLimit = (limit) => {
        setPage(0)
        setLimit(limit)
    }

    const handleSort = ({ selector, type: typeData }, type) => {
        setSort({ selector, type, typeData })
    }

    const columns = [
        {
            name: "Date",
            type: typeDate,
            selector: "createdAt",
            maxWidth: '90px',
            cell: row => {
                const date = (row.createdAt && new Date(row.createdAt)) || new Date()
                return <span>{date.toLocaleTimeString()}<br />{new Intl.DateTimeFormat('en-US', { day: 'numeric', year: 'numeric', month: 'short' }).format(date)}</span>
            }
        },
        {
            name: "Email",
            type: typeString,
            selector: "email",
            flexGrow: 1,
            cell: row => <span title={row.email}>{row.email}</span>
        },
        {
            name: "Total",
            type: typeNumber,
            selector: "total",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{row.total}$</span>
        },
        {
            name: "Promocode",
            type: typeString,
            selector: "promocode",
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.promocode}>{row.promocode}</span>
        },
        {
            name: "Name",
            type: typeString,
            selector: "name",
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.name}>{row.name}</span>
        },
        {
            name: "Type",
            type: typeString,
            selector: "type",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>1 {row.type}</span>
        },
        {
            name: "Kind",
            type: typeString,
            selector: "method",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{row.method}</span>
        },
        {
            name: "Actions",
            maxWidth: '20px',
            cell: row => (
                <ActionsComponent
                    row={row}
                    activateUser={activateUser}
                    deleteRow={(row) => refAlert.current.show(row)}
                />
            )
        }
    ]
    const refAlert = React.useRef()
    if (!dataAll) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 200 }}>
            <Spinner color="primary" style={{ margin: '0 auto', alignSelf: 'center' }} size='lg' />
        </div>
    }
    return <div className={`data-list simple`}>
        <BasicSweetCallback refAlert={refAlert}  onConfirm={(row) => {
                    removeData(row)
                }}/>
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            subHeader
            responsive
            conditionalRowStyles={conditionalRowStyles}
            pointerOnHover
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            subHeaderComponent={
                <CustomHeaderStatistic
                    refresh={refresh}
                    handleFilter={(value) => setFilter(value)}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={limit}
                    total={total}
                    index={sortIndex}
                >
                    <div style={{ marginRight: 10, marginTop: 5, width: 185 }}>
                        <Flatpickr
                            value={dateRange}
                            onChange={setDateRange}
                            className="form-control"
                            options={{ mode: "range" }}
                        />
                    </div>
                    <div title='Download CSV with payments'><Download onClick={download} style={{ margin: 10, cursor: 'pointer' }} /></div>
                </CustomHeaderStatistic>
            }
        />
    </div>
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state),
        user: state.app.user?.user,
    }
}

export default connect(mapStateToProps)(Payments)
