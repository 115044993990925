import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap"
import { Check, ChevronDown, ChevronLeft, ChevronRight, Download, FilePlus, PlusSquare, X } from "react-feather"
import { CustomHeaderStatistic, getSortIndex, saveFile } from "./utils"
import React, { useEffect, useState } from "react"
import { blackListSelector, statisticLimitSelector, tokenSelector } from "../../redux/selectors"
import { setBlackList, setStatisticLimit } from "../../redux/actions"

import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexy"
import DataTable from "react-data-table-component"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import Select from "react-select"
import { connect } from "react-redux"
import fetchApi from "../../utility/context/fetchApi"
import { getSystemLanguage } from "./languages"
import notify from "../../utility/context/notify"

const spanStyle = { maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', textTransform: 'capitalize' }

const spanStyleWithoutCapitalize = { maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis' }

const conditionalRowStyles = [
    {
        when: row => row.page === 'white',
        style: {
            backgroundColor: '#ffe998',
            color: 'white',
        },
    }
]

const CustomInput = ({ value, defaultValue, cleanDate, inputRef, ...props }) => {
    return <div class="flatpickr" style={{ position: 'relative' }}>
        <input {...props} ref={inputRef} />
        {value ? <X size={20} className='cursor-pointer' onClick={cleanDate} style={{ position: 'absolute', top: 14, right: 10 }} /> : null}
    </div>
}

function Statistics(props) {
    const [state, setState] = useState({ data: [], total: 1, totalPages: 1, sortIndex: [1, 2] })
    const [page, setPage] = useState(0)
    const [filter] = useState('')
    const [dateFilter, setDateFilter] = useState(0)
    const [modal, setModal] = useState(null)
    const [ip, setIp] = useState(true)
    const [isp, setIsp] = useState(false)
    const [userAgent, setUserAgent] = useState(false)
    const [rowCounter, setRowCounter] = useState()
    const [dateRange, setDateRange] = useState()
    const [innerModal, setInnerModal] = useState(null)
    const [batchedModal, setBatchedModal] = useState(null)
    const [selectedBlackList, setSelectedBlackList] = useState(null)
    const [selectedBlackListBatched, setSelectedBlackListBatched] = useState(null)
    const [blackListToAdd, setBlackListToAdd] = useState(null)

    const companyId = props?.match?.params?.companyId
    const companyName = props?.match?.params?.companyName

    useEffect(() => {
        if (selectedBlackList) {
            addToBlackList()
        }
    }, [selectedBlackList])

    useEffect(() => {
        if (dateFilter !== 'date' || dateRange) {
            refresh()
        }
    }, [dateFilter, companyId, page, props.limit, filter, dateRange])

    const refresh = () => {
        fetchApi({
            url: `/statistic/user`,
            method: 'POST',
            token: props.token,
            body: {
                companyId,
                date: dateFilter,
                timeOffset: new Date().getTimezoneOffset(),
                page,
                customDate: dateRange,
                limit: props.limit,
                filter
            }
        }).then(res => {
            setState({
                data: res.data,
                page: res.page,
                totalPages: Math.ceil((res.count) / props.limit),
                total: res.count,
                sortIndex: getSortIndex(res.page, props.limit, res.count)
            })
            setRowCounter(res.count)
            if (page !== res.page) {
                setPage(res.page)
            }
        })
    }

    const downloadCSV = () => {
        notify(getSystemLanguage() === 'RU' ? 'Происходит формирование файла, ожидайте.' : 'Download was started. Wait please.', 'warning')
        fetchApi({
            url: `/statistic/user/getAllStats`,
            method: 'POST',
            token: props.token,
            body: {
                companyId,
                date: dateFilter,
                timeOffset: new Date().getTimezoneOffset(),
                page,
                customDate: dateRange,
                limit: props.limit,
                filter
            }
        }).then(res => {
            const replacer = (key, value) => {
                return value === null ? '' : value
            }
            const replacerTopLevel = (key, value, row) => {
                if (key === 'createdAt') {
                    return new Intl.DateTimeFormat('en-US', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(value))
                }
                if (key === 'device') {
                    return value === 'smartphone' ? 'mobile' : value
                }
                return value
            }
            const header = ['createdAt', 'ip', 'country', 'isp', 'referer', 'device', 'agent', 'filter', 'page']
            let csv = res.map(row => header.map(fieldName => JSON.stringify(replacerTopLevel(fieldName, row[fieldName], row), replacer)).join(','))
            csv.unshift(['Date', 'IP', 'Country', 'ISP', 'Referer', 'Device', 'User Agent', 'Filter', 'Page'].join(','))
            csv = csv.join('\r\n')
            saveFile(csv, 'Statistic.csv')  
        })
    }

    const handleLimit = (limit) => {
        setPage(0)
        props.setLimit(limit)
    }

    const handleSort = () => { }

    const columns = [
        {
            name: getSystemLanguage() === 'RU' ? 'Дата' : 'Date',
            width: '120px',
            cell: row => {
                const date = (row.createdAt && new Date(row.createdAt)) || new Date()
                return <span>{date.toLocaleTimeString()}<br />{new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(date)}</span>
            }
        },
        {
            name: "Ip",
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.ip}>{row.ip}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Страна' : 'Country',
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.country}>{row.country}</span>
        },
        {
            name: "ISP",
            flexGrow: 1,
            cell: row => <span style={spanStyleWithoutCapitalize} title={row.isp}>{row.isp}</span>
        },
        {
            name: "Referer",
            flexGrow: 1,
            cell: row => <span style={spanStyleWithoutCapitalize} title={row.referer}>{row.referer}</span>
        },
        {
            name: "Device",
            flexGrow: 1,
            cell: row => {
                const device = row.device === 'smartphone' ? 'mobile' : row.device
                return <span style={spanStyle} title={device}>{device}</span>
            }
        },
        {
            name: "User Agent",
            flexGrow: 1,
            cell: row => <span style={spanStyleWithoutCapitalize} title={row.agent}>{row.agent}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Фильтр' : 'Filter',
            selector: 'filter',
            flexGrow: 1,
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Страница' : 'Page',
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.page}>{row.page}</span>
        },
    ]

    const addToBlackList = (type, field, value) => {
        if (!props.blackLists || !props.blackLists.length) {
            fetchApi({
                url: '/black-list',
                method: 'POST',
                token: props.token,
                body: { name: 'Default', [field]: value, userId: props.user._id }
            }).then((doc) => {
                props.setBlackList([doc])
                if (getSystemLanguage() === 'RU') {
                    notify(`${type} добавлен в ${doc?.name} Black List.`, 'success')
                } else {
                    notify(`${type} was added to ${doc?.name} Black List.`, 'success')
                }
            })
            return null
        }
        if (props.blackLists.length === 1) {
            const blackList = props.blackLists[0]
            fetchApi({
                url: `/black-list/${blackList._id}`,
                method: 'PATCH',
                token: props.token,
                body: { ...blackList, userId: props.user._id, [field]: `${value}\n${blackList[field]}` }
            }).then((res) => {
                props.setBlackList(props.blackLists.map((node) => {
                    if (node._id === res._id) return res
                    return node
                }))
                if (getSystemLanguage() === 'RU') {
                    notify(`${type} добавлен в ${blackList?.name} Black List.`, 'success')
                } else {
                    notify(`${type} was added to ${blackList?.name} Black List.`, 'success')
                }
            })
            return null
        }
        if (!selectedBlackList && !blackListToAdd) {
            setInnerModal(true)
            setBlackListToAdd({ type, field, value })
            return null
        }
        if (selectedBlackList) {
            setInnerModal(false)
            const blackList = props.blackLists.find(({ _id }) => _id === selectedBlackList.value)
            fetchApi({
                url: `/black-list/${selectedBlackList?.value}`,
                method: 'PATCH',
                token: props.token,
                body: { ...blackList, userId: props.user._id, [blackListToAdd.field]: `${blackListToAdd.value}\n${blackList[blackListToAdd.field]}` }
            }).then((res) => {
                props.setBlackList(props.blackLists.map((node) => {
                    if (node._id === res._id) return res
                    return node
                }))
                if (getSystemLanguage() === 'RU') {
                    notify(`${blackListToAdd.type} добавлен в ${blackList?.name} Black List.`, 'success')
                } else {
                    notify(`${blackListToAdd.type} was added to ${blackList?.name} Black List.`, 'success')
                }
                setBlackListToAdd(null)
                setSelectedBlackList(null)
            })
            return null
        }
    }

    const today = new Date()
    return <div className='data-list simple' style={{ width: '100%' }}>
        <Modal
            isOpen={Boolean(batchedModal)}
            toggle={() => setBatchedModal(null)}
            className="modal-dialog-centered"
        >
            <ModalHeader toggle={() => setBatchedModal(null)}>
                {getSystemLanguage() === 'RU' ? 'Массовый импорт в Black List' : 'Bulk Import to Black List'}
            </ModalHeader>
            <ModalBody className="modal-dialog-centered">
                <div style={{ width: '100%' }}>
                    <Row>
                        <div style={{ zIndex: 1000 }} className='form-group form-password-toggle col-md-12'>
                            <Label for="data-black-list" style={{ fontSize: 14 }}>{getSystemLanguage() === 'RU' ? 'Выбрать Black List' : 'Select Black List'}</Label>
                            <Select
                                value={selectedBlackListBatched}
                                size="sm"
                                onChange={(value) => {
                                    setSelectedBlackListBatched(value)
                                }}
                                options={props.blackLists.map((data) => ({ value: data._id, label: data.name }))}
                                className="React"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className='form-group form-password-toggle col-md-12'>
                            <Checkbox
                                color="primary"
                                icon={<Check className="vx-icon" size={10} />}
                                checked={ip}
                                onChange={() => { }}
                                onClick={() => setIp(!ip)}
                                label="IP"
                                size="sm"
                            />
                        </div>
                        <div className='form-group form-password-toggle col-md-12'>
                            <Checkbox
                                color="primary"
                                icon={<Check className="vx-icon" size={10} />}
                                checked={isp}
                                onChange={() => { }}
                                onClick={() => setIsp(!isp)}
                                label="ISP"
                                size="sm"
                            />
                        </div>
                        <div className='form-group form-password-toggle col-md-12'>
                            <Checkbox
                                color="primary"
                                icon={<Check className="vx-icon" size={10} />}
                                checked={userAgent}
                                onChange={() => { }}
                                onClick={() => setUserAgent(!userAgent)}
                                label="User Agent"
                                size="sm"
                            />
                        </div>
                        <div className='form-password-toggle col-md-12'>
                            <Label style={{ fontSize: 14 }} for="data-white">{getSystemLanguage() === 'RU' ? 'Количество строк' : 'Number of Rows'}</Label>
                            <Input
                                type="number"
                                value={rowCounter}
                                onChange={e => setRowCounter(Math.min(e.target.value, state.total))}
                            />
                        </div>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button title={!selectedBlackListBatched ? getSystemLanguage() === 'RU' ? 'Выберите Black List для импорта строк.' : 'Select target Black List to import rows.' : undefined} disabled={!selectedBlackListBatched} color="primary" onClick={() => {
                    fetchApi({
                        url: `/statistic/user`,
                        method: 'POST',
                        token: props.token,
                        body: {
                            companyId,
                            date: dateFilter,
                            timeOffset: new Date().getTimezoneOffset(),
                            page: 0,
                            customDate: dateRange,
                            limit: rowCounter,
                            filter
                        }
                    }).then(res => {
                        const blackList = props.blackLists.find(({ _id }) => _id === selectedBlackListBatched.value)
                        fetchApi({
                            url: `/black-list/${selectedBlackListBatched?.value}`,
                            method: 'PATCH',
                            token: props.token,
                            body: {
                                ...blackList, userId: props.user._id,
                                blackIp: (ip ? res.data.reduce((total, { ip }) => `${total}\n${ip}`, blackList.blackIp) : blackList.blackIp),
                                isp: (isp ? res.data.reduce((total, { isp }) => `${total}\n${isp}`, blackList.isp) : blackList.isp),
                                userAgent: (userAgent ? res.data.reduce((total, { agent }) => `${total}\n${agent}`, blackList.userAgent) : blackList.userAgent)
                            }
                        }).then((res) => {
                            props.setBlackList(props.blackLists.map((node) => {
                                if (node._id === res._id) return res
                                return node
                            }))
                            notify(getSystemLanguage() === 'RU' ? `${blackList?.name} Black List обновлен.` : `${blackList?.name} Black List was updated.`, 'success')
                            setBatchedModal(null)
                        })
                    })
                }}>{getSystemLanguage() === 'RU' ? 'Добавить в Black List' : 'Add to Black List'}</Button>
            </ModalFooter>
        </Modal>
        <Modal
            isOpen={Boolean(modal)}
            toggle={() => setModal(null)}
            className="modal-dialog-centered"
        >
            <ModalHeader toggle={() => setModal(null)}>
                {getSystemLanguage() === 'RU' ? 'Подробная информация' : 'Details Info'}
            </ModalHeader>
            <ModalBody className="modal-dialog-centered">
                <Modal
                    isOpen={Boolean(innerModal)}
                    toggle={() => setInnerModal(null)}
                    className="modal-dialog-centered"
                >
                    <ModalHeader toggle={() => setInnerModal(null)}>
                        {getSystemLanguage() === 'RU' ? 'Выбрать Black List' : 'Select Black List'}
                    </ModalHeader>
                    <ModalBody className="modal-dialog-centered">
                        <div style={{ width: '100%' }}>
                            <FormGroup>
                                <Label for="data-status">Black List</Label>
                                <Select
                                    value={selectedBlackList}
                                    size="sm"
                                    onChange={(value) => {
                                        setSelectedBlackList(value)
                                    }}
                                    options={props.blackLists.map((data) => ({ value: data._id, label: data.name }))}
                                    className="React"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                        </div>
                    </ModalBody>
                </Modal>
                <Table size="sm">
                    {modal ? <tbody>
                        <tr>
                            <th scope="row">{getSystemLanguage() === 'RU' ? 'Дата' : 'Date'}</th>
                            <td><span>{new Date(modal.createdAt).toLocaleTimeString()}  {new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(modal.createdAt))}</span></td>
                        </tr>
                        <tr>
                            <th scope="row">Ip</th>
                            <td style={{ lineHeight: '24px' }}>{modal.ip ? <PlusSquare
                                className="cursor-pointer control-icon small"
                                size={20}
                                onClick={() => {
                                    addToBlackList('IP', 'blackIp', modal.ip)
                                }}
                            /> : null}{modal.ip}</td>
                        </tr>
                        <tr>
                            <th scope="row">{getSystemLanguage() === 'RU' ? 'Страна' : 'Country'}</th>
                            <td>{modal.country}</td>
                        </tr>
                        <tr>
                            <th scope="row">ISP</th>
                            <td style={{ lineHeight: '24px' }}>{modal.isp ? <PlusSquare
                                className="cursor-pointer control-icon small"
                                size={20}
                                onClick={() => {
                                    addToBlackList('ISP', 'isp', modal.isp)
                                }}
                            /> : null}{modal.isp}</td>
                        </tr>
                        <tr>
                            <th scope="row">Referer</th>
                            <td style={{overflowWrap: 'anywhere'}}>{modal.referer}</td>
                        </tr>
                        <tr>
                            <th scope="row">Device</th>
                            <td style={{textTransform: 'capitalize'}}>{modal.device === 'smartphone' ? 'mobile' : modal.device}</td>
                        </tr>
                        <tr>
                            <th scope="row">User Agent</th>
                            <td style={{ lineHeight: '24px', overflowWrap: 'anywhere' }}>{modal.agent ? <PlusSquare
                                className="cursor-pointer control-icon small"
                                size={20}
                                style={{float: 'left'}}
                                onClick={() => {
                                    addToBlackList('User Agent', 'userAgent', modal.agent)
                                }}
                            /> : null}{modal.agent}</td>
                        </tr>
                        <tr>
                            <th scope="row">{getSystemLanguage() === 'RU' ? 'Фильтр' : 'Filter'}</th>
                            <td>{modal.filter}</td>
                        </tr>
                        <tr>
                            <th scope="row">{getSystemLanguage() === 'RU' ? 'Страница' : 'Page'}</th>
                            <td style={{textTransform: 'capitalize'}}>{modal.page}</td>
                        </tr>
                    </tbody> : null}
                </Table>
            </ModalBody>
        </Modal>
        <DataTable
            columns={columns}
            data={state.data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={state.totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={pageLocal => {
                        if (pageLocal.selected !== page) {
                            setPage(pageLocal.selected)
                        }
                    }}
                />
            )}
            noHeader
            subHeader
            responsive
            conditionalRowStyles={conditionalRowStyles}
            pointerOnHover
            noDataComponent={<div style={{ padding: 24 }}>{getSystemLanguage() === 'RU' ? 'Статистика отсутствует' : 'There are no data'}</div>}
            sortIcon={<ChevronDown />}
            sortServer
            onRowClicked={(row) => setModal(row)}
            onSort={handleSort}
            subHeaderComponent={
                <CustomHeaderStatistic
                    refresh={refresh}
                    company={companyName}
                    dateFilter={dateFilter}
                    handleDateFilter={(value) => {
                        setDateFilter(value)
                    }}
                    // handleFilter={(value) => {
                    //     setPage(0)
                    //     setFilter(value)
                    // }}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={props.limit}
                    index={state.sortIndex}
                    total={state.total}
                >
                    <div title='Download Statistic'><Download onClick={() => {
                        downloadCSV()
                    }} style={{ margin: 11, cursor: 'pointer' }} /></div>
                    {state?.total ? <div title='Create Black List From Statistic'><FilePlus onClick={() => {
                        if (!props.blackLists || !props.blackLists.length) {
                            return fetchApi({
                                url: '/black-list',
                                method: 'POST',
                                token: props.token,
                                body: { name: 'Default', userId: props.user._id }
                            }).then((doc) => {
                                props.setBlackList([doc])
                                return setBatchedModal(true)
                            })
                        }
                        setBatchedModal(true)
                    }} style={{ margin: 11, cursor: 'pointer' }} /></div> : null}
                    {dateFilter === 'date' ? <div className='custom-date-wrapper-dima'>
                        <Flatpickr
                            value={dateRange}
                            onChange={setDateRange}
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать день' : "Custom date filter"}
                            className="form-control sort-dropdown-custom-date"
                            options={{ minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30), maxDate: today }}
                            render={
                                ({ defaultValue, value, ...props }, ref) => {
                                    return <CustomInput {...props} inputRef={ref} value={value} cleanDate={() => setDateRange(undefined)} />
                                }
                            }
                        />
                    </div> : null}
                </CustomHeaderStatistic>
            }
        />
    </div >
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state),
        limit: statisticLimitSelector(state),
        blackLists: blackListSelector(state),
        user: state.app.user?.user,
    }
}

export default connect(mapStateToProps, { setLimit: setStatisticLimit, setBlackList })(Statistics)
