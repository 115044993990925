import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup, Button } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import { initialTarif } from "./utils"

const TarifsDataListSidebar = ({ show, data, updateData, addData, cancelSidebar }) => {
  const [localData, setLocalData] = useState(data || initialTarif)

  useEffect(() => {
    setLocalData(data || initialTarif)
  }, [data])

  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialTarif)
  }

  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? "UPDATE DATA" : "ADD NEW DATA"}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-name">Name</Label>
          <Input
            type="text"
            value={localData.name}
            placeholder=""
            onChange={e => setLocalData({ ...localData, name: e.target.value })}
            id="data-name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-limit">Limit</Label>
          <Input
            type="number"
            value={localData.limit}
            placeholder=""
            onChange={e => setLocalData({ ...localData, limit: e.target.value })}
            id="data-limit"
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-price">Price</Label>
          <Input
            type="number"
            value={localData.price}
            placeholder=""
            onChange={e => setLocalData({ ...localData, price: e.target.value })}
            id="data-price"
          />
        </FormGroup>
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit(localData)}>
          {data !== null ? "Update" : "Submit"}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          Cancel
          </Button>
      </div>
    </div>
  )
}
export default TarifsDataListSidebar
