const app = (state) => state.app

const emptyArray = []

export const registrationSelector = (state) => app(state).registration

export const statisticLimitSelector = (state) => app(state).statisticLimit

export const companyLimitSelector = (state) => app(state).companyLimit

export const loginSelector = (state) => app(state).login

export const verificationSelector = (state) => app(state).verification

export const recoverySelector = (state) => app(state).recovery

export const filterCompaniesSelector = (state) => app(state).filterCompanies

export const companySelector = (state) => app(state).profile

export const userSelector = (state) => app(state).user

export const affiliateModeSelector = (state) => app(state).affiliateMode

export const tokenSelector = (state) => app(state).user?.tokens?.access?.token

export const blackIpsSelector = (state) => app(state)?.blackIps || emptyArray

export const tarifsSelector = (state) => app(state)?.tarifs || emptyArray

export const promosSelector = (state) => app(state)?.promos || emptyArray

export const usersSelector = (state) => app(state)?.users || null

export const companiesSelector = (state) => app(state)?.companies || emptyArray

export const statisticSelector = (state) => app(state)?.statistic || emptyArray

export const ispSelector = (state) => app(state)?.isp || emptyArray

export const blackListSelector = (state) => app(state)?.blackList || emptyArray

export const userAgentSelector = (state) => app(state)?.userAgent || emptyArray

export const promocodeSelector = (state) => app(state)?.promocode || emptyArray

export const paymentSelector = (state) => app(state)?.payments || emptyArray

export const coinSelector = (state) => app(state)?.coins || emptyArray

export const filterDateCompaniesSelector = (state) => app(state)?.filterDateCompanies

export const languageSelector = (state) => userSelector(state).language
