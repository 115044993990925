import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap"
import { ChevronDown, DollarSign, Download, Plus, RefreshCw } from "react-feather"

import React from 'react'
import debounce from 'debounce'
import fileSaver from 'file-saver'
import { getSystemLanguage } from './languages'

export const initialTarif = { name: '', price: '', limit: '' }
export const initialPromo = { text: '', active: false }
export const initialCoin = { nameFull: '', nameShort: '', address: '', index: 1, sortOrder: 1 }
export const initialBlackIp = { value: '' }
export const initialPromocode = { name: '', discount: 0 }
export const initialBlackList = { name: '', blackIp: '', isp: '', userAgent: '', ispEmptyFilter: false, ipWithoutGeoFilter: false }
export const initialTestOffer = { offer: '', loadTypeOffer: 'load', probability: 0 }
export const initialCompany = {
    name: '', white: '', loadTypeWhite: 'load', offer: '', loadTypeOffer: 'load', abtest: false, probabilityEqual: true,
    testOffers: [{...initialTestOffer, probability: 50}, {...initialTestOffer, probability: 50}],
    countries: [], devices: [], referers: '', utmParameters: '',
    countriesDisallow: false, devicesDisallow: false, referersDisallow: false, utmParametersDisallow: false,
    proxy: false, ipv6: false, premium: true, blackLists: [], maximumClicksByIp: false, maximumClicksByIpAfter: 1,
    emptyRefererFilter: false, status: 'active', dedicatedClicks: 30
}

export const getSliceData = (data, page, limit) => data.slice(page * limit, page * limit + limit)
export const getTotalPages = (data, limit) => Math.ceil((data.length) / limit)
export const getSortIndex = (page, limit, totalDocs) => [page * limit + 1, Math.min(page * limit + limit, totalDocs)]

export function saveFile(content, fileName) {
    return fileSaver.saveAs(
        new Blob([String(content)], {
            type: "text/php;charset=utf-8",
            endings: "native"
        }),
        fileName
    );
}

export const CustomHeader = ({ toggleAffiliateMode, activeUsers, verifiedUsers, filter, filterValue, setFilterHandler, download, downloadActiveUsers, handleSidebar, index, total, handleRowsPerPage, handleFilter, addNew = true, children }) => {
    const setFilter = filterValue !== undefined ? handleFilter : debounce(handleFilter, 300)
    return (
        <div className="data-list-header d-flex justify-content-between flex-wrap">
            <div className="actions-left d-flex flex-wrap">
                {
                    verifiedUsers !== undefined ? <span style={{
                        fontSize: 19,
                        padding: 9,
                        paddingLeft: 0,
                        color: '#29c66f'
                    }}>Users: {verifiedUsers}</span> : null
                }
                {
                    activeUsers !== undefined ? <span style={{
                        fontSize: 19,
                        padding: 9,
                        marginLeft: 56,
                        color: '#29c66f'
                    }}>Active: {activeUsers}</span> : null
                }
                {addNew && <Button
                    className="add-new-btn"
                    color="primary"
                    onClick={() => handleSidebar(true)}
                    outline>
                    <Plus size={15} />
                    <span className="align-middle"> {getSystemLanguage() === 'RU' ? 'Создать' : 'Create New'}</span>
                </Button>}
            </div>
            <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
                {toggleAffiliateMode ? <div title='Показывать только партнёров'><DollarSign onClick={toggleAffiliateMode} style={{ margin: 10, cursor: 'pointer' }} /></div> : null}
                {downloadActiveUsers ? <div title='Скачать CSV с активными пользователями'><Download onClick={downloadActiveUsers} style={{ margin: 10, cursor: 'pointer' }} /></div> : null}
                {download ? <div title='Скачать CSV с бывшими пользователями'><Download onClick={download} style={{ margin: 10, cursor: 'pointer' }} /></div> : null}
                {children}
                {filter ? <UncontrolledDropdown className="data-list-rows-dropdown mr-1 d-md-block">
                    <DropdownToggle color="" className="sort-dropdown">
                        <span className="align-middle mx-50">
                            {`${filter === 1 ? getSystemLanguage() === 'RU' ? 'Сегодня' : 'Today' : filter === 2 ? getSystemLanguage() === 'RU' ? 'Вчера' : 'Yesterday' : filter === 4 ? getSystemLanguage() === 'RU' ? 'Дата' : 'Date' : getSystemLanguage() === 'RU' ? 'Все время' : 'All Time'}`}
                        </span>
                        <ChevronDown size={15} />
                    </DropdownToggle>
                    <DropdownMenu tag="div" right>
                        <DropdownItem tag="a" onClick={() => setFilterHandler(1)}>
                            {getSystemLanguage() === 'RU' ? 'Сегодня' : 'Today'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => setFilterHandler(2)}>
                            {getSystemLanguage() === 'RU' ? 'Вчера' : 'Yesterday'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => setFilterHandler(3)}>
                            {getSystemLanguage() === 'RU' ? 'Все время' : 'All Time'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => setFilterHandler(4)}>
                            {getSystemLanguage() === 'RU' ? 'Дата' : 'Date'}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> : null}
                <UncontrolledDropdown className="data-list-rows-dropdown mr-1 d-md-block">
                    <DropdownToggle color="" className="sort-dropdown">
                        <span className="align-middle mx-50">
                            {`${total === 0 ? 0 : index[0]} - ${index[1]} из ${total}`}
                        </span>
                        <ChevronDown size={15} />
                    </DropdownToggle>
                    <DropdownMenu tag="div" right>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(4)}>
                            4
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(7)}>
                            7
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(10)}>
                            10
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(15)}>
                            15
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(20)}>
                            20
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(50)}>
                            50
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(100)}>
                            100
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(200)}>
                            300
              </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <div className="filter-section">
                    <Input type="text" placeholder={getSystemLanguage() === 'RU' ? 'Текстовый поиск' : 'Textual search'} value={filterValue || undefined} onChange={e => setFilter(e.target.value)} />
                </div>
            </div>
        </div>
    )
}

export const CustomHeaderStatistic = ({ refresh, company, index, total, handleRowsPerPage, handleFilter, dateFilter, handleDateFilter, children }) => {
    const setFilter = handleFilter ? debounce(handleFilter, 300) : null
    return (
        <div className="data-list-header d-flex justify-content-between flex-wrap">
            <div className="actions-left d-flex flex-wrap">
                <span className="text-bold-600" style={{ alignSelf: 'center' }}>{company}</span>
            </div>
            <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
                {children}
                <RefreshCw className="d-none" title='Update data' onClick={() => refresh && refresh()} style={{ margin: 10, cursor: 'pointer' }} />
                {handleDateFilter ? <UncontrolledDropdown className="data-list-rows-dropdown mr-1 d-md-block">
                    <DropdownToggle color="" className="sort-dropdown">
                        <span className="align-middle mx-50">
                            {dateFilter === 0 ? getSystemLanguage() === 'RU' ? 'Сегодня' : 'Today' : dateFilter === 'date' ? getSystemLanguage() === 'RU' ? 'Дата' : 'Date' :  `${dateFilter} ${getSystemLanguage() === 'RU' ? 'Дней' : 'Days'}`}
                        </span>
                        <ChevronDown size={15} />
                    </DropdownToggle>
                    <DropdownMenu tag="div" right>
                        <DropdownItem tag="a" onClick={() => handleDateFilter(0)}>
                            {getSystemLanguage() === 'RU' ? 'Сегодня' : 'Today'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleDateFilter(7)}>
                            7 {getSystemLanguage() === 'RU' ? 'Дней' : 'Days'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleDateFilter(30)}>
                            30 {getSystemLanguage() === 'RU' ? 'Дней' : 'Days'}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleDateFilter('date')}>
                            {getSystemLanguage() === 'RU' ? 'Дата' : 'Date'}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> : null}
                <UncontrolledDropdown className={setFilter ? "data-list-rows-dropdown mr-1 d-md-block" : "data-list-rows-dropdown d-md-block" }>
                    <DropdownToggle color="" className="sort-dropdown">
                        <span className="align-middle mx-50">
                            {`${index[0]} - ${index[1]} из ${total}`}
                        </span>
                        <ChevronDown size={15} />
                    </DropdownToggle>
                    <DropdownMenu tag="div" right>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(10)}>
                            10
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(15)}>
                            15
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(20)}>
                            20
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(50)}>
                            50
              </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handleRowsPerPage(100)}>
                            100
              </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {setFilter ? <div className="filter-section">
                    <Input type="text" placeholder={getSystemLanguage() === 'RU' ? 'Текстовый поиск' : 'Textual search'} onChange={e => setFilter(e.target.value)} />
                </div> : null}
            </div>
        </div>
    )
}

export const typeNumber = 'number'
export const typeString = 'string'
export const typeDate = 'date'

export const comporatorNumber = (selector) => (a, b) => a[selector] - b[selector]

export const comporatorDate = (selector) => (a, b) => new Date(a[selector]) - new Date(b[selector])

export const comporatorString = (selector) => (a, b) => {
    if (a[selector] > b[selector]) {
        return 1;
    }
    if (a[selector] < b[selector]) {
        return -1;
    }
    return 0;
}

export const comparators = {
    [typeNumber]: comporatorNumber,
    [typeString]: comporatorString,
    [typeDate]: comporatorDate,
}
