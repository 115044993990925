import React, { useEffect, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import { ChevronLeft, ChevronRight, Edit, Trash } from "react-feather"
import ReactPaginate from "react-paginate"

import "../../assets/scss/plugins/extensions/react-paginate.scss"
import "../../assets/scss/pages/data-list.scss"
import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import {  tokenSelector } from "../../redux/selectors"
import { CustomHeader, getSliceData, getSortIndex, getTotalPages, typeString } from "./utils"
import { setBlackIps } from "../../redux/actions"
import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback"
import { Spinner } from "reactstrap"
import { history } from "../../history"

const ActionsComponent = ({ row, editRow, deleteRow }) => {
    return (
        <div className="data-list-action minus-6">
            <Edit
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    return editRow(row)
                }}
            />
            <Trash
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    deleteRow(row)
                }}
            />
        </div>
    )
}

function PostList(props) {
    const [data, setData] = useState([])
    const [dataAll, setDataAll] = useState(null)
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(4)
    const [totalPages, setTotalPages] = useState(1)
    const [sortIndex, setSortIndex] = useState([1, 2])
    const [filter, setFilter] = useState('')
    const refAlert = useRef()

    useEffect(() => {
        if (dataAll) {
            if (filter) {
                const filteredData = dataAll.filter(({ blogTitle }) => blogTitle.includes(filter))
                setData(filteredData)
                setTotal(filteredData.length)
                setTotalPages(1)
                setPage(0)
                setSortIndex([1, filteredData.length])
            } else {
                setData(getSliceData(dataAll, page, limit))
                setTotal(dataAll.length)
                setTotalPages(getTotalPages(dataAll, limit))
                setSortIndex(getSortIndex(page, limit, dataAll.length))
            }
        }
    }, [page, dataAll, limit, filter])

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        if (dataAll) {
            setDataAll(null)
        }
        fetchApi({
            url: `/post`,
            method: 'GET',
            token: props.token,
        }).then((posts) => {
            setDataAll(posts)
        })
    }

    const handleLimit = (limit) => {
        setPage(0)
        setLimit(limit)
    }

    const removeData = ({ _id }) => {
        fetchApi({
            url: `/post/${_id}`,
            method: 'DELETE',
            token: props.token,
        }).then(() => {
            setDataAll(dataAll.filter((node) =>
                node._id !== _id
            ))
        })
    }

    const columns = [
        {
            name: "Title",
            selector: "blogTitle",
            sortable: true,
            type: typeString,
            grow: 1
        },
        {
            name: "Actions",
            maxWidth: '100px',
            cell: row => (
                <ActionsComponent
                    row={row}
                    editRow={(row) => {
                        history.push(`/admin/edit-posts/${row._id}`)
                    }}
                    deleteRow={(row) => refAlert.current.show(row)}
                />
            )
        }
    ]
    if (!dataAll) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 200 }}>
            <Spinner color="primary" style={{ margin: '0 auto', alignSelf: 'center' }} size='lg' />
        </div>
    }
    return <div className={`data-list list-view`}>
        <BasicSweetCallback refAlert={refAlert} onConfirm={(row) => {
            removeData(row)
        }} />
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            subHeader
            responsive
            pointerOnHover
            subHeaderComponent={
                <CustomHeader
                    handleSidebar={() => history.push('/admin/edit-posts/new')}
                    handleFilter={(value) => setFilter(value)}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={limit}
                    total={total}
                    index={sortIndex}
                />
            }
        />
    </div>
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setBlackIps })(PostList)
