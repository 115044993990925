import { BTC, CARD, ETH, LTC, USD, USDT, USDT20 } from './constants'
import { BtcIcon, CardIcon, EthereumIcon, LtcIcon, UsdIcon, UsdtIcon } from './Icons'
import { Button, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

import React from 'react'
import { getSystemLanguage } from '../languages'

const PaymentMethod = (props) => <ListGroupItem style={{cursor: 'pointer'}} active={props.active === props.kind} onClick={() => props.setPaySystem(props.kind)}>
    <p className="float-left mb-0 mr-1">
        {props.children}
    </p>
    <span>{props.label}</span>
</ListGroupItem>

const SelectPaymentMethod = (props) => {
    return <Modal
        isOpen={props.open}
        toggle={props.closeHandler}
        className="modal-dialog-centered">
        <ModalHeader toggle={props.closeHandler}>
            <span style={{ textTransform: 'uppercase' }}>
                {props.plan?.name}</span> - 1 {props.active}<span style={{ display: 'block', float: 'right', position: 'absolute', right: 46, top: 12 }}>${props.summaryPrice}
            </span>
        </ModalHeader>
        <ModalBody className="modal-dialog-centered">
            <div style={{ width: '100%' }}>
                <Row style={{ width: '100%' }}>
                    <Col lg="8" md="8" sm="8">
                        <FormGroup>
                            <Label for="basicInput">{getSystemLanguage() === 'RU' ? 'Промокод' : 'Promo Code'}</Label>
                            <Input type="text" value={props.promo} onChange={props.promoHandler} placeholder={getSystemLanguage() === 'RU' ? 'Введите Промокод' : 'Enter Promo Code'} />
                        </FormGroup>
                    </Col>
                    {props.discount ? <Col lg="4" md="4" sm="4" style={{ paddingTop: 28, textAlign: 'end' }}>
                        <span style={{ fontWeight: 'bold' }}>{getSystemLanguage() === 'RU' ? 'Скидка' : 'Discount'} - {props.discount}%</span>
                        <br />
                        <span style={{ fontWeight: 'bold', color: 'red', display: 'block', paddingTop: 3 }}>-{props.summaryDiscount}$</span>
                    </Col> : null}
                </Row>
                {props.discount ? <Row><Col style={{ textAlign: 'end', paddingRight: 40 }}><span style={{ fontSize: 20, fontWeight: 'bold' }}>
                    {getSystemLanguage() === 'RU' ? 'Всего' : 'Total'} <span style={{ color: '#20ac4b' }}>{props.summaryPriceWithDiscount}$</span></span></Col></Row>
                    : null}
                <Row><Col style={{ textAlign: 'center', paddingTop: 15 }}><span style={{ fontWeight: 'bold', fontSize: 22 }}>{getSystemLanguage() === 'RU' ? 'Выберите Метод Оплаты' : 'Select Payment Method'}</span></Col></Row>
                <Row><Col style={{ marginTop: 13 }}>
                    <ListGroup>
                        <PaymentMethod label="Bitcoin (BTC)" active={props.paySystem} kind={BTC} setPaySystem={props.setPaySystem}><BtcIcon /></PaymentMethod>
                        <PaymentMethod label="Ethereum (ETH)" active={props.paySystem} kind={ETH} setPaySystem={props.setPaySystem}><EthereumIcon /></PaymentMethod>
                        <PaymentMethod label="Tether (USDT)" active={props.paySystem} kind={USDT} setPaySystem={props.setPaySystem}><UsdtIcon /></PaymentMethod>
                        <PaymentMethod label="Tether (USDT, TRC 20) low fee" active={props.paySystem} kind={USDT20} setPaySystem={props.setPaySystem}><UsdtIcon /></PaymentMethod>
                        <PaymentMethod label="Litecoin (LTC)" active={props.paySystem} kind={LTC} setPaySystem={props.setPaySystem}><LtcIcon /></PaymentMethod>
                        <PaymentMethod label="Capitalist (USD)" active={props.paySystem} kind={USD} setPaySystem={props.setPaySystem}><UsdIcon /></PaymentMethod>
                        <PaymentMethod label="CARD" active={props.paySystem} kind={CARD} setPaySystem={props.setPaySystem}><CardIcon /></PaymentMethod>
                    </ListGroup>
                </Col></Row>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button disabled={!props.paySystem} color="primary" onClick={() => {
                props.closeHandler()
                props.openPaymentModal()
            }}>{getSystemLanguage() === 'RU' ? 'Далее' : 'Next'}</Button>
        </ModalFooter>
    </Modal>
}

export default SelectPaymentMethod