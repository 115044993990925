import { Button, Card, CardBody, Col, Row } from "reactstrap"

import React from "react"
import fetchApi from "../../../utility/context/fetchApi"
import { getSystemLanguage } from "../languages"
import notAuthImg from "../../../assets/img/pages/not-authorized.png"

class Verified extends React.Component {
  componentDidMount = () => {
    fetchApi({
      url: '/auth/verify', method: 'POST', body: {
        verifiedId: this.props.match.params.verifiedId
      }
    })
  }
  render() {
    return (
      <Row className="m-0">
        <Col sm="12">
          <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <CardBody className="text-center">
              <img
                src={notAuthImg}
                alt="notAuthImg"
                className="img-fluid align-self-center mt-75"
              />
              <h1 className="font-large-2 my-2">{getSystemLanguage() === 'RU' ? 'Верификация прошла успешно' : 'You are verified'}!</h1>
              <Button.Ripple
                tag="a"
                href="/login"
                color="primary"
                size="lg"
                className="mt-2"
              >
                {getSystemLanguage() === 'RU' ? 'Войти' : 'Login'}
              </Button.Ripple>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}
export default Verified
