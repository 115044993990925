import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup, Button, CustomInput } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import { initialPromo } from "./utils"
import {AllHtmlEntities} from 'html-entities'
import Select from "react-select"

const entities = new AllHtmlEntities();

const targetOptions = [
  { value: "All Users", label: "All Users" },
  { value: "Active Users", label: "Active Users" },
]

const PromosDataListSidebar = ({ show, data, updateData, addData, cancelSidebar }) => {
  const [localData, setLocalData] = useState(data || initialPromo)

  useEffect(() => {
    setLocalData(data || initialPromo)
  }, [data])

  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialPromo)
  }

  const targetValue = targetOptions.filter(({ value }) => localData?.target === value)

  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? "UPDATE DATA" : "ADD NEW DATA"}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-user-agent">Text RU</Label>
          <Input
            type="textarea"
            rows="6"
            value={entities.decode(localData.text)}
            onChange={(e) => {
                setLocalData({ ...localData, text: entities.encode(e.target.value) })
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="data-user-agent">Text EN</Label>
          <Input
            type="textarea"
            rows="6"
            value={entities.decode(localData.textEn)}
            onChange={(e) => {
                setLocalData({ ...localData, textEn: entities.encode(e.target.value) })
            }}
          />
        </FormGroup>
        <FormGroup>
          <CustomInput
            size="sm"
            type="switch"
            id='premium-data'
            checked={localData.active}
            onChange={e => setLocalData({ ...localData, active: e.target.checked })}
            inline
          >
            <span className="switch-label">Active</span>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="data-status">Target</Label>
          <Select
            value={targetValue}
            size="sm"
            onChange={(value) => setLocalData({ ...localData, target: value.value })}
            options={targetOptions}
            className="React"
            classNamePrefix="select"
          />
        </FormGroup>
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit(localData)}>
          {data !== null ? "Update" : "Submit"}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          Cancel
          </Button>
      </div>
    </div>
  )
}
export default PromosDataListSidebar
