import React, { useEffect, useState } from "react"
import Wizard from './wizard'
import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import { tarifsSelector, tokenSelector } from "../../redux/selectors"
import { initialCompany } from "./utils"
import notify from "../../utility/context/notify"
import { useRef } from "react"
import CompanySetupPages from "./CompanySetupPages"
import CompanySetupFilters from "./CompanySetupFilters"
import CompanySetupStatus from "./CompanySetupStatus"
import { getSystemLanguage } from "./languages"
import { history } from "../../history"

function CompanySetup(props) {
    const companyId = props?.match?.params?.companyId
    const isNewCompany = companyId === 'new'
    const [stepper, setStepper] = useState(null)
    const ref = useRef(null)
    const [dataAll, setDataAll] = useState(null)
    const [company, setCompany] = useState(initialCompany)

    useEffect(() => {
        refresh()
    }, [props.user?._id, companyId, isNewCompany])

    const refresh = () => {
        if (!isNewCompany) {
            fetchApi({
                url: `/companies/company/${companyId}`,
                method: 'GET',
                token: props.token
            }).then((company) => {
                setCompany(company)
            })
        }
        fetchApi({
            url: `/companies/user/${props.user?._id}`,
            method: 'GET',
            token: props.token
        }).then((companies) => {
            setDataAll(companies)
        })
    }

    const addCompany = (data) => {
        const endDate = new Date(props.user.subscriptionEndAt)
        const isActive = new Date() < endDate
        if (!props.user.tarif || !isActive) {
            notify(getSystemLanguage() === 'RU' ? 'Активируйте подписку чтобы использовать Cloakit' : 'Activate your Plan to use Cloakit', 'error')
            return null
        }
        const tarif = (props.tarifs && props.tarifs.find(({ _id }) => _id === props.user.tarif)) || ''
        if (dataAll.length + 1 > tarif.limit) {
            notify(getSystemLanguage() === 'RU' ? 'Лимит кампаний достугнут. Перейдите на следующий тариф чтобы создать кампанию.' : 'Upgrade your plan to create new campaign', 'error')
            return null
        }
        if (dataAll.find(({ name }) => name === data.name)) {
            notify(getSystemLanguage() === 'RU' ? 'Имя уже занято.' : 'Name already taken', 'error')
            return null
        }
        if (!data.name) {
            notify(getSystemLanguage() === 'RU' ? 'Имя обязательно.' : 'Name is required', 'error')
            return null
        }
        return fetchApi({
            url: `/companies/${props.user._id}`,
            method: 'POST',
            token: props.token,
            body: { ...data, userId: props.user._id, testOffers: data.testOffers?.map((o) => ({...o, probability: Number(o.probability)})) }
        }).then(() => {
            notify(getSystemLanguage() === 'RU' ? 'Кампания создана.' : 'New campaign was added.', 'success')
            history.push('/campaigns')
        })
    }

    const updateCompany = ({ _id, ...data }) =>
        fetchApi({
            url: `/companies/company/${_id}`,
            method: 'PATCH',
            token: props.token,
            body: { ...data, testOffers: data.testOffers?.map((o) => ({...o, probability: Number(o.probability)})) }
        }).then(() => {
            notify(getSystemLanguage() === 'RU' ? 'Кампания обновлена.' : 'Campaign was updated.', 'success')
            history.push('/campaigns')
        })


    const steps = [
        {
            id: 'account-details',
            title: getSystemLanguage() === 'RU' ? 'Страницы' : 'Pages',
            subtitle: getSystemLanguage() === 'RU' ? 'Настройка страниц' : 'Setup Pages',
            content: <CompanySetupPages addCompany={addCompany} updateCompany={updateCompany} setCompany={setCompany}
                stepper={stepper} company={company} isNewCompany={isNewCompany} 
                title={isNewCompany ? getSystemLanguage() === 'RU' ? 'Создание кампании' : 'New Campaign' : getSystemLanguage() === 'RU' 
                    ? `Обновление кампании ${company?.name}` : `Update Campaign ${company?.name}`} />
        },
        {
            id: 'personal-info',
            title: getSystemLanguage() === 'RU' ? 'Фильтры' : 'Filters',
            subtitle: getSystemLanguage() === 'RU' ? 'Настройка фильтров' : 'Setup Filters',
            content: <CompanySetupFilters addCompany={addCompany} updateCompany={updateCompany} setCompany={setCompany}
                stepper={stepper} company={company} isNewCompany={isNewCompany} 
                title={isNewCompany ? getSystemLanguage() === 'RU' ? 'Создание кампании' : 'New Campaign' : getSystemLanguage() === 'RU' 
                    ? `Обновление кампании ${company?.name}` : `Update Campaign ${company?.name}`} />
        },
        {
            id: 'step-address',
            title: getSystemLanguage() === 'RU' ? 'Статус' : 'Status',
            subtitle: getSystemLanguage() === 'RU' ? 'Настройка статуса кампании' : 'Set Status of the Campaign',
            content: <CompanySetupStatus addCompany={addCompany} updateCompany={updateCompany} setCompany={setCompany}
                stepper={stepper} company={company} isNewCompany={isNewCompany} 
                title={isNewCompany ? getSystemLanguage() === 'RU' ? 'Создание кампании' : 'New Campaign' : getSystemLanguage() === 'RU' 
                    ? `Обновление кампании ${company?.name}` : `Update Campaign ${company?.name}`} />
        }
    ]

    return (
        <div className='horizontal-wizard'>
            <Wizard instance={el => setStepper(el)} ref={ref} steps={steps} options={{
                linear: false
            }} type='vertical' />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        tarifs: tarifsSelector(state),
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps)(CompanySetup)
