import React, { useEffect, useState } from "react"
import { Label, Input, FormGroup, Button } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import { initialBlackIp } from "./utils"

const DataListSidebar = ({ show, data, updateData, addData, cancelSidebar }) => {
  const [localData, setLocalData] = useState(data || initialBlackIp)

  useEffect(() => {
    setLocalData(data || initialBlackIp)
  }, [data])

  const handleSubmit = obj => {
    if (data !== null) {
      updateData(obj)
    } else {
      addData(obj)
    }
    cancelSidebar()
    setLocalData(initialBlackIp)
  }

  return (
    <div
      className={classnames("data-list-sidebar", {
        show: show
      })}>
      <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
        <h4>{data !== null ? "UPDATE DATA" : "ADD NEW DATA"}</h4>
        <X size={20} onClick={cancelSidebar} />
      </div>
      <PerfectScrollbar
        className="data-list-fields px-2 mt-1"
        options={{ wheelPropagation: false }}>
        <FormGroup>
          <Label for="data-name">Isp</Label>
          <Input
            type="text"
            value={localData.value}
            placeholder=""
            onChange={e => setLocalData({ ...localData, value: e.target.value })}
            id="data-name"
          />
        </FormGroup>
      </PerfectScrollbar>
      <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
        <Button color="primary" onClick={() => handleSubmit(localData)}>
          {data !== null ? "Update" : "Submit"}
        </Button>
        <Button
          className="ml-1"
          color="danger"
          outline
          onClick={cancelSidebar}>
          Cancel
          </Button>
      </div>
    </div>
  )
}
export default DataListSidebar
