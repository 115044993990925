import React, { Fragment } from "react"
import { Check } from "react-feather"
import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexy"
import Select from "react-select"
import { Label, Row, Input, Form, Button, CustomInput, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap'
import { connect } from "react-redux"
import { blackListSelector } from "../../redux/selectors"
import countries from './countries'
import { history } from "../../history"
import { getSystemLanguage } from "./languages"
import classNames from 'classnames'

const LabelForAllow = (props) => {
    return <Label style={{ fontSize: 14 }} for={props.for}>
        {props.label}, <span className='label-with-allow' title={!props.value ? (getSystemLanguage() === 'RU' ? 'Нажмите чтобы запретить' : 'Click to Disallow') : (getSystemLanguage() === 'RU' ? 'Нажмите чтобы разрешить' : 'Click to Allow')} style={{ color: props.value ? '#ea5455' : '#ff9f43' }} onClick={props.onClick}>
            {props.value ? (getSystemLanguage() === 'RU' ? 'Запретить' : 'Disallow') : (getSystemLanguage() === 'RU' ? 'Разрешить' : 'Allow')}</span></Label>
}

const countryOptions = countries.sort().map((name) => ({ value: name, label: name }))

const deviceOptions = [
    { value: "desktop", label: "Desktop" },
    { value: "smartphone", label: "Mobile" },
    { value: "tablet", label: "Tablet" },
    { value: "tv", label: "Tv" },
]

function CompanySetupFilters({ type, ...props }) {

    const countryValue = countryOptions
        .filter(({ value }) => props.company?.countries?.includes(value))

    const deviceValue = deviceOptions
        .filter(({ value }) => props.company?.devices?.includes(value))

    const blackListOptions = props.blackList.map(({ _id: value, name: label }) => ({ value, label }))
    const blackListValue = props.blackList
        .filter(({ _id }) => props.company?.blackLists?.includes(_id))
        .map(({ _id: value, name: label }) => ({ value, label }))

    return (
        <Fragment>
            <div className='content-header'>
                <h5 className='mb-0'>{props.title}</h5>
            </div>
            <Form onSubmit={e => e.preventDefault()}>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <CustomInput
                            size="sm"
                            type="switch"
                            id='premium'
                            checked={props.company?.premium}
                            onChange={e => props.setCompany({ ...props.company, premium: e.target.checked })}
                            inline
                        >
                            <span className="switch-label">Cloakit</span>
                        </CustomInput>
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <CustomInput
                            size="sm"
                            type="switch"
                            id='proxy-data'
                            checked={props.company?.proxy}
                            onChange={e => props.setCompany({ ...props.company, proxy: e.target.checked })}
                            inline
                        >
                            <span className="switch-label">VPN/Proxy</span>
                        </CustomInput>
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <CustomInput
                            type="switch"
                            size="sm"
                            id='premium-ipv6'
                            checked={props.company?.ipv6}
                            onChange={e => props.setCompany({ ...props.company, ipv6: e.target.checked })}
                            inline
                        >
                            <span className="switch-label">IPv6</span>
                        </CustomInput>
                    </div>
                    <div className={classNames({
                        'form-group': true,
                        'form-password-toggle': true,
                        'col-md-12': true
                    })}>
                        <CustomInput
                            size="sm"
                            type="switch"
                            id='Maximum-clicks'
                            checked={props.company?.maximumClicksByIp}
                            onChange={e => props.setCompany({ ...props.company, maximumClicksByIp: e.target.checked })}
                            inline
                        >
                            <span className="switch-label">{getSystemLanguage() === 'RU' ? 'Максимальное количество кликов по IP-адресу' : 'Maximum Clicks by IP Address'}</span>
                        </CustomInput>
                    </div>
                    {props.company?.maximumClicksByIp ? <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-white">{getSystemLanguage() === 'RU' ? 'Фильтр по IP-адресу после' : 'Filter by IP Address After'}</Label>
                        <InputGroup>
                            <Input
                                type="number"
                                value={props.company?.maximumClicksByIpAfter}
                                onChange={e => props.setCompany({ ...props.company, maximumClicksByIpAfter: e.target.value })}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText style={{backgroundColor: 'transparent'}}>{getSystemLanguage() === 'RU' ? 'Клика(ов)' : 'Click(s)'}</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div> : null}
                    <div className='form-group form-password-toggle col-md-12'>
                        <LabelForAllow for="data-geo" label={getSystemLanguage() === 'RU' ? 'Гео' : 'Geo Filter'}
                            value={props.company?.countriesDisallow}
                            onClick={() => props.setCompany({ ...props.company, countriesDisallow: !props.company?.countriesDisallow })} />
                        <Select
                            value={countryValue}
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать' : 'Select'}
                            onChange={(value) => props.setCompany({ ...props.company, countries: value?.map(({ value }) => value) || [] })}
                            isMulti
                            size="sm"
                            options={countryOptions}
                            className="React"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <LabelForAllow for="data-device" label={getSystemLanguage() === 'RU' ? 'Устройства' : 'Device Filter'}
                            value={props.company?.devicesDisallow}
                            onClick={() => props.setCompany({ ...props.company, devicesDisallow: !props.company?.devicesDisallow })} />
                        <Select
                            value={deviceValue}
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать' : 'Select'}
                            onChange={(value) => props.setCompany({ ...props.company, devices: value?.map(({ value }) => value) || [] })}
                            isMulti
                            size="sm"
                            options={deviceOptions}
                            className="React"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <LabelForAllow for="data-referer" label={getSystemLanguage() === 'RU' ? 'Referer' : 'Referer Filter'}
                            value={props.company?.referersDisallow}
                            onClick={() => props.setCompany({ ...props.company, referersDisallow: !props.company?.referersDisallow })} />
                        <Input
                            type="textarea"
                            size="sm"
                            rows="2"
                            value={props.company?.referers}
                            onChange={e => props.setCompany({ ...props.company, referers: e.target.value })}
                        />
                        <div className="d-inline-block mr-1">
                            <Checkbox
                                color="primary"
                                icon={<Check className="vx-icon" size={10} />}
                                checked={props.company?.emptyRefererFilter}
                                onChange={() => props.setCompany({ ...props.company, emptyRefererFilter: !props.company.emptyRefererFilter })}
                                label={getSystemLanguage() === 'RU' ? 'Фильтровать пустой Referer' : 'Filter Empty Referer'}
                                size="sm"
                            />
                        </div>
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <LabelForAllow for="data-utmParameters" label={getSystemLanguage() === 'RU' ? 'Utm Parameters' : 'Utm Parameters Filter'}
                            value={props.company?.utmParametersDisallow}
                            onClick={() => props.setCompany({ ...props.company, utmParametersDisallow: !props.company?.utmParametersDisallow })} />
                        <Input
                            type="textarea"
                            size="sm"
                            rows="2"
                            value={props.company?.utmParameters}
                            onChange={e => props.setCompany({ ...props.company, utmParameters: e.target.value })}
                        />
                    </div>
                    <div style={{ zIndex: 1000 }} className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-black-list">Black List</Label>
                        <Select
                            value={blackListValue}
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать' : 'Select'}
                            onChange={(value) => props.setCompany({ ...props.company, blackLists: value?.map(({ value }) => value) || [] })}
                            size="sm"
                            isMulti
                            options={blackListOptions}
                            className="React"
                            classNamePrefix="select"
                        />
                    </div>
                </Row>
                <hr />
                <div className='d-flex justify-content-between'>
                    <Button.Ripple style={{ width: 120 }} color='danger' className='btn-next' outline onClick={() => history.push('/campaigns')}>
                        <span className='align-middle d-sm-inline-block'>{getSystemLanguage() === 'RU' ? 'Отмена' : 'Cancel'}</span>
                    </Button.Ripple>
                    <Button.Ripple style={{ width: 130 }} color='primary' className='btn-next' onClick={() => props.isNewCompany ? props.addCompany(props.company) : props.updateCompany(props.company)}>
                        <span className='align-middle d-sm-inline-block'>{props.isNewCompany ? getSystemLanguage() === 'RU' ? 'Создать' : 'Save' : getSystemLanguage() === 'RU' ? 'Изменить' : 'Update'}</span>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        blackList: blackListSelector(state)
    }
}

export default connect(mapStateToProps)(CompanySetupFilters)
