import { CARD, MONO, MONTH, PRIVAT, VISA } from "./constants"
import { coinSelector, promocodeSelector, tokenSelector } from "../../../redux/selectors"
import { useCallback, useState } from "react"

import Description from "./Description"
import Navs from "./Navs"
import PaymentPage from "./PaymentPage"
import React from "react"
import SelectCardBank from "./SelectCardBank"
import SelectPaymentMethod from "./SelectPaymentMethod"
import StatPayments from "./StatPayments"
import { connect } from "react-redux"
import fetchApi from "../../../utility/context/fetchApi"
import { getSystemLanguage } from "../languages"
import notify from "../../../utility/context/notify"
import { withBenefit } from "./utils"

function Payments(props) {
    const [active, setActive] = useState(MONTH)
    const [plan, setPlan] = useState(null)
    const [selectPageModal, setSelectPageModal] = useState(false)
    const [cardPageModal, setCardPageModal] = useState(false)
    const [paymentPageModal, setPaymentPageModal] = useState(false)
    const [promo, setPromo] = useState(props.user.promocode)
    const [paymentCounter, setPaymentCounter] = useState(0)
    const [paySystem, setPaySystem] = useState('')
    const [cardSystem, setCardSystem] = useState('')

    const openPaymentModal = useCallback(() => {
        if (paySystem === CARD && selectPageModal) {
            return setCardPageModal(true)
        }
        setPaymentPageModal(true)
    }, [paySystem, selectPageModal])
    const closePaymentModal = useCallback(() => setPaymentPageModal(false), [])

    const openSelectModal = useCallback(() => {
        if (paySystem === CARD && paymentPageModal) {
            return setCardPageModal(true)
        }
        setSelectPageModal(true)
    }, [paySystem, paymentPageModal])
    const closeSelectModal = useCallback(() => setSelectPageModal(false), [])

    const closeCardModal = useCallback(() => setCardPageModal(false), [])

    const activeHandler = useCallback((target) => setActive(target), [])
    const planHandler = useCallback((plan) => {
        setPlan(plan)
        openSelectModal()
    }, [openSelectModal])
    const promoHandler = useCallback((e) => setPromo(e.target.value), [])
    const paySystemHandler = useCallback((paySystem) => setPaySystem(paySystem), [])
    const cardSystemHandler = useCallback((cardSystem) => setCardSystem(cardSystem), [])

    const promocode = props.promocodes.find(({ name }) => String(name).toLowerCase().trim() === String(promo).toLowerCase().trim())
    const discount = promocode?.discount || 0

    const planPrice = plan?.price || 0
    const summaryPrice = active === MONTH ? planPrice : withBenefit(planPrice) * 12
    const summaryDiscount = summaryPrice * discount / 100
    const summaryPriceWithDiscount = summaryPrice - summaryDiscount
    const coin = props.coins.find(({ nameShort }) => nameShort === paySystem)
    let coinIndex = coin?.index || 0
    if (paySystem === CARD && [PRIVAT, MONO, VISA].includes(cardSystem)) {
        coinIndex = 1
    }
    const total = summaryPriceWithDiscount * coinIndex
    const targetPrice = Number.isInteger(total) ? total : total.toFixed(4)

    const sendHandler = useCallback(() => {
        return fetchApi({
            url: `/payment`,
            method: 'POST',
            token: props.token,
            body: {
                userId: props.user?._id,
                tarifId: plan?._id,
                email: props.user?.email,
                promocode: promocode?.name || '',
                name: plan?.name,
                method: paySystem,
                total: summaryPriceWithDiscount,
                totalInTarget: targetPrice,
                type: active,
            }
        }).then(() => {
            setPaymentCounter(paymentCounter + 1)
            closePaymentModal()
            notify(getSystemLanguage() === 'RU' ? 'Запрос отправлен успешно. Ожидайте подтверждения.' : 'The request was sent successfully. Wait for confirmation.', 'success')
        })
    }, [props.user, plan, promocode, paySystem, targetPrice, summaryPriceWithDiscount, active, props.token, closePaymentModal])

    return <div>
        <Navs current={active} onClick={activeHandler} />
        <Description current={active} onClick={planHandler} />
        <SelectPaymentMethod
            summaryPrice={summaryPrice}
            summaryDiscount={summaryDiscount}
            summaryPriceWithDiscount={summaryPriceWithDiscount}
            discount={discount}
            plan={plan}
            active={active}
            promo={promo}
            paySystem={paySystem}
            open={selectPageModal}
            closeHandler={closeSelectModal}
            promoHandler={promoHandler}
            setPaySystem={paySystemHandler}
            openSelectModal={openSelectModal}
            openPaymentModal={openPaymentModal} />
        <SelectCardBank
            summaryPrice={summaryPrice}
            summaryDiscount={summaryDiscount}
            summaryPriceWithDiscount={summaryPriceWithDiscount}
            discount={discount}
            plan={plan}
            active={active}
            promo={promo}
            cardSystem={cardSystem}
            open={cardPageModal}
            closeHandler={closeCardModal}
            promoHandler={promoHandler}
            setCardSystem={cardSystemHandler}
            openSelectModal={openSelectModal}
            openPaymentModal={openPaymentModal} />
        <PaymentPage
            summaryPrice={summaryPrice}
            summaryDiscount={summaryDiscount}
            summaryPriceWithDiscount={summaryPriceWithDiscount}
            coin={coin}
            coins={props.coins}
            targetPrice={targetPrice}
            discount={discount}
            plan={plan}
            cardSystem={cardSystem}
            active={active}
            promo={promo}
            paySystem={paySystem}
            open={paymentPageModal}
            closeHandler={closePaymentModal}
            openSelectModal={openSelectModal}
            sendHandler={sendHandler} />
        <StatPayments paymentCounter={paymentCounter} />
    </div >
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        promocodes: promocodeSelector(state) || [],
        coins: coinSelector(state) || [],
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps)(Payments)