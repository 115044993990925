import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import { tarifsSelector } from '../../../redux/selectors'
import { getSystemLanguage } from '../languages'
import { MONTH, YEAR } from './constants'
import { withBenefit } from './utils'

const TarifsTab = (props) => <TabPane tabId={props.target}>
    <Row>
        {props.tarifs.sort((tarif, tarif2) => tarif.price - tarif2.price).map(tarif => {
            return <Col lg="4" md="6" sm="12" key={tarif.name}>
                <Card>
                    <CardBody>
                        <h5 style={{ textTransform: 'uppercase' }}>{tarif.name}</h5>
                        <ul>
                            <li style={{ fontWeight: 'bold' }}>{tarif.limit <= 999999999999 ? tarif.limit === 1 ? getSystemLanguage() === 'RU' ? '1 Активная кампания' : '1 Active Campaign' : `${tarif.limit} ${getSystemLanguage() === 'RU' ? 'Активных кампаний' : 'Active Campaigns'}` : getSystemLanguage() === 'RU' ? 'Безлимит кампаний' : 'Unlimited Active Campaigns'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Безлимит кликов' : 'Unlimited Clicks'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Защита от ботов и модераторов всех рекламных источников' : 'Bots Filtration'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Защита от спай сервисов' : 'Spy Service Filtration'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Защита от VPN/Proxy' : 'VPN/Proxy Filtration'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Статистика в реальном времени' : 'Realtime Clicks Statistics'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'PHP Интеграция' : 'PHP Intergration'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'JS Интеграция' : 'JS Intergration'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Android и iOS Интеграция' : 'Android and iOS'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Премиум ГЕО Базы' : 'Premium GEO Database'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Поддержка IPv4' : 'Support IPv4'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Поддержка IPv6' : 'Support IPv6'}</li>
                            <li>{getSystemLanguage() === 'RU' ? 'Поддержка всех источников трафика' : 'Supports All Traffic Sources'}</li>
                            <li style={{ fontWeight: 'bold' }}>{tarif.limit > 1 ? getSystemLanguage() === 'RU' ? 'Приоритетная живая поддержка' : 'Priority Live Support' : getSystemLanguage() === 'RU' ? 'Живая поддержка' : 'Live Support'}</li>
                            {tarif.limit <= 999999999999 ? null : <li style={{ fontWeight: 'bold' }}>{getSystemLanguage() === 'RU' ? 'Инструкция по запуску аккаунтов в популярных рекламных источниках' : 'Instructions for launching accounts in popular advertising sources'}</li>}
                        </ul>
                        {props.children(tarif)}
                        <div className="card-btns d-flex justify-content-between mt-2">
                            <Button.Ripple color='primary' className="text-white shadow-button" onClick={() => props.onClick(tarif)}>
                                {getSystemLanguage() === 'RU' ? 'Выбрать' : 'Select'}
                            </Button.Ripple>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        })}
    </Row>
</TabPane>

const mapStateToProps = state => {
    return {
        tarifs: tarifsSelector(state),
    }
}

const TarifsTabConnected = connect(mapStateToProps)(TarifsTab)

const Description = (props) =>
    <TabContent activeTab={props.current}>
        <TarifsTabConnected target={MONTH} onClick={props.onClick}>
            {(tarif) => [<h3>${tarif.price}/{getSystemLanguage() === 'RU' ? 'месяц' : 'month'}</h3>,
            <h3 style={{ color: '#20ac4b' }}>${tarif.price * 0.6}/{getSystemLanguage() === 'RU' ? 'месяц с промокодом' : 'month with promo code'}</h3>]}
        </TarifsTabConnected>
        <TarifsTabConnected target={YEAR} onClick={props.onClick}>
            {(tarif) => [<h4 style={{ color: '#20ac4b' }}>${withBenefit(tarif.price) * 0.6}/{getSystemLanguage() === 'RU' ? 'месяц - c промокодом' : 'month - with promo code'}</h4>,
            <h3 style={{ color: '#20ac4b' }}>${withBenefit(tarif.price) * 12 * 0.6}/{getSystemLanguage() === 'RU' ? 'год - с промокодом' : 'year - with promo code'}</h3>]}
        </TarifsTabConnected>
    </TabContent>

export default Description