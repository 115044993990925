import React, { useEffect, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import { ChevronDown, ChevronLeft, ChevronRight, Edit, Trash } from "react-feather"
import ReactPaginate from "react-paginate"
import classNames from "classnames"

import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import { blackListSelector, tokenSelector } from "../../redux/selectors"
import DataListSidebar from "./BlackListDataListSidebar"
import { comparators, CustomHeader, getSliceData, getSortIndex, getTotalPages, typeString } from "./utils"
import { setBlackList } from "../../redux/actions"
import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback"
import { getSystemLanguage } from "./languages"

const ActionsComponent = ({ row, editRow, deleteRow }) => {
    return (
        <div className="data-list-action minus-6">
            <Edit
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    return editRow(row)
                }}
            />
            <Trash
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    deleteRow(row)
                }}
            />
        </div>
    )
}

function BlackList(props) {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(4)
    const [totalPages, setTotalPages] = useState(1)
    const [sortIndex, setSortIndex] = useState([1, 2])
    const [sidebar, setSidebar] = useState(false)
    const [currentData, setCurrentData] = useState(null)
    const [filter, setFilter] = useState('')
    const [sort, setSort] = useState({ selector: 'name', type: 'asc', typeData: typeString })

    useEffect(() => {
        const allDataSorted = props.totalData.slice().sort(comparators[sort.typeData](sort.selector))
        if (sort.type === 'desc') allDataSorted.reverse()
        if (filter) {
            const filteredData = allDataSorted.filter(({ name }) => name.toLowerCase().includes(filter.toLocaleLowerCase()))
            setData(filteredData)
            setTotal(filteredData.length)
            setTotalPages(1)
            setPage(0)
            setSortIndex([1, filteredData.length])
        } else {
            const totalPages = getTotalPages(allDataSorted, limit)
            if (page >= totalPages) {
                setPage(totalPages - 1)
            }
            setData(getSliceData(allDataSorted, page, limit))
            setTotal(allDataSorted.length)
            setTotalPages(getTotalPages(allDataSorted, limit))
            setSortIndex(getSortIndex(page, limit, allDataSorted.length))
        }
    }, [page, props.totalData, limit, filter, sort, props.token])

    const handleLimit = (limit) => {
        setPage(0)
        setLimit(limit)
    }

    const handleSort = ({ selector, type: typeData }, type) => {
        setSort({ selector, type, typeData })
    }

    const cancelSidebar = () => {
        setSidebar(false)
        setCurrentData(null)
    }

    const addData = (data) => {
        fetchApi({
            url: '/black-list',
            method: 'POST',
            token: props.token,
            body: { ...data, userId: props.user._id }
        }).then((doc) => {
            props.setBlackList([doc, ...props.totalData])
            setPage(0)
            setCurrentData(null)
        })
    }

    const updateData = ({ _id, ...data }) => {
        fetchApi({
            url: `/black-list/${_id}`,
            method: 'PATCH',
            token: props.token,
            body: { ...data, userId: props.user._id }
        }).then((res) => {
            props.setBlackList(props.totalData.map((node) => {
                if (node._id === res._id) return res
                return node
            }))
            setCurrentData(null)
        })
    }

    const removeData = ({ _id }) => {
        fetchApi({
            url: `/black-list/${_id}`,
            method: 'DELETE',
            token: props.token,
        }).then(() => {
            props.setBlackList(props.totalData.filter((node) =>
                node._id !== _id
            ))
        })
    }
    const refAlert = useRef()

    const columns = [
        {
            name: getSystemLanguage() === 'RU' ? "Имя" : 'Name' ,
            type: typeString,
            selector: "name",
            sortable: true,
            grow: 1
        },
        {
            name: getSystemLanguage() === 'RU' ? "Управление" : 'Actions' ,
            maxWidth: '100px',
            cell: row => (
                <ActionsComponent
                    row={row}
                    editRow={(row) => {
                        setCurrentData(row)
                        setSidebar(true)
                    }}
                    deleteRow={(row) => refAlert.current.show(row)}
                />
            )
        }
    ]
    return <div className={`data-list list-view`}>
        <BasicSweetCallback refAlert={refAlert} onConfirm={(row) => {
            removeData(row)
        }} />
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            subHeader
            responsive
            pointerOnHover
            noDataComponent={<div style={{padding: 24}}>{ getSystemLanguage() === 'RU' ? 'Нет записей' : 'No Data' }</div>}
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            subHeaderComponent={
                <CustomHeader
                    handleSidebar={setSidebar}
                    handleFilter={(value) => setFilter(value)}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={limit}
                    total={total}
                    index={sortIndex}
                />
            }
        />
        <DataListSidebar
            show={sidebar}
            data={currentData}
            updateData={updateData}
            addData={addData}
            cancelSidebar={cancelSidebar}
        />
        <div
            className={classNames("data-list-overlay", {
                show: sidebar
            })}
            onClick={cancelSidebar}
        />
    </div>
}

const mapStateToProps = state => {
    return {
        totalData: blackListSelector(state),
        token: tokenSelector(state),
        user: state.app.user?.user,
    }
}

export default connect(mapStateToProps, { setBlackList })(BlackList)
