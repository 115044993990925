import React, { useEffect, useState, Fragment } from 'react'
import classnames from 'classnames'
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom'
import { InputGroup, InputGroupAddon, Input, InputGroupText, Media } from 'reactstrap'
import { tokenSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import fetchApi from '../../utility/context/fetchApi'
import { history } from '../../history'
import notify from '../../utility/context/notify'
import { getSystemLanguage } from './languages'

const BlogSidebar = (props) => {
  const [data, setData] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    fetchApi({
      url: `/post`,
      method: 'GET',
      token: props.token,
    }).then((posts) => {
      setData(posts.filter(({ status }) => status !== 'Draft'))
    })
  }

  const renderRecentPosts = () => {
    return data.filter(({ excerpt, blogTitle, description }) => {
      if (!search) return true
      return [blogTitle, description, excerpt].map((str) => str.toLocaleLowerCase()).some((txt) => txt.includes(search.toLocaleLowerCase()))
    }).map((post, index) => {
      const linkTo = () => {
        if (post.tarifIds.length === 0 || post.tarifIds.includes(props.user?.tarif)) {
          return history.push(`/knowledge-base/${post._id}`)
        }
        notify('Upgrade your plan to read this post.', 'error')
      }
      return (
        <Media
          key={index}
          className={classnames({
            'mb-2': index !== data.length - 1
          })}
        >
          <Link className='mr-2' onClick={linkTo}>
            <img className='rounded' src={post.featuredImage} alt={post.blogTitle} width='100' height='70' />
          </Link>
          <Media body>
            <h6 className='blog-recent-post-title'>
              <Link className='text-body-heading' onClick={linkTo}>
                {post.blogTitle}
              </Link>
            </h6>
            <div className='text-muted mb-0'>{new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(post.createdAt))}</div>
          </Media>
        </Media>
      )
    })
  }

  return (
    <div className='sidebar-detached sidebar-right'>
      <div className='sidebar'>
        {data !== null && data.length ?
          <div className='blog-sidebar right-sidebar my-2 my-lg-0'>
            <div className='right-sidebar-content'>
              <div className='blog-search'>
                <InputGroup className='input-group-merge'>
                  <Input placeholder={getSystemLanguage() === 'RU' ? 'Искать' : 'Search here'} value={search} onChange={(e) => setSearch(e.target.value)} />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>
                      <Icon.Search size={14} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              {data !== null ? (
                <Fragment>
                  <div className='blog-recent-posts mt-3'>
                    <h6 className='section-label'>{getSystemLanguage() === 'RU' ? 'Недавние статьи' : 'Recent Posts'}</h6>
                    <div className='mt-75'>{renderRecentPosts()}</div>
                  </div>
                </Fragment>
              ) : null}
            </div>
          </div>
          : null}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.app.user?.user,
    token: tokenSelector(state)
  }
}

export default connect(mapStateToProps)(BlogSidebar)

