import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ChevronDown, ChevronLeft, ChevronRight, Copy, Download, Edit, Trash, X } from "react-feather"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import downloadStandart from './downloadStandart'
import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import { companyLimitSelector, filterCompaniesSelector, filterDateCompaniesSelector, tarifsSelector, tokenSelector } from "../../redux/selectors"
import { CustomHeader, getSliceData, getSortIndex, getTotalPages } from "./utils"
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { history } from "../../history"
import { generateJsClient, generateJsClientSimple } from "./generateJsClient"
import notify from "../../utility/context/notify"
import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback"
import { useRef } from "react"
import { setCompanyLimit, setFilterCompanies, setFilterDateCompanies } from "../../redux/actions"
import { getSystemLanguage } from "./languages"
import genWordpressScript from "./downloadStandartWordPress"
import downloadKeitaro from "./downloadKeitaro"

const ActionsComponent = ({ row, copy, download, deleteRow }) => {
    return (
        <div className="data-list-action minus-6">
            <Download
                className="cursor-pointer control-icon"
                size={24}
                onClick={() => {
                    return download(row)
                }}
            />
            <Copy
                className="cursor-pointer control-icon"
                size={24}
                onClick={() => {
                    return copy(row)
                }}
            />
            <Edit
                className="cursor-pointer control-icon"
                size={24}
                onClick={() => {
                    history.push(`/campaigns/setup/${row._id}`)
                }}
            />
            <Trash
                className="cursor-pointer control-icon"
                size={24}
                onClick={() => deleteRow(row)}
            />
        </div>
    )
}

const JSintegration = () => {
    const [userServer, setUserServer] = useState('')
    const [userClientScript, setUserClientScript] = useState('')
    const [userClientScriptSimple, setUserClientScriptSimple] = useState('')
    const userServerHandler = (value) => {
        setUserServer(value)
        setUserClientScript(generateJsClient(value))
        setUserClientScriptSimple(generateJsClientSimple(value))
    }
    return <div>
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 1. Скачайте скрипт (index.php)' : 'Step 1. Download Script (index.php)'}</span><br />
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 2. Введите путь к скрипту на сервере (index.php)' : 'Step 2. Enter path where located script (index.php)'}</span>
        <FormGroup style={{ marginBottom: 6, marginTop: 6 }}>
            <Input
                style={{ width: 470 }}
                type="text"
                value={userServer}
                placeholder="http://example.org"
                onChange={(e) => {
                    userServerHandler(e.target.value)
                }}
            />
        </FormGroup>
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 3. Вставьте обычный код или кодированный в Base64 в HEAD елемент Вашего сайта.' : 'Step 3. Insert Simple Code or Base64 Encode in HEAD of your site.'}</span>
        <FormGroup style={{ marginBottom: 0 }}>
            <Label for="data-user-server-minified">{getSystemLanguage() === 'RU' ? 'Обычный Код' : 'Simple Code'}</Label>
            <Row>
                <Input
                    style={{ width: 434, marginLeft: 14, height: 39 }}
                    disabled
                    value={userClientScriptSimple}
                />
                <CopyToClipboard text={userClientScriptSimple}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
        <FormGroup style={{ marginBottom: 0 }}>
            <Label for="data-user-server-minified">{getSystemLanguage() === 'RU' ? '' : ''}Base64</Label>
            <Row>
                <Input
                    style={{ width: 434, marginLeft: 14, height: 39 }}
                    disabled
                    value={userClientScript}
                />
                <CopyToClipboard text={userClientScript}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
    </div>
}

const WordpressIntegration = (props) => {
    return <div>
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 1. Откройте index.php сайта на Wordpress' : 'Step 1. Open your Wordpress index.php file'}</span><br />
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 2. Скопируйте код ниже и вставьте после <?php' : 'Step 2. Copy code and insert after <?php'}</span>
        <FormGroup style={{ marginBottom: 0, marginTop: 6 }}>
            <Row>
                <Input
                    style={{ width: 411, marginLeft: 14, height: 39 }}
                    disabled
                    value={genWordpressScript(props.companyId)}
                />
                <CopyToClipboard text={genWordpressScript(props.companyId)}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
    </div>
}

const WordpressPluginIntegration = (props) => {
    return <div>
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 1. Скачайте и установите плагин - ' : 'Step 1. Download and setup plugin - '}</span><a href='/files/cloakit.zip' download style={{ color: "#ff9700" }}>{getSystemLanguage() === 'RU' ? 'Скачать' : 'Download'}</a><br />
        <span>{getSystemLanguage() === 'RU' ? 'Шаг 2. Вставьте id кампании в плагин' : 'Step 2. Copy and paste Campaign id in plugin'}</span>
        <FormGroup style={{ marginBottom: 0, marginTop: 6 }}>
            <Label for="data-user-server-minified">Campaign id</Label>
            <Row>
                <Input
                    style={{ width: 411, marginLeft: 14, height: 39 }}
                    disabled
                    value={props.companyId}
                />
                <CopyToClipboard text={props.companyId}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
    </div>
}

const KeitaroIntegration = (props) => {
    return <div>
        <span style={{ color: "#ff9700" }} onClick={() => history.push('/knowledge-base')}>{getSystemLanguage() === 'RU' ? 'Инструкции по интеграции' : 'Integration instructions'}</span><br />
        <span style={{ color: "#ff9700" }} onClick={() => downloadKeitaro()}>{getSystemLanguage() === 'RU' ? 'Скачать' : 'Download'}</span> cloakit.php<br />
        <FormGroup style={{ marginBottom: 0, marginTop: 6 }}>
        <Label for="data-user-server-minified">Campaign id</Label>
            <Row>
                <Input
                    style={{ width: 411, marginLeft: 14, height: 39 }}
                    disabled
                    value={props.companyId}
                />
                <CopyToClipboard text={props.companyId}>
                    <Copy className="cursor-pointer control-icon" style={{ marginLeft: 4, marginTop: 2 }} onClick={() => notify(getSystemLanguage() === 'RU' ? 'Скопировано' : 'Copied Successfully', 'success')} size={20} />
                </CopyToClipboard>
            </Row>
        </FormGroup>
    </div>
}

const CustomInput = ({ value, defaultValue, cleanDate, inputRef, ...props }) => {
    return <div class="flatpickr" style={{ position: 'relative' }}>
        <input {...props} ref={inputRef} />
        {value ? <X size={20} className='cursor-pointer' onClick={cleanDate} style={{ position: 'absolute', top: 14, right: 10 }} /> : null}
    </div>
}

function Companies(props) {
    const [data, setData] = useState([])
    const [dataAll, setDataAll] = useState(null)
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [sortIndex, setSortIndex] = useState([1, 2])
    // const [filter, setFilter] = useState('')
    const [download, setDownload] = useState()
    const [accord, setAccord] = useState([])
    const [dateRange, setDateRange] = useState()

    const refAlert = useRef()

    const userIdFromRouter = props?.match?.params?.userId
    const userId = userIdFromRouter || props.user._id

    const toggleAccord = (id) => {
        const element = accord.find(item => item === id)
        if (element) {
            setAccord(accord.filter(item => item !== id))
        } else {
            setAccord([...accord, id])
        }
    }

    useEffect(() => {
        if (props.filterDate !== 4 || dateRange) {
            refresh()
        }
    }, [props.filterDate, userId, dateRange])

    const refresh = () => {
        if (dataAll) {
            setDataAll(null)
        }
        fetchApi({
            url: `/companies/user`,
            method: 'POST',
            token: props.token,
            body: {
                userId,
                filterDate: props.filterDate,
                timeShift: new Date().getTimezoneOffset(),
                customDate: dateRange
            }
        }).then((companies) => {
            setDataAll(companies)
        })
    }

    useEffect(() => {
        if (dataAll) {
            if (props.filter) {
                const filteredData = dataAll.filter(({ name }) => name.toLowerCase().includes(props.filter.toLocaleLowerCase()))
                setData(filteredData)
                setTotal(filteredData.length)
                setTotalPages(1)
                setPage(0)
                setSortIndex([1, filteredData.length])
            } else {
                const totalPages = getTotalPages(dataAll, props.limit)
                if (page >= totalPages) {
                    setPage(Math.max(0, totalPages - 1))
                }
                setData(getSliceData(dataAll, page, props.limit))
                setTotal(dataAll.length)
                setTotalPages(totalPages)
                setSortIndex(getSortIndex(page, props.limit, dataAll.length))
            }
        }
    }, [page, dataAll, props.limit, props.filter])

    const handleLimit = (limit) => {
        setPage(0)
        props.setLimit(limit)
    }

    const handleSort = () => { }

    const addData = (data) => {
        const endDate = new Date(props.user.subscriptionEndAt)
        const isActive = new Date() < endDate
        if (!props.user.tarif || !isActive) {
            notify(getSystemLanguage() === 'RU' ? 'Активируйте Тарифный план чтобы создать кампанию' : 'Activate your Plan to use Cloak IT', 'error')
            return null
        }
        const tarif = (props.tarifs && props.tarifs.find(({ _id }) => _id === props.user.tarif)) || ''
        if (dataAll.length + 1 > tarif.limit) {
            notify(getSystemLanguage() === 'RU' ? 'Увеличте Ваш тарифный план чтобы создать новую кампанию' : 'Upgrade your plan to create new campaign', 'error')
            return null
        }
        if (dataAll.find(({ name }) => name === data.name)) {
            notify(getSystemLanguage() === 'RU' ? 'Такое имя уже существует.' : 'Name already taken', 'error')
            return null
        }
        if (!data.name) {
            notify(getSystemLanguage() === 'RU' ? 'Имя обязательно' : 'Name is required', 'error')
            return null
        }
        return fetchApi({
            url: `/companies/${props.user._id}`,
            method: 'POST',
            token: props.token,
            body: { ...data, userId: props.user._id }
        }).then((doc) => {
            setDataAll([doc, ...dataAll])
            setPage(0)
        })
    }

    const getUniqueName = (name) => {
        const newName = `${name}-copy`
        const duplicate = dataAll.find(({ name }) => name === newName)
        if (duplicate) {
            return getUniqueName(newName)
        }
        return newName
    }

    const copy = ({ _id, createdAt, ...data }) => {
        const uniqueName = getUniqueName(data.name)
        addData({ ...data, name: uniqueName })
    }

    const removeData = ({ _id }) =>
        fetchApi({
            url: `/companies/company/${_id}`,
            method: 'DELETE',
            token: props.token,
        }).then(() => {
            setDataAll(dataAll.filter((node) =>
                node._id !== _id
            ))
        })

    const columns = [
        {
            name: getSystemLanguage() === 'RU' ? 'Дата создания' : 'Creation Date',
            grow: 1,
            cell: row => {
                const date = (row.createdAt && new Date(row.createdAt)) || new Date()
                return new Intl.DateTimeFormat(getSystemLanguage(), { day: 'numeric', year: 'numeric', month: 'short' }).format(date)
            }
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Кампания' : 'Campaign',
            selector: "name",
            grow: 1
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Клики' : 'Clicks',
            grow: 1,
            cell: ({ whiteCounter, offerCounter }) => {
                return whiteCounter + offerCounter
            }
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Отфильтровано' : 'Filtered',
            grow: 1,
            cell: ({ whiteCounter }) => {
                return whiteCounter
            }
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Соотношение' : 'Filter Rate',
            grow: 1,
            cell: ({ whiteCounter, offerCounter }) => {
                const commonCount = whiteCounter + offerCounter
                return whiteCounter === 0 ? 0 : `${Math.round(whiteCounter * 100 / commonCount)}%`
            }
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Статус' : 'Status',
            grow: 1,
            cell: row => <span style={{ textTransform: 'capitalize' }}>{statusOptions[row.status]}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Управление' : 'Actions',
            width: '145px',
            cell: row => (
                <ActionsComponent
                    row={row}
                    copy={copy}
                    download={setDownload}
                    deleteRow={(row) => refAlert.current.show(row)}
                />
            )
        }
    ]
    if (!dataAll) {
        return null
    }
    const collapseItems = [
        {
            id: 1,
            title: "PHP",
            content:
                () => {
                    return <div>
                        <span>{getSystemLanguage() === 'RU' ? 'Шаг 1. Скачайте скрипт (index.php)' : 'Step 1. Download Script (index.php)'}</span><br />
                        <span>{getSystemLanguage() === 'RU' ? 'Шаг 2. Направляйте трафик на скрипт (index.php)' : 'Step 2. Direct your traffic to script (index.php)'}</span>
                    </div>
                }
        },
        {
            id: 2,
            title: "JavaScript",
            content: JSintegration
        },
        {
            id: 3,
            title: "Wordpress",
            content: WordpressIntegration
        },
        {
            id: 4,
            title: "Wordpress Plugin",
            content: WordpressPluginIntegration
        },
        {
            id: 5,
            title: "Keitaro",
            content: KeitaroIntegration
        }
    ]

    const statusOptions = {
        "active": getSystemLanguage() === 'RU' ? 'Активна' : 'Active',
        "pause": getSystemLanguage() === 'RU' ? 'Пауза' : 'Pause',
        "dedicated": getSystemLanguage() === 'RU' ? 'Отл. Cтарт' : 'Dedicated',
    }
    const accordionItems = collapseItems.map(collapseItem => {
        const Content = collapseItem.content
        return (
            <Card
                style={{ boxShadow: 'none' }}
                key={collapseItem.id}
                className="collapse-border-item clickable"
                onClick={(e) => {
                    if (e.target.classList.contains("clickable")) {
                        toggleAccord(collapseItem.id)
                    }
                }}
            >
                <CardHeader className="clickable">
                    <CardTitle className="lead collapse-title collapsed clickable">
                        {collapseItem.title}
                    </CardTitle>
                    <ChevronDown size={15} className="collapse-icon clickable" />
                </CardHeader>
                <Collapse
                    isOpen={Boolean(accord.includes(collapseItem.id))}
                >
                    <CardBody><Content companyId={download?._id} /></CardBody>
                </Collapse>
            </Card>
        )
    })

    const today = new Date()
    return <div className={`data-list list-view`}>
        <BasicSweetCallback refAlert={refAlert} onConfirm={(row) => removeData(row)} />
        <Modal
            isOpen={Boolean(download)}
            toggle={() => setDownload(null)}
            className="modal-dialog-centered"
        >
            <ModalHeader toggle={() => setDownload(null)}>{getSystemLanguage() === 'RU' ? 'Интеграции' : 'Integration'}</ModalHeader>
            <ModalBody className="modal-dialog-centered">
                <Row><Col><span>{getSystemLanguage() === 'RU' ? 'Мы поддерживаем гибкие методы интеграции со всеми типами сайтов.' : 'We have flexible integration methods for all types of websites.'}</span><br />
                    <span>{getSystemLanguage() === 'RU' ? 'Все что вам нужно - скачать скрипт (index.php) на ваш хостинг или сервер.' : 'All what you need - download script (index.php) to your hosting or vps.'}</span><br /><br />
                    <div style={{ width: '100%' }} className="vx-collapse collapse-bordered ">
                        {accordionItems}
                    </div>
                </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => downloadStandart(download._id)}>{getSystemLanguage() === 'RU' ? 'Скачать скрипт' : 'Download Script'}</Button>
            </ModalFooter>
        </Modal>
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => {
                        setPage(page.selected)
                    }}
                />
            )}
            noHeader
            subHeader
            noDataComponent={<div style={{ padding: 24 }}>{getSystemLanguage() === 'RU' ? 'Кампании отсутствуют' : 'No Campaigns'}</div>}
            responsive
            onRowClicked={(row) => {
                history.push(`/statistics/${row._id}/${row.name}`)
            }}
            pointerOnHover
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            subHeaderComponent={
                <CustomHeader
                    filter={props.filterDate}
                    setFilterHandler={props.setFilterDate}
                    refresh={refresh}
                    handleSidebar={() => {
                        const endDate = new Date(props.user.subscriptionEndAt)
                        const isActive = new Date() < endDate
                        if (!props.user.tarif || !isActive) {
                            notify('Activate your Plan to use Cloakit.pro', 'error')
                            return null
                        }
                        const tarif = (props.tarifs && props.tarifs.find(({ _id }) => _id === props.user.tarif)) || ''
                        if (dataAll.length + 1 > tarif.limit) {
                            notify('Upgrade your plan to create new campaign', 'error')
                            return null
                        }
                        history.push(`/campaigns/setup/new`)
                    }}
                    filterValue={props.filter}
                    handleFilter={(value) => props.setFilter(value)}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={props.limit}
                    total={total}
                    index={sortIndex}
                >
                    {props.filterDate === 4 ? <div className='custom-date-wrapper-dima'>
                        <Flatpickr
                            value={dateRange}
                            onChange={setDateRange}
                            placeholder={getSystemLanguage() === 'RU' ? 'Выбрать день' : "Custom date filter"}
                            className="form-control sort-dropdown-custom-date"
                            options={{ minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30), maxDate: today }}
                            render={
                                ({ defaultValue, value, ...props }, ref) => {
                                    return <CustomInput {...props} inputRef={ref} value={value} cleanDate={() => setDateRange(undefined)} />
                                }
                            }
                        />
                    </div> : null}
                </CustomHeader>
            }
        />
    </div>
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        filterDate: filterDateCompaniesSelector(state),
        filter: filterCompaniesSelector(state),
        limit: companyLimitSelector(state),
        tarifs: tarifsSelector(state),
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setFilterDate: setFilterDateCompanies, setLimit: setCompanyLimit, setFilter: setFilterCompanies })(Companies)
