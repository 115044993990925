import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather"
import React, { useEffect, useState } from "react"
import { comparators, getSliceData, getSortIndex, getTotalPages, typeDate, typeString } from "../utils"

import DataTable from "react-data-table-component"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import fetchApi from "../../../utility/context/fetchApi"
import { getSystemLanguage } from "../languages"
import { tokenSelector } from "../../../redux/selectors"

const spanStyle = { maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', textTransform: 'capitalize' }

function StatPayments(props) {
    const [data, setData] = useState([])
    const [dataAll, setDataAll] = useState(null)
    const [, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit] = useState(3)
    const [totalPages, setTotalPages] = useState(1)
    const [, setSortIndex] = useState([1, 2])
    const [filter] = useState('')
    const [sort, setSort] = useState({ selector: 'createdAt', type: 'asc', typeData: typeDate })

    useEffect(() => {
        if (dataAll) {
            const allDataSorted = dataAll.slice().sort(comparators[sort.typeData](sort.selector))
            if (sort.type === 'asc') allDataSorted.reverse()
            if (filter) {
                const filteredData = allDataSorted
                    .filter((row) =>
                        (row.email && row.email.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.promocode && row.promocode.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.name && row.name.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.method && row.method.toLowerCase().includes(filter.toLowerCase())) ||
                        (row.type && row.type.toLowerCase().includes(filter.toLowerCase()))
                    )
                setData(filteredData)
                setTotal(filteredData.length)
                setTotalPages(1)
                setPage(0)
                setSortIndex([1, filteredData.length])
            } else {
                setData(getSliceData(allDataSorted, page, limit))
                setTotal(allDataSorted.length)
                setTotalPages(getTotalPages(allDataSorted, limit))
                setSortIndex(getSortIndex(page, limit, allDataSorted.length))
            }
        }
    }, [page, dataAll, limit, filter, sort])

    useEffect(() => {
        refresh()
    }, [props.paymentCounter])

    const refresh = () => {
        if (dataAll) {
            setDataAll(null)
        }
        fetchApi({
            url: `/payment/user/${props.user?._id}`,
            method: 'GET',
            token: props.token,
        }).then((companies) => {
            if (Array.isArray(companies) && companies.length) {
                setDataAll(companies)
            }
        })
    }

    const handleSort = ({ selector, type: typeData }, type) => {
        setSort({ selector, type, typeData })
    }

    const columns = [
        {
            name: getSystemLanguage() === 'RU' ? 'Дата' : 'Date',
            type: typeDate,
            selector: "createdAt",
            flexGrow: 1,
            cell: row => {
                const date = (row.createdAt && new Date(row.createdAt)) || new Date()
                return <span>{date.toLocaleTimeString()}<br />{new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(date)}</span>
            }
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Название' : 'Name',
            type: typeString,
            selector: "name",
            flexGrow: 1,
            cell: row => <span style={spanStyle} title={row.name}>{row.name}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Тип' : 'Type',
            type: typeString,
            selector: "type",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>1 {row.type}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Метод оплаты' : 'Payment Method',
            type: typeString,
            selector: "method",
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{row.method}</span>
        },
        {
            name: getSystemLanguage() === 'RU' ? 'Статус' : 'Status',
            flexGrow: 1,
            cell: row => <span style={spanStyle}>{row.activated ? getSystemLanguage() === 'RU' ? 'Завершен' : 'Complete' : getSystemLanguage() === 'RU' ? 'В обработке' : 'In Progress'}</span>
        }
    ]
    if (!dataAll) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 200 }}>
        </div>
    }

    return <div className='data-list simple' style={{ marginTop: 30 }}>
        <span style={{ fontSize: '1.5rem', marginLeft: '3px', fontWeight: 500 }}>{getSystemLanguage() === 'RU' ? 'Мои платежи' : 'My Payments'}</span>
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            responsive
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
        />
    </div>
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state),
        user: state.app.user?.user,
    }
}

export default connect(mapStateToProps)(StatPayments)
