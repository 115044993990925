import React from "react"
import {
  Card,
  CardHeader,
  Row,
  Col,
} from "reactstrap"
import LoginJWT from "./LoginJWT"
import logo from "../../../../assets/img/logo/logo.png"


class Login extends React.Component {
  render() {
    return (
      <Row className="m-0 justify-content-center" >
        <Col
          sm="8"
          xl="7"
          lg="8"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication login-card rounded-0 mb-0" style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
            <Row className="m-0">
              <Col lg="12" md="12" className="p-0">
                <Card className="mb-0 px-2 login-tabs-container login-custom-width" style={{backgroundColor: 'transparent', border: '1px solid #ff9e4363', borderRadius: '7px !important'}}>
                  <CardHeader className="pb-1">
                    <img style={{width: 240, height: 65, display: 'block', margin: 'auto'}} src={logo} alt="logo" />
                  </CardHeader>
                  <LoginJWT />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}
export default Login
