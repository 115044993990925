export default [
'Rwanda',
'Somalia',
'Yemen',
'Iraq',
'Saudi Arabia',
'Iran',
'Cyprus',
'Tanzania',
'Syria',
'Armenia',
'Kenya',
'DR Congo',
'Djibouti',
'Uganda',
'Central African Republic',
'Seychelles',
'Hashemite Kingdom of Jordan',
'Lebanon',
'Kuwait',
'Oman',
'Qatar',
'Bahrain',
'United Arab Emirates',
'Israel',
'Turkey',
'Ethiopia',
'Eritrea',
'Egypt',
'Sudan',
'Greece',
'Burundi',
'Estonia',
'Latvia',
'Azerbaijan',
'Republic of Lithuania',
'Svalbard and Jan Mayen',
'Georgia',
'Republic of Moldova',
'Belarus',
'Finland',
'Åland',
'Ukraine',
'North Macedonia',
'Hungary',
'Bulgaria',
'Albania',
'Poland',
'Romania',
'Kosovo',
'Zimbabwe',
'Zambia',
'Comoros',
'Malawi',
'Lesotho',
'Botswana',
'Mauritius',
'Eswatini',
'Réunion',
'South Africa',
'Mayotte',
'Mozambique',
'Madagascar',
'Afghanistan',
'Pakistan',
'Bangladesh',
'Turkmenistan',
'Tajikistan',
'Sri Lanka',
'Bhutan',
'India',
'Maldives',
'British Indian Ocean Territory',
'Nepal',
'Myanmar',
'Uzbekistan',
'Kazakhstan',
'Kyrgyzstan',
'French Southern Territories',
'Heard Island and McDonald Islands',
'Cocos [Keeling] Islands',
'Palau',
'Vietnam',
'Thailand',
'Indonesia',
'Laos',
'Taiwan',
'Philippines',
'Malaysia',
'China',
'Hong Kong',
'Brunei',
'Macao',
'Cambodia',
'South Korea',
'Japan',
'North Korea',
'Singapore',
'Cook Islands',
'East Timor',
'Russia',
'Mongolia',
'Australia',
'Christmas Island',
'Marshall Islands',
'Federated States of Micronesia',
'Papua New Guinea',
'Solomon Islands',
'Tuvalu',
'Nauru',
'Vanuatu',
'New Caledonia',
'Norfolk Island',
'New Zealand',
'Fiji',
'Libya',
'Cameroon',
'Senegal',
'Congo Republic',
'Portugal',
'Liberia',
'Ivory Coast',
'Ghana',
'Equatorial Guinea',
'Nigeria',
'Burkina Faso',
'Togo',
'Guinea-Bissau',
'Mauritania',
'Benin',
'Gabon',
'Sierra Leone',
'São Tomé and Príncipe',
'Gibraltar',
'Gambia',
'Guinea',
'Chad',
'Niger',
'Mali',
'Western Sahara',
'Tunisia',
'Spain',
'Morocco',
'Malta',
'Algeria',
'Faroe Islands',
'Denmark',
'Iceland',
'United Kingdom',
'Switzerland',
'Sweden',
'Netherlands',
'Austria',
'Belgium',
'Germany',
'Luxembourg',
'Ireland',
'Monaco',
'France',
'Andorra',
'Liechtenstein',
'Jersey',
'Isle of Man',
'Guernsey',
'Slovakia',
'Czechia',
'Norway',
'Vatican City',
'San Marino',
'Italy',
'Slovenia',
'Montenegro',
'Croatia',
'Bosnia and Herzegovina',
'Angola',
'Namibia',
'Saint Helena',
'Bouvet Island',
'Barbados',
'Cabo Verde',
'Guyana',
'French Guiana',
'Suriname',
'Saint Pierre and Miquelon',
'Greenland',
'Paraguay',
'Uruguay',
'Brazil',
'Falkland Islands',
'South Georgia and the South Sandwich Islands',
'Jamaica',
'Dominican Republic',
'Cuba',
'Martinique',
'Bahamas',
'Bermuda',
'Anguilla',
'Trinidad and Tobago',
'St Kitts and Nevis',
'Dominica',
'Antigua and Barbuda',
'Saint Lucia',
'Turks and Caicos Islands',
'Aruba',
'British Virgin Islands',
'Saint Vincent and the Grenadines',
'Montserrat',
'Saint Martin',
'Saint Barthélemy',
'Guadeloupe',
'Grenada',
'Cayman Islands',
'Belize',
'El Salvador',
'Guatemala',
'Honduras',
'Nicaragua',
'Costa Rica',
'Venezuela',
'Ecuador',
'Colombia',
'Panama',
'Haiti',
'Argentina',
'Chile',
'Bolivia',
'Peru',
'Mexico',
'French Polynesia',
'Pitcairn Islands',
'Kiribati',
'Tokelau',
'Tonga',
'Wallis and Futuna',
'Samoa',
'Niue',
'Northern Mariana Islands',
'Guam',
'Puerto Rico',
'U.S. Virgin Islands',
'U.S. Minor Outlying Islands',
'American Samoa',
'Canada',
'United States',
'Palestine',
'Serbia',
'Antarctica',
'Sint Maarten',
'Curaçao',
'Bonaire, Sint Eustatius, and Saba',
'South Sudan']