import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Edit,
  Eye,
  RotateCw,
  Trash,
} from "react-feather";
import {
  CustomHeader,
  comparators,
  getSliceData,
  getSortIndex,
  getTotalPages,
  typeDate,
  typeString,
} from "./utils";
import React, { useEffect, useRef, useState } from "react";
import {
  affiliateModeSelector,
  tarifsSelector,
  tokenSelector,
  usersSelector,
} from "../../redux/selectors";
import { setAffiliateMode, setUsers } from "../../redux/actions";

import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback";
import DataListSidebar from "./UserDataListSidebar";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { Spinner } from "reactstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import fetchApi from "../../utility/context/fetchApi";
import { history } from "../../history";
import { saveFile } from "./utils";

const conditionalRowStyles = [
  {
    when: (row) => new Date(row.subscriptionEndAt) < new Date(),
    style: {
      backgroundColor: "#ffe998",
      color: "white",
    },
  },
];

const ActionsComponent = ({
  row,
  editRow,
  renewUser,
  deleteRow,
  verifyUser,
}) => {
  return (
    <div className="data-list-action minus-6">
      <Check
        style={{ color: row.verified ? "inherit" : "#b7b7b7" }}
        className="cursor-pointer control-icon"
        size={20}
        title="Verify user"
        onClick={() => {
          verifyUser(row);
        }}
      />
      <RotateCw
        className="cursor-pointer control-icon"
        size={20}
        title="Continue subsciption"
        onClick={() => {
          renewUser(row);
        }}
      />
      <Eye
        className="cursor-pointer control-icon"
        size={20}
        title="View user campaigns"
        onClick={() => {
          history.push(`/campaigns/${row._id}`);
        }}
      />
      <Edit
        className="cursor-pointer control-icon"
        size={20}
        onClick={() => {
          return editRow(row);
        }}
      />
      <Trash
        className="cursor-pointer control-icon"
        size={20}
        onClick={() => {
          deleteRow(row);
        }}
      />
    </div>
  );
};

function Users(props) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  const [totalPages, setTotalPages] = useState(1);
  const [sortIndex, setSortIndex] = useState([1, 2]);
  const [currentData, setCurrentData] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState({
    selector: "email",
    type: "asc",
    typeData: typeString,
  });
  const refAlert = useRef();

  useEffect(() => {
    if (props.users) {
      const allDataSorted = props.users
        .slice()
        .sort(comparators[sort.typeData](sort.selector));
      if (sort.type === "desc") allDataSorted.reverse();
      if (filter || props.affiliateMode) {
        const filteredData = allDataSorted.filter(({ email, affiliate }) => {
          if (props.affiliateMode) {
            return (
              affiliate &&
              (String(email).toLowerCase().includes(filter.toLocaleLowerCase()) ||
                String(affiliate).toLowerCase().includes(filter.toLocaleLowerCase()))
            );
          }
          return String(email).toLowerCase().includes(filter.toLocaleLowerCase());
        });
        setData(filteredData);
        setTotal(filteredData.length);
        setTotalPages(1);
        setPage(0);
        setSortIndex([1, filteredData.length]);
      } else {
        setData(getSliceData(allDataSorted, page, limit));
        setTotal(allDataSorted.length);
        setTotalPages(getTotalPages(allDataSorted, limit));
        setSortIndex(getSortIndex(page, limit, allDataSorted.length));
      }
    }
  }, [page, props.users, limit, filter, sort, props.affiliateMode]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchApi({
      url: `/users`,
      method: "GET",
      token: props.token,
    }).then((companies) => {
      props.setUsers(companies);
    });
  };

  const handleLimit = (limit) => {
    setPage(0);
    setLimit(limit);
  };

  const handleSort = ({ selector, type: typeData }, type) => {
    setSort({ selector, type, typeData });
  };

  const updateData = ({ _id, ...data }) => {
    fetchApi({
      url: `/users/${_id}`,
      method: "PATCH",
      token: props.token,
      body: {
        ...data,
        subscriptionEndAt: new Date(
          new Date(new Date(data.subscriptionEndAt).setHours(23)).setMinutes(59)
        ),
        notified: false,
      },
    }).then((res) => {
      props.setUsers(
        props.users.map((node) => {
          if (node._id === res._id) return res;
          return node;
        })
      );
    });
  };

  const renewUser = ({ _id, subscriptionEndAt }) => {
    const currentDate = new Date();
    const fromDate = new Date(
      Math.max(new Date(subscriptionEndAt), currentDate)
    );
    const newDate = new Date(
      new Date(
        new Date(new Date(fromDate).setDate(fromDate.getDate() + 30)).setHours(
          23
        )
      ).setMinutes(59)
    );
    updateData({ _id, subscriptionEndAt: newDate });
  };

  const verifyUser = ({ _id }) => {
    updateData({ _id, verified: true });
  };

  const removeData = ({ _id }) => {
    fetchApi({
      url: `/users/${_id}`,
      method: "DELETE",
      token: props.token,
    }).then(() => {
      props.setUsers(props.users.filter((node) => node._id !== _id));
    });
  };

  const cancelSidebar = () => {
    setSidebar(false);
    setCurrentData(null);
  };

  const columns = [
    {
      name: "Email",
      type: typeString,
      selector: "email",
      sortable: true,
      grow: 1,
      cell: (row) => `${row.email}${row.affiliate ? " - $" : ""}`,
    },
    {
      name: "Telegram",
      type: typeString,
      selector: "telegram",
      sortable: true,
      grow: 1,
    },
    props.affiliateMode
      ? {
          name: "Promocode",
          type: typeString,
          sortable: false,
          selector: "affiliate",
          grow: 1,
        }
      : {
          name: "Tarif",
          type: typeString,
          sortable: false,
          selector: "tarif",
          grow: 1,
          cell: (row) =>
            props.tarifs.find(({ _id }) => _id === row.tarif)?.name,
        },
    {
      name: "End At",
      type: typeDate,
      selector: "subscriptionEndAt",
      sortable: true,
      grow: 1,
      cell: (row) => {
        let date = new Date()
        try {
          date = (row.subscriptionEndAt && new Date(row.subscriptionEndAt)) ||
          new Date(row.createdAt);
        } catch (error) {
        }
        return new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          year: "numeric",
          month: "short",
        }).format(date);
      },
    },
    {
      name: "Actions",
      width: "185px",
      cell: (row) => (
        <ActionsComponent
          row={row}
          renewUser={renewUser}
          verifyUser={verifyUser}
          editRow={(row) => {
            setCurrentData(row);
            setSidebar(true);
          }}
          deleteRow={(row) => refAlert.current.show(row)}
        />
      ),
    },
  ];
  if (!props.users) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 200,
        }}
      >
        <Spinner
          color="primary"
          style={{ margin: "0 auto", alignSelf: "center" }}
          size="lg"
        />
      </div>
    );
  }
  return (
    <div className={`data-list list-view`}>
      <BasicSweetCallback
        refAlert={refAlert}
        onConfirm={(row) => {
          removeData(row);
        }}
      />
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationComponent={() => (
          <ReactPaginate
            previousLabel={<ChevronLeft size={15} />}
            nextLabel={<ChevronRight size={15} />}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={totalPages}
            containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
            activeClassName="active"
            initialPage={page}
            onPageChange={(page) => setPage(page.selected)}
          />
        )}
        noHeader
        subHeader
        allowOverflow
        responsive
        onRowClicked={(row) => {
          if (row.affiliate) {
            history.push(`/affiliate/${row._id}`);
          }
        }}
        pointerOnHover
        sortIcon={<ChevronDown />}
        sortServer
        conditionalRowStyles={conditionalRowStyles}
        onSort={handleSort}
        subHeaderComponent={
          <CustomHeader
            verifiedUsers={
              props.users.filter(({ verified }) => verified).length
            }
            toggleAffiliateMode={() =>
              props.setAffiliateMode(!props.affiliateMode)
            }
            download={() => {
              fetchApi({
                url: `/payment`,
                method: "GET",
                token: props.token,
              }).then((payments) => {
                const items = props.users
                  .filter(
                    ({ subscriptionEndAt }) =>
                      new Date(subscriptionEndAt) < new Date()
                  )
                  .map((value) => {
                    const promocode = payments
                      .filter(({ userId }) => userId === value._id)
                      .find(({ promocode }) => promocode)?.promocode;
                    return { ...value, promocode };
                  });
                const replacer = (key, value) => {
                  return value === null ? "" : value;
                };
                const replacerTopLevel = (key, value) => {
                  if (key === "tarif") {
                    return props.tarifs.find(({ _id }) => _id === value)?.name;
                  }
                  if (key === "subscriptionEndAt") {
                    return new Intl.DateTimeFormat("en-US", {
                      day: "numeric",
                      year: "numeric",
                      month: "short",
                    }).format(new Date(value));
                  }
                  return value;
                };
                const header = [
                  "email",
                  "telegram",
                  "tarif",
                  "promocode",
                  "subscriptionEndAt",
                ];
                let csv = items.map((row) =>
                  header
                    .map((fieldName) =>
                      JSON.stringify(
                        replacerTopLevel(fieldName, row[fieldName]),
                        replacer
                      )
                    )
                    .join(",")
                );
                csv.unshift(header.join(","));
                csv = csv.join("\r\n");
                saveFile(csv, "Бывшие пользователи.csv");
              });
            }}
            downloadActiveUsers={() => {
              fetchApi({
                url: `/payment`,
                method: "GET",
                token: props.token,
              }).then((payments) => {
                const items = props.users
                  .filter(
                    ({ subscriptionEndAt }) =>
                      new Date(subscriptionEndAt) > new Date()
                  )
                  .map((value) => {
                    const promocode = payments
                      .filter(({ userId }) => userId === value._id)
                      .find(({ promocode }) => promocode)?.promocode;
                    return { ...value, promocode };
                  });
                const replacer = (key, value) => {
                  return value === null ? "" : value;
                };
                const replacerTopLevel = (key, value, row) => {
                  if (key === "tarif") {
                    return props.tarifs.find(({ _id }) => _id === value)?.name;
                  }
                  if (key === "last payment") {
                    return (
                      row.payments
                        .filter(({ activated }) => activated)
                        .slice(-1)[0]?.total || 0
                    );
                  }
                  if (key === "all payments") {
                    return row.payments.reduce(
                      (total, payment) =>
                        payment.activated ? total + payment.total : total,
                      0
                    );
                  }
                  if (key === "subscriptionEndAt") {
                    return new Intl.DateTimeFormat("en-US", {
                      day: "numeric",
                      year: "numeric",
                      month: "short",
                    }).format(new Date(value));
                  }
                  return value;
                };
                const header = [
                  "email",
                  "telegram",
                  "tarif",
                  "promocode",
                  "subscriptionEndAt",
                  "last payment",
                  "all payments",
                ];
                let csv = items.map((row) =>
                  header
                    .map((fieldName) =>
                      JSON.stringify(
                        replacerTopLevel(fieldName, row[fieldName], row),
                        replacer
                      )
                    )
                    .join(",")
                );
                csv.unshift(header.join(","));
                csv = csv.join("\r\n");
                saveFile(csv, "Активные пользователи.csv");
              });
            }}
            activeUsers={
              props.users.filter(
                ({ subscriptionEndAt }) =>
                  new Date(subscriptionEndAt) > new Date()
              ).length
            }
            addNew={false}
            handleFilter={(value) => setFilter(value)}
            handleRowsPerPage={handleLimit}
            rowsPerPage={limit}
            total={total}
            index={sortIndex}
          />
        }
      />
      {currentData && (
        <DataListSidebar
          show={sidebar}
          data={currentData}
          updateData={updateData}
          tarifs={props.tarifs}
          addData={() => {}}
          cancelSidebar={cancelSidebar}
        />
      )}
      <div
        className={classNames("data-list-overlay", {
          show: sidebar,
        })}
        onClick={cancelSidebar}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tarifs: tarifsSelector(state),
    users: usersSelector(state),
    affiliateMode: affiliateModeSelector(state),
    token: tokenSelector(state),
  };
};

export default connect(mapStateToProps, { setUsers, setAffiliateMode })(Users);
