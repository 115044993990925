import React, { useEffect, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import { ChevronLeft, ChevronRight, Edit, Trash } from "react-feather"
import ReactPaginate from "react-paginate"
import classNames from "classnames"

import fetchApi from "../../utility/context/fetchApi"
import { connect } from "react-redux"
import { tokenSelector } from "../../redux/selectors"
import DataListSidebar from "./IspDataListSidebar"
import { CustomHeader, getSliceData, getSortIndex, getTotalPages, typeString } from "./utils"
import BasicSweetCallback from "../../extensions/sweet-alert/SweetAlertCallback"
import { Spinner } from "reactstrap"

const ActionsComponent = ({ row, editRow, deleteRow }) => {
    return (
        <div className="data-list-action minus-6">
            <Edit
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    return editRow(row)
                }}
            />
            <Trash
                className="cursor-pointer control-icon"
                size={20}
                onClick={() => {
                    deleteRow(row)
                }}
            />
        </div>
    )
}

function Isp(props) {
    const [data, setData] = useState([])
    const [dataAll, setDataAll] = useState(null)
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(4)
    const [totalPages, setTotalPages] = useState(1)
    const [sortIndex, setSortIndex] = useState([1, 2])
    const [sidebar, setSidebar] = useState(false)
    const [currentData, setCurrentData] = useState(null)
    const [filter, setFilter] = useState('')
    const refAlert = useRef()

    useEffect(() => {
        if (dataAll) {
            if (filter) {
            const filteredData = dataAll.filter(({ value }) => value.toLowerCase().includes(filter.toLowerCase()))
            setData(filteredData)
            setTotal(filteredData.length)
            setTotalPages(1)
            setPage(0)
            setSortIndex([1, filteredData.length])
        } else {
            setData(getSliceData(dataAll, page, limit))
            setTotal(dataAll.length)
            setTotalPages(getTotalPages(dataAll, limit))
            setSortIndex(getSortIndex(page, limit, dataAll.length))
        }}
    }, [page, dataAll, limit, filter])

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        if (dataAll) {
            setDataAll(null)
        }
        fetchApi({
            url: `/isp`,
            method: 'GET',
            token: props.token,
        }).then((companies) => {
            setDataAll(companies)
        })
    }

    const handleLimit = (limit) => {
        setPage(0)
        setLimit(limit)
    }

    const cancelSidebar = () => {
        setSidebar(false)
        setCurrentData(null)
    }

    const addData = (data) => {
        fetchApi({
            url: '/isp',
            method: 'POST',
            token: props.token,
            body: data
        }).then((doc) => {
            setDataAll([doc, ...dataAll])
            setPage(0)
            setCurrentData(null)
        })
    }

    const updateData = ({ _id, ...data }) => {
        fetchApi({
            url: `/isp/${_id}`,
            method: 'PATCH',
            token: props.token,
            body: data
        }).then((res) => {
            setDataAll(dataAll.map((node) => {
                if (node._id === res._id) return res
                return node
            }))
            setCurrentData(null)
        })
    }

    const removeData = ({ _id }) => {
        fetchApi({
            url: `/isp/${_id}`,
            method: 'DELETE',
            token: props.token,
        }).then(() => {
            setDataAll(dataAll.filter((node) =>
                node._id !== _id
            ))
        })
    }

    const columns = [
        {
            name: "Isp",
            selector: "value",
            type: typeString,
            sortable: true,
            grow: 1
        },
        {
            name: "Actions",
            maxWidth: '100px',
            cell: row => (
                <ActionsComponent
                    row={row}
                    editRow={(row) => {
                        setCurrentData(row)
                        setSidebar(true)
                    }}
                    deleteRow={(row) => refAlert.current.show(row)}
                />
            )
        }
    ]
    if (!dataAll) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 200 }}>
            <Spinner color="primary" style={{ margin: '0 auto', alignSelf: 'center' }} size='lg' />
        </div>
    }
    return <div className={`data-list list-view`}>
        <BasicSweetCallback refAlert={refAlert}  onConfirm={(row) => {
                    removeData(row)
                }}/>
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationComponent={() => (
                <ReactPaginate
                    previousLabel={<ChevronLeft size={15} />}
                    nextLabel={<ChevronRight size={15} />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
                    activeClassName="active"
                    initialPage={page}
                    onPageChange={page => setPage(page.selected)}
                />
            )}
            noHeader
            subHeader
            responsive
            pointerOnHover
            subHeaderComponent={
                <CustomHeader
                    handleSidebar={setSidebar}
                    handleFilter={(value) => setFilter(value)}
                    handleRowsPerPage={handleLimit}
                    rowsPerPage={limit}
                    total={total}
                    index={sortIndex}
                />
            }
        />
        <DataListSidebar
            show={sidebar}
            data={currentData}
            updateData={updateData}
            addData={addData}
            cancelSidebar={cancelSidebar}
        />
        <div
            className={classNames("data-list-overlay", {
                show: sidebar
            })}
            onClick={cancelSidebar}
        />
    </div>
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps)(Isp)
