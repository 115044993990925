import React, { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Media, Row } from "reactstrap"
import { history } from "../../history"
import { tokenSelector } from "../../redux/selectors"
import fetchApi from "../../utility/context/fetchApi"
import notify from "../../utility/context/notify"
import BlogSidebar from "./BlogSidebar"
import { getSystemLanguage } from "./languages"

const BlogList = (props) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        if (data) {
            setData(null)
        }
        fetchApi({
            url: `/post`,
            method: 'GET',
            token: props.token,
        }).then((posts) => {
            setData(posts.filter(({ status }) => status !== 'Draft'))
        })
    }

    const renderRenderList = () => {
        return data.map(item => {
            const linkTo = () => {
                if (item.tarifIds.length === 0 || item.tarifIds.includes(props.user?.tarif)) {
                    return history.push(`/knowledge-base/${item._id}`)
                }
                notify('Upgrade your plan to read this post.', 'error')
            }
            return (
                <Col key={item.blogTitle} md='6'>
                    <Card>
                        <Link onClick={linkTo}>
                            <CardImg className='img-fluid' src={item.featuredImage} alt={item.blogTitle} top />
                        </Link>
                        <CardBody>
                            <CardTitle tag='h4'>
                                <Link onClick={linkTo} className='blog-title-truncate text-body-heading'>
                                    {item.blogTitle}
                                </Link>
                            </CardTitle>
                            <Media>
                                <Media body>
                                    <small className='text-muted'>{new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(item.createdAt))}</small>
                                </Media>
                            </Media>
                            <div className='my-1 py-25'></div>
                            <CardText className='blog-content-truncate'><span>{item.description}</span></CardText>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <Link>
                                </Link>
                                <Link className='font-weight-bold' onClick={linkTo}>
                                    {getSystemLanguage() === 'RU' ? 'Читать далее' : 'Read More' }
                                </Link>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )
        })
    }

    return (
        <div className='blog-wrapper'>
            <div className='content-detached content-left'>
                <div className='content-body'>
                    {data !== null ? (
                        <div className='blog-list-wrapper'>
                            <Row>{renderRenderList()}</Row>
                        </div>
                    ) : null}
                </div>
            </div>
            <BlogSidebar />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps)(BlogList)
