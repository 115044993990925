import React, { useEffect, useState } from "react"
import Router from "./Router"
import "./components/@vuexy/rippleButton/RippleButton"

import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"
import { connect } from "react-redux"
import { tokenSelector } from "./redux/selectors"
import {
  setTarifs, setPromos, setUsers, setBlackIps, setIsp, setCoins, setInitValue,
  setBlackList, setUserAgent, setPromocode, setValueBinded, setPayments
} from "./redux/actions"
import fetchApi from "./utility/context/fetchApi"
import { Spinner } from "reactstrap"
import { history } from "./history"
import ErrorBoundary from "./ErrorCatcher"
import { getSystemLanguage } from "./views/pages/languages"

const App = props => {
  const [loaded, setLoaded] = useState(false)
  if (!props.user) {
    if (window.location.href.includes(`/register${window.location.search}`)) {
      history.push(`/register${window.location.search}`)
    } else {
      if (window.location.href.includes('/verified')) {
        const [, id] = window.location.href.split('/verified/')
        history.push(`/verified/${id}`)
      } else {
        history.push(`/login${window.location.search}`)
      }
    }
  }
  const initApp = () => 
    fetchApi({
      url: `/users/init/${props.user?._id}`,
      method: 'GET',
      token: props.token,
    }).then(({ user, blackList, tarifs, promocodes, coins, promos }) => {
      localStorage.setItem('lang', user?.user?.lang || getSystemLanguage())
      localStorage.setItem('user', JSON.stringify({ ...props.userFull, user: { ...props.user, ...user } }))
      props.setInitValue({ user, blackList, tarifs, promocodes, coins, promos })
    }).catch((e) => {
      history.push(`/login${window.location.search}`)
    })
  
  useEffect(() => {
    if (props.user && !loaded) {
      initApp().then(() => setLoaded(true))
    }
  }, [props.user?._id])
  if (props.user && !loaded) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
      <Spinner color="primary" style={{ margin: '0 auto', alignSelf: 'center' }} size='lg' />
    </div>
  }
  return <ErrorBoundary><Router user={props.user} initApp={initApp} /></ErrorBoundary>
}

const mapStateToProps = state => {
  return {
    token: tokenSelector(state),
    user: state.app.user?.user,
    userFull: state.app.user,
  }
}

export default connect(mapStateToProps, {
  setUsers, setTarifs, setCoins,
  setBlackIps, setIsp, setInitValue,
  setBlackList, setUserAgent, setPromocode, setPayments, setPromos,
  setUser: setValueBinded(['user', 'user'])
})(App)
