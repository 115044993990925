import React, { Fragment, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    Media,
} from 'reactstrap'

import '../../assets/scss/plugins/extensions/page-blog.scss'
import fetchApi from '../../utility/context/fetchApi'
import { connect } from 'react-redux'
import { tokenSelector } from '../../redux/selectors'
import { AllHtmlEntities } from 'html-entities'
import BlogSidebar from './BlogSidebar'
import { getSystemLanguage } from './languages'

const entities = new AllHtmlEntities()

const BlogDetails = (props) => {
    let postId = props?.match?.params?.postId
    const [data, setData] = useState(null)

    useEffect(() => {
        if (postId) {
            fetchApi({
                url: `/post/${postId}`,
                method: 'GET',
                token: props.token,
            }).then((post) => {
                setData(post)
            })
        }
    }, [postId, props.token])

    return (
        <Fragment>
            <div className='blog-wrapper'>
                <div className='content-detached content-left'>
                    <div className='content-body'>
                        {data !== null ? (
                            <Row>
                                <Col sm='12'>
                                    <Card className='mb-3'>
                                        <CardImg src={data.featuredImage} className='img-fluid' top />
                                        <CardBody>
                                            <CardTitle tag='h4'>{data.blogTitle}</CardTitle>
                                            <Media>
                                                <Media body>
                                                    <small className='text-muted'>{new Intl.DateTimeFormat(getSystemLanguage() === 'RU' ? 'ru-RU' : 'en-EN', { day: 'numeric', year: 'numeric', month: 'short' }).format(new Date(data.createdAt))}</small>
                                                </Media>
                                            </Media>
                                            <div className='my-1 py-25'></div>
                                            <div
                                                className='blog-post-inner-wrapper'
                                                dangerouslySetInnerHTML={{
                                                    __html: entities.decode(data.excerpt)
                                                }}
                                            ></div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                </div>
                <BlogSidebar />
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps)(BlogDetails)

