export const getSystemLanguage = () => {
  if (
    localStorage.getItem('lang') &&
    localStorage.getItem('lang') !== 'undefined' &&
    localStorage.getItem('lang') !== undefined
  ) {
    return localStorage.getItem('lang')
  }
  const lang = navigator.language.includes('ru') ? 'RU' : 'EN'
  localStorage.setItem('lang', lang)
  return lang
}

export default {
  RU: {
    error: {
      activatePlan: 'Активируйте Тарифный план',
    },
    menu: {
      compaigns: 'Кампании',
      blackList: 'Black List',
      knowledgeBase: 'База знаний',
      affiliate: 'Affiliate',
      payments: 'Оплата',
      support: 'Поддержка',
    },
    alert: {
      title: 'Вы уверены?',
      confirm: 'Да, удалить!',
      cancel: 'Отмена',
      msg: 'Вы не сможете восстановить это!'
    },
    footer: {
      text: 'Все права защищены'
    }
  },
  EN: {
    error: {
      activatePlan: 'Activate Subscription',
    },
    menu: {
      compaigns: 'Campaigns',
      blackList: 'Black Lists',
      knowledgeBase: 'Knowledge Base',
      payments: 'Payments',
      affiliate: 'Affiliate',
      support: 'Support',
    },
    alert: {
      title: 'Are you sure?',
      confirm: 'Yes, delete it!',
      cancel: 'Cancel',
      msg: "You won't be able to revert this!"
    },
    footer: {
      text: 'All rights reserved'
    }
  }
}

// { getSystemLanguage() === 'RU' ? '' : '' }