import React, { Fragment, useState } from "react"
import { Check, X, Plus } from "react-feather"
import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexy"
import { Col, FormGroup, Label, Row, Input, Form, Button, CustomInput, FormFeedback } from 'reactstrap'
import { history } from "../../history"
import { initialTestOffer } from "./utils"
import { getSystemLanguage } from "./languages"

const Repeater = props => {
    const { count, tag = 'div', component, children, ...rest } = props
    const Tag = tag
    const items = []
    for (let i = 0; i < count; i++) {
        items.push(children(i))
    }
    return <Tag {...rest}>{items}</Tag>
}

const TestOffer = ({ i, ...props }) => {
    const [error, setError] = useState()
    return <div className='form-group form-password-toggle' key={i}>
        <Row className='justify-content-between align-items-center col-md-12'>
            <Col md={9}>
                <FormGroup>
                    <Label style={{ fontSize: 14 }} for={`item-name-${i}`}>Offer Page</Label>
                    <Input type='text' id={`item-name-${i}`}
                        value={props.company?.testOffers[i]?.offer}
                        onChange={e => props.setCompany({
                            ...props.company,
                            testOffers: props.company?.testOffers?.map((o, index) => i === index ? { ...o, offer: e.target.value } : o)
                        })} />
                </FormGroup>
            </Col>
            <Col md={1}>
                <FormGroup>
                    <Label style={{ fontSize: 14 }} for={`cost-${i}`}>{getSystemLanguage() === 'RU' ? 'Вес' : 'Weight'}</Label>
                    <Input type='number' id={`cost-${i}`}
                        value={props.company?.probabilityEqual ? props.equalityWeight : props.company?.testOffers[i]?.probability}
                        disabled={props.company?.probabilityEqual}
                        invalid={Boolean(error)}
                        onChange={e => {
                            if (Number(e.target.value) > 100) {
                                setError(getSystemLanguage() === 'RU' ? 'Максимальное значение - 100' : 'Max value is 100')
                                return
                            }
                            if (Number(e.target.value) < 0) {
                                setError(getSystemLanguage() === 'RU' ? 'Минимальное значение - 0' : 'Min value is 0')
                                return
                            }
                            if (props.company?.testOffers?.filter((o, index) => index !== i)?.reduce((total, { probability }) => total + Number(probability), Number(e.target.value)) > 100) {
                                setError(getSystemLanguage() === 'RU' ? 'Максимальное общее значение не может превышать 100' : 'Max value for offers is 100')
                                return
                            }
                            setError('')
                            props.setCompany({
                                ...props.company,
                                testOffers: props.company?.testOffers?.map((o, index) => i === index ? { ...o, probability: e.target?.value } : o)
                            })
                        }} />
                    <FormFeedback>{error}</FormFeedback>
                    <span style={{ position: 'absolute', right: -4, top: 28 }}>%</span>
                </FormGroup>
            </Col>
            <Col md={2}>
                <Button.Ripple color='danger' className='text-nowrap px-1' style={{ height: 37, paddingTop: 10, marginTop: 5 }} onClick={(e) => props.deleteForm(e, i)} outline>
                    <X size={14} className='mr-50' />
                    <span>{getSystemLanguage() === 'RU' ? 'Удалить' : 'Delete'}</span>
                </Button.Ripple>
            </Col>
        </Row>
        <div className='form-group form-probabilityEqual-toggle col-md-12'>
            <div className="d-inline-block mr-1">
                <Checkbox
                    color="primary"
                    icon={<Check className="vx-icon" size={10} />}
                    onChange={() => { }}
                    checked={props.company?.testOffers[i]?.loadTypeOffer === 'load'}
                    onClick={() => props.setCompany({
                        ...props.company,
                        testOffers: props.company?.testOffers?.map((o, index) => i === index ? { ...o, loadTypeOffer: 'load' } : o)
                    })}
                    label={getSystemLanguage() === 'RU' ? 'Подгрузка' : 'Show Content'}
                    size="sm"
                />
            </div>
            <div className="d-inline-block mr-1">
                <Checkbox
                    color="primary"
                    onChange={() => { }}
                    icon={<Check className="vx-icon" size={10} />}
                    checked={props.company?.testOffers[i]?.loadTypeOffer === 'redirect'}
                    onClick={() => props.setCompany({
                        ...props.company,
                        testOffers: props.company?.testOffers?.map((o, index) => i === index ? { ...o, loadTypeOffer: 'redirect' } : o)
                    })}
                    label={getSystemLanguage() === 'RU' ? 'Редирект' : 'Redirect'}
                    size="sm"
                />
            </div>
            <div className="d-inline-block mr-1">
                <Checkbox
                    color="primary"
                    icon={<Check className="vx-icon" size={10} />}
                    onChange={() => { }}
                    checked={props.company?.testOffers[i]?.loadTypeOffer === 'iframe'}
                    onClick={() => props.setCompany({
                        ...props.company,
                        testOffers: props.company?.testOffers?.map((o, index) => i === index ? { ...o, loadTypeOffer: 'iframe' } : o)
                    })}
                    label="Iframe"
                    size="sm"
                />
            </div>
        </div>
    </div>
}

export default function CompanySetupPages({ type, ...props }) {
    const increaseCount = () => {
        props.setCompany({ ...props.company, testOffers: [...props.company?.testOffers, initialTestOffer] })
    }

    const deleteForm = (e, i) => {
        e.preventDefault()
        props.setCompany({ ...props.company, testOffers: props.company?.testOffers.filter((o, index) => index !== i) })
    }
    return (
        <Fragment>
            <div className='content-header'>
                <h5 className='mb-0'>{props.title}</h5>
            </div>
            <Form onSubmit={e => e.preventDefault()}>
                <Row>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-name">{getSystemLanguage() === 'RU' ? 'Имя' : 'Name'}</Label>
                        <Input
                            type="text"
                            value={props.company?.name}
                            onChange={e => props.setCompany({ ...props.company, name: e.target.value })}
                        />
                    </div>
                    <div className='form-group form-password-toggle col-md-12'>
                        <Label style={{ fontSize: 14 }} for="data-white">White Page</Label>
                        <Input
                            type="text"
                            value={props.company?.white}
                            onChange={e => props.setCompany({ ...props.company, white: e.target.value })}
                        />
                        <div className="d-inline-block mr-1">
                            <Checkbox
                                color="primary"
                                icon={<Check className="vx-icon" size={10} />}
                                checked={props.company?.loadTypeWhite === 'load'}
                                onChange={() => { }}
                                onClick={() => props.setCompany({ ...props.company, loadTypeWhite: 'load' })}
                                label={getSystemLanguage() === 'RU' ? 'Подгрузка' : 'Show Content'}
                                size="sm"
                            />
                        </div>
                        <div className="d-inline-block mr-1">
                            <Checkbox
                                color="primary"
                                onChange={() => { }}
                                icon={<Check className="vx-icon" size={10} />}
                                checked={props.company?.loadTypeWhite === 'redirect'}
                                onClick={() => props.setCompany({ ...props.company, loadTypeWhite: 'redirect' })}
                                label={getSystemLanguage() === 'RU' ? 'Редирект' : 'Redirect'}
                                size="sm"
                            />
                        </div>
                    </div>
                    {!props.company?.abtest ?
                        <div className='form-group form-password-toggle col-md-12'>
                            <Label style={{ fontSize: 14 }} for="data-offer">Offer Page</Label>
                            <Input
                                type="text"
                                value={props.company?.offer}
                                onChange={e => props.setCompany({ ...props.company, offer: e.target.value })}
                            />
                            <div className="d-inline-block mr-1">
                                <Checkbox
                                    color="primary"
                                    icon={<Check className="vx-icon" size={10} />}
                                    onChange={() => { }}
                                    checked={props.company?.loadTypeOffer === 'load'}
                                    onClick={() => props.setCompany({ ...props.company, loadTypeOffer: 'load' })}
                                    label={getSystemLanguage() === 'RU' ? 'Подгрузка' : 'Show Content'}
                                    size="sm"
                                />
                            </div>
                            <div className="d-inline-block mr-1">
                                <Checkbox
                                    color="primary"
                                    onChange={() => { }}
                                    icon={<Check className="vx-icon" size={10} />}
                                    checked={props.company?.loadTypeOffer === 'redirect'}
                                    onClick={() => props.setCompany({ ...props.company, loadTypeOffer: 'redirect' })}
                                    label={getSystemLanguage() === 'RU' ? 'Редирект' : 'Redirect'}
                                    size="sm"
                                />
                            </div>
                            <div className="d-inline-block mr-1">
                                <Checkbox
                                    color="primary"
                                    icon={<Check className="vx-icon" size={10} />}
                                    onChange={() => { }}
                                    checked={props.company?.loadTypeOffer === 'iframe'}
                                    onClick={() => props.setCompany({ ...props.company, loadTypeOffer: 'iframe' })}
                                    label="Iframe"
                                    size="sm"
                                />
                            </div>
                        </div> : null}
                    <div className='form-group form-password-toggle col-md-12'>
                        <CustomInput
                            size="sm"
                            type="switch"
                            id='premium-data'
                            checked={props.company?.abtest}
                            onChange={e => props.setCompany({ ...props.company, abtest: e.target.checked })}
                            inline
                        >
                            <span className="switch-label">{getSystemLanguage() === 'RU' ? 'A/B Тест' : 'A/B Test'}</span>
                        </CustomInput>
                    </div>
                    {props.company?.abtest ?
                        <div style={{ width: '100%' }}>
                            <div className='form-group form-probabilityEqual-toggle col-md-12'>
                                <CustomInput
                                    size="sm"
                                    type="switch"
                                    id='probabilityEqual-data'
                                    checked={props.company?.probabilityEqual}
                                    onChange={e => props.setCompany({ ...props.company, probabilityEqual: e.target.checked })}
                                    inline
                                >
                                    <span className="switch-label">{getSystemLanguage() === 'RU' ? 'Равный вес' : 'Equal Weight'}</span>
                                </CustomInput>
                            </div>
                            <Repeater count={props.company?.testOffers?.length}>
                                {i => (<TestOffer i={i} {...props} equalityWeight={Math.trunc(1 / props.company?.testOffers?.length * 100)} deleteForm={deleteForm} />)}
                            </Repeater>
                            <div className='form-group form-password-toggle col-md-12'>
                                <Button.Ripple className='btn-icon' color='primary' onClick={increaseCount}>
                                    <Plus size={14} />
                                    <span className='align-middle ml-25'>{getSystemLanguage() === 'RU' ? 'Добавить' : 'Add New'}</span>
                                </Button.Ripple>
                            </div>
                        </div>
                        : null}
                </Row>
                <hr />
                <div className='d-flex justify-content-between'>
                    <Button.Ripple style={{ width: 120 }} color='danger' className='btn-next' outline onClick={() => history.push('/campaigns')}>
                        <span className='align-middle d-sm-inline-block'>{getSystemLanguage() === 'RU' ? 'Отмена' : 'Cancel'}</span>
                    </Button.Ripple>
                    <Button.Ripple style={{ width: 130 }} color='primary' className='btn-next' onClick={() => {
                        props.isNewCompany ? props.addCompany(props.company) : props.updateCompany(props.company)
                    }}>
                        <span className='align-middle d-sm-inline-block'>{props.isNewCompany ? getSystemLanguage() === 'RU' ? 'Создать' : 'Save' : getSystemLanguage() === 'RU' ? 'Изменить' : 'Update'}</span>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}
